// node_modules
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
// Components
import { FindestButton, SuggestingTextbox } from "Components";
import { DetailInformation } from "./DetailInformation";
// Constants
import { AiConstants } from "Constants";
// Controllers
import {
  ActivityControllerSingleton,
  SearchControllerSingleton,
} from "Controllers";
// Enums
import {
  AskIgorMenuItemEnum,
  DocumentObjectTypeEnums,
  ObjectTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Helpers
import { ObjectTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Hooks
import { useAskIgor, useInformationExtraction } from "Hooks";
// Interfaces
import {
  IAskIgorDetailInformation,
  IAskIgorRequest,
  IGptForTiResponse,
  ISavedDocumentDTO,
} from "Interfaces";
// Types
import { TIdNameTypeObjectType } from "Types";
// Styles
import styles from "./extractDetailInformation.module.scss";

interface IExtractDetailInformationProps {
  aboutObject: TIdNameTypeObjectType;
  documentsSelected: string[];
  linkedDocuments: ISavedDocumentDTO[] | undefined;
  aiGeneratedText: string;
  setAIGeneratedText: (generatedText: string) => void;
  setIsGeneratingText: (isGeneratingText: boolean) => void;
  doCancel: boolean;
  setDoCancel: (doCancel: boolean) => void;
  showTableBuilder: boolean;
  setShowTableBuilder: (showTableBuilder: boolean) => void;
}

export const ExtractDetailInformation: FC<IExtractDetailInformationProps> = ({
  aboutObject,
  documentsSelected,
  linkedDocuments,
  aiGeneratedText,
  setAIGeneratedText,
  setIsGeneratingText,
  doCancel,
  setDoCancel,
  showTableBuilder,
  setShowTableBuilder,
}: IExtractDetailInformationProps) => {
  const ENCODED_EMOJI_CONTENT_LOADING_CELL = "&#128260;";
  const EMOJI_CONTENT_LOADING_CELL = "🔄";
  // State
  const [suggestions, setSuggestions] = useState<TIdNameTypeObjectType[]>([
    aboutObject,
  ]);
  const [selectedLinkToObject, setSelectedLinkToObject] =
    useState<TIdNameTypeObjectType | undefined>(aboutObject);
  const [isRecentActivityVisible, setIsRecentActivityVisible] =
    useState<boolean>(false);
  const [detailInformation, setDetailInformation] = useState<
    IAskIgorDetailInformation[]
  >([]);
  const [generatedInformation, setGeneratedInformation] = useState<string>("");

  // Refs
  const currentObjectNameRef = useRef<string>("");

  // Memo
  const isSubmitButtonDisabled = useMemo(() => {
    if (!detailInformation || detailInformation.length === 0) return true;

    const hasInvalidEntry = detailInformation.some(
      (info) => !info.columnHeader?.trim() || !info.dataToExtract?.trim()
    );

    if (hasInvalidEntry) return true;

    const hasDuplicate = (arr: string[]) => new Set(arr).size !== arr.length;

    const columnHeaders = detailInformation.map((info) => info.columnHeader.trim());
    const dataToExtracts = detailInformation.map((info) => info.dataToExtract.trim());

    return hasDuplicate(columnHeaders) || hasDuplicate(dataToExtracts);
  }, [detailInformation]);

  // Hooks
  const { generateContentFromGptForTiResponse } = useAskIgor({
    askIgorMenuItem: AskIgorMenuItemEnum.InformationExtraction,
    documentsSelected: [],
  });

  // Logic
  const processCompletionResultAsync = useCallback(
    async (
      request: IAskIgorRequest,
      result: IGptForTiResponse
    ): Promise<void> => {
      const documentId: string = request.dataPoints?.[0] ?? "";
      setGeneratedInformation((prevInfo) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(prevInfo, "text/html");
        const rows = doc.querySelectorAll("tbody tr");
        rows.forEach((row) => {
          const cells = row.querySelectorAll("td");
          if (cells[0]?.getAttribute("id") === documentId) {
            cells.forEach((cell) => {
              if (cell?.getAttribute("id") === request.input) {
                cell.innerHTML = generateContentFromGptForTiResponse(
                  "HTML_PARAGRAPH",
                  result.InformationExtraction?.Documents || {},
                  result.InformationExtraction?.Highlights || {},
                  request.input
                );
              }
            });
          }
        });
        return doc.body.innerHTML;
      });
    },
    [generateContentFromGptForTiResponse]
  );

  const onAllInformationRequestsDone = useCallback(() => {
    setIsGeneratingText(false);
  }, [setIsGeneratingText]);

  const {
    informationExtractionRequested,
    addInformationExtractionRequested,
    requestInformationExtractionAsync,
    cancelAllInformationExtractionRequests,
  } = useInformationExtraction(
    processCompletionResultAsync,
    onAllInformationRequestsDone
  );

  const retrieveRecentActivityAsync = async () => {
    const recentActivity: TIdNameTypeObjectType[] =
      await ActivityControllerSingleton.getMySimpleActivityAsync();

    if (!recentActivity) {
      setSuggestions([]);
      return;
    }

    setSuggestions(recentActivity);
  };

  const runSuggestionsSearchAsync = async (
    suggestionValue: string,
    currentSearchTypes: ObjectTypeEnum[]
  ): Promise<void> => {
    setIsRecentActivityVisible(false);

    currentObjectNameRef.current = suggestionValue;

    if (!suggestionValue) {
      retrieveRecentActivityAsync();
      setIsRecentActivityVisible(true);
      return;
    }

    const foundSuggestions: TIdNameTypeObjectType[] =
      await SearchControllerSingleton.searchMultipleObjectsAsync(
        suggestionValue,
        currentSearchTypes
      );

    if (!foundSuggestions) {
      setSuggestions([]);
      return;
    }

    setSuggestions(foundSuggestions);
  };

  useEffect(() => {
    setAIGeneratedText(generatedInformation);
  }, [setAIGeneratedText, generatedInformation]);

  const onSubmitClickAsync = useCallback(async (): Promise<void> => {
    cancelAllInformationExtractionRequests();
    setGeneratedInformation("");
    setAIGeneratedText("");
    setIsGeneratingText(true);

    const extractDetailInformationRequests: IAskIgorRequest[] = [];
    for (const currentDetailInformation of detailInformation) {
      for (const documentId of documentsSelected) {
        const savedDocumentType = linkedDocuments?.find(
          (doc) => doc.id === documentId
        )?.savedDocumentType;
        extractDetailInformationRequests.push({
          completionId: window.crypto.randomUUID(),
          input: currentDetailInformation.dataToExtract,
          objectId: selectedLinkToObject
            ? selectedLinkToObject.id
            : aboutObject.id,
          objectType: selectedLinkToObject
            ? selectedLinkToObject.objectType
            : aboutObject.objectType,
          documentTypes: savedDocumentType
            ? [
              ObjectTypeHelperSingleton.documentTypeToObjectType(
                savedDocumentType
              ),
            ]
            : DocumentObjectTypeEnums,
          dataPoints: [documentId],
        });
      }
    }
    addInformationExtractionRequested(extractDetailInformationRequests);

    let tableHtml = "<table><thead><tr><th>Document</th>";
    for (const info of detailInformation) {
      tableHtml += `<th>${info.columnHeader}</th>`;
    }
    tableHtml += "</tr></thead><tbody>";

    for (const documentId of documentsSelected) {
      const docName = linkedDocuments?.find(
        (doc) => doc.id === documentId
      )?.title;
      tableHtml += `<tr><td id="${documentId}">${docName}</td>`;
      for (const currentDetailInformation of detailInformation) {
        tableHtml += `<td id="${currentDetailInformation.dataToExtract}">${ENCODED_EMOJI_CONTENT_LOADING_CELL}</td>`;
      }
      tableHtml += "</tr>";
    }
    tableHtml += "</tbody></table>";
    setGeneratedInformation(tableHtml);
    setShowTableBuilder(false);

    let isSuccess = true;
    let failedOnDataToExtract = "";
    let failedOnDocumentTitle = "";
    for (const request of extractDetailInformationRequests) {
      isSuccess = await requestInformationExtractionAsync(request);

      if (!isSuccess) {
        failedOnDataToExtract = request.input;
        failedOnDocumentTitle =
          linkedDocuments?.find((doc) => doc.id === request.dataPoints?.[0])
            ?.title ||
          request.dataPoints?.[0] ||
          "";
        break;
      }
    }
    if (!isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        `${AiConstants.EXTRACT_DETAIL_INFORMATION_FAILED} Information to extract: ${failedOnDataToExtract}, document: ${failedOnDocumentTitle}`
      );
      cancelAllInformationExtractionRequests(false);
    }
  }, [
    cancelAllInformationExtractionRequests,
    setAIGeneratedText,
    setIsGeneratingText,
    addInformationExtractionRequested,
    setShowTableBuilder,
    detailInformation,
    documentsSelected,
    linkedDocuments,
    selectedLinkToObject,
    aboutObject.id,
    aboutObject.objectType,
    requestInformationExtractionAsync,
  ]);

  useEffect(() => {
    if (doCancel && informationExtractionRequested.size > 0) {
      cancelAllInformationExtractionRequests(false);
      setDoCancel(false);

      setGeneratedInformation((prevInfo) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(prevInfo, "text/html");
        const cells = doc.querySelectorAll("td");
        cells.forEach((cell) => {
          if (
            cell.innerHTML === ENCODED_EMOJI_CONTENT_LOADING_CELL ||
            cell.innerHTML === EMOJI_CONTENT_LOADING_CELL
          ) {
            cell.innerHTML = "";
          }
        });
        return doc.body.innerHTML;
      });
    }
  }, [
    cancelAllInformationExtractionRequests,
    doCancel,
    informationExtractionRequested.size,
    setDoCancel,
  ]);

  return (
    <div className={styles.container}>
      <div
        className={`${styles.table} ${!showTableBuilder && aiGeneratedText.length ? styles.displayNone : ""
          }`}
      >
        <div className={styles.existingObjectSuggestions}>
          <h6 className={styles.title}>Object</h6>
          <SuggestingTextbox
            forcedSuggestionValue={
              selectedLinkToObject?.name || aboutObject.name
            }
            placeholder="Search for entity or study"
            title={
              isRecentActivityVisible
                ? "Recently updated objects"
                : "Universe suggestions"
            }
            onValueChangeHandler={(value) =>
              (currentObjectNameRef.current = value)
            }
            refreshSuggestionsAsync={async (newValue: string) => {
              runSuggestionsSearchAsync(newValue, [
                ObjectTypeEnum.Entity,
                ObjectTypeEnum.Study,
              ]);
            }}
            suggestions={suggestions}
            handleSuggestionClick={(suggestion: TIdNameTypeObjectType) => {
              setSelectedLinkToObject(suggestion);
            }}
            selectedOption={selectedLinkToObject}
            setSelectedOption={setSelectedLinkToObject}
          />
        </div>
        <DetailInformation
          aboutObject={selectedLinkToObject || aboutObject}
          onDetailInformationChange={setDetailInformation}
        />
        <div className={styles.footer}>
          <FindestButton
            title={showTableBuilder ? "Update table" : "Extract information"}
            onClick={onSubmitClickAsync}
            extraClassName={styles.submitButton}
            isDisabled={isSubmitButtonDisabled}
          />
          {showTableBuilder && (
            <FindestButton
              title="Back to table"
              buttonType="secondary"
              onClick={() => setShowTableBuilder(false)}
            />
          )}
        </div>
      </div>
      {!showTableBuilder && aiGeneratedText && (
        <div className={styles.table}>
          <div className={styles.existingObjectSuggestions}>
            <h6 className={styles.title}>Extract detail information</h6>
          </div>
        </div>
      )}
    </div>
  );
};
