// Node Modules
import {
  faMessageBot,
  faRotateRight,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useResizeObserver from "@react-hook/resize-observer";
// React
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
// Hooks
import { useClickOutsideRef } from "Hooks";
// Components
import { Checkbox, EditableInput, RatingStar } from "Components";
import { ScoutingServiceTableCell } from "./ScoutingServiceTableCell/ScoutingServiceTableCell";
// Types
import { TScoutingServiceTableObject, TUpdateAssessmentScoreDTO } from "Types";
// Styles
import styles from "./scoutingServiceOptionTable.module.scss";
// Enums
import { ObjectTypeEnum, ScoutingServiceTableTypeEnum } from "Enums";
import { IAskIgorRequest } from "Interfaces";

type TScoutingServiceOptionTableProps = {
  headerTitles: string[];
  changeHeaderTitle?: (newValue: string, index: number) => void;
  isRatingEnabled?: boolean;
  isRatingEnabledPerLayerIndex: Map<number, boolean>;
  updateIsRatingEnabledPerLayerIndex?: (
    layerIndex: number,
    newIsRatingEnabledForLayerIndex: boolean
  ) => void;
  getRatingStarRating?: (layerIndex: number) => number;
  tableObjectData: (TScoutingServiceTableObject | null)[][];
  setTableObjectData?: Dispatch<
    SetStateAction<(TScoutingServiceTableObject | null)[][]>
  >;
  recursivelyGetAllBottomChildrenCount: (
    tableObject: TScoutingServiceTableObject,
    doSkipIsNotChecked?: boolean
  ) => number;
  isNumbered: boolean;
  newColumnHeaderPlaceholder?: string;
  type: ScoutingServiceTableTypeEnum;
  refreshColumnHandler?: (headerTitle: string) => void;
  refreshRowHandler?: (objectData: TScoutingServiceTableObject | null) => void;
  refreshCellHandler?: (headerTitle: string, rowIndex: number) => void;
  deleteColumnHandler?: (headerTitle: string, columnIndex: number) => void;
  requirementSummariesRequested?: Map<string, IAskIgorRequest>;
  objectIdEdited?: string;
  objectTypeEdited?: ObjectTypeEnum;
  isTableEditable?: boolean;
  extraClassNames?: { container?: string; cellActionsContainer?: string };
  maturityLevelPerEntityId?: Map<string, TUpdateAssessmentScoreDTO>;
  setMaturityLevelPerEntityId?: Dispatch<
    SetStateAction<Map<string, TUpdateAssessmentScoreDTO>>
  >;
};

export function ScoutingServiceOptionTable({
  headerTitles,
  changeHeaderTitle,
  isRatingEnabled,
  isRatingEnabledPerLayerIndex,
  updateIsRatingEnabledPerLayerIndex,
  getRatingStarRating,
  tableObjectData,
  setTableObjectData,
  recursivelyGetAllBottomChildrenCount,
  isNumbered,
  newColumnHeaderPlaceholder,
  type,
  refreshColumnHandler,
  refreshRowHandler,
  refreshCellHandler,
  deleteColumnHandler,
  requirementSummariesRequested,
  objectIdEdited,
  objectTypeEdited,
  isTableEditable = true,
  extraClassNames = {},
  maturityLevelPerEntityId,
  setMaturityLevelPerEntityId,
}: TScoutingServiceOptionTableProps) {
  // State
  const [selectedRowOrCell, setSelectedRowOrCell] = useState<string>("");
  const [focusedTableColumnHeaderIndex, setFocusedTableColumnHeaderIndex] =
    useState<string>("");

  // Ref
  const tableElementRef = useRef<HTMLTableElement>(null);

  // Memo
  const isRequirementsTable = useMemo((): boolean => {
    return type === ScoutingServiceTableTypeEnum.RequirementsTable;
  }, [type]);

  const isMaturityRadar = useMemo((): boolean => {
    return type === ScoutingServiceTableTypeEnum.MaturityRadar;
  }, [type]);

  const areAllObjectsNotChecked = useMemo((): boolean => {
    // go through each row in table object data
    for (const shownTableObjectDataRow of tableObjectData) {
      // go through each cell in row
      for (const shownTableObjectDataCell of shownTableObjectDataRow) {
        // if shown table object data cell is defined and is checked
        if (shownTableObjectDataCell && shownTableObjectDataCell.isChecked) {
          // as soon as we find a checked cell, we can return false
          return false;
        }
      }
    }

    // if we make it through all cells without finding a checked one, then they are all not checked
    return true;
  }, [tableObjectData]);

  const requirementSummaryRequestedPerTableCell = useMemo((): Set<string> => {
    // init new set
    const newRequirementSummaryRequestedPerTableCell = new Set<string>();

    // safety-checks
    if (!requirementSummariesRequested) {
      // stop execution, return empty set
      return new Set();
    }

    // go through each entry in the requirementSummariesRequested map
    requirementSummariesRequested.forEach((value: IAskIgorRequest) => {
      // safety-checks
      if (value.input && value.objectId) {
        newRequirementSummaryRequestedPerTableCell.add(
          `${value.input}-${value.objectId}`
        );
      }
    });

    // return new set
    return newRequirementSummaryRequestedPerTableCell;
  }, [requirementSummariesRequested]);

  const isRequestingRequirementSummary = useMemo((): boolean => {
    // if there is at least one requirement summary requested then it means that we are requesting a requirement summary
    return requirementSummaryRequestedPerTableCell.size > 0;
  }, [requirementSummaryRequestedPerTableCell.size]);

  const handleTableResize = useCallback(() => {
    // get current visible selection box div
    const selectionBox = document.querySelector(
      "div[id^='selectionBox_'][data-current-selection-box='1']"
    ) as HTMLElement;
    if (selectionBox) {
      const currentTdElement = selectionBox.closest("td");
      const tableElement = currentTdElement?.closest("table");
      const tableElementRect = tableElement?.getBoundingClientRect();
      // resize selection box
      if (currentTdElement && tableElementRect) {
        const currentRect = currentTdElement.getBoundingClientRect();
        selectionBox.style.height = `${Math.round(currentRect.height)}px`;
        const selectionBoxWidth = Math.round(
          tableElementRect.width - (currentRect.left - tableElementRect.left)
        );
        selectionBox.style.width = `${selectionBoxWidth}px`;
      }
    }
  }, []);

  // Hooks
  useClickOutsideRef(tableElementRef, () => {
    setSelectedRowOrCell("");
    resetTableCellSelection();
  });
  useResizeObserver(tableElementRef.current, handleTableResize);

  const resetTableCellSelection = useCallback(() => {
    const visibleSelectionBox = document.querySelector(
      "div[id^='selectionBox_'][data-current-selection-box='1']"
    ) as HTMLElement;
    if (visibleSelectionBox) {
      visibleSelectionBox.dataset.currentSelectionBox = "0";
      visibleSelectionBox.style.display = "none";
    }
  }, []);

  const getDoShowRefreshColumnButton = useCallback(
    (headerTitle: string, columnIndex: number): boolean => {
      // init do show refresh column button
      let doShowRefreshColumnButton = !isRequestingRequirementSummary;
      // add check if headerTitle is defined
      if (!headerTitle) {
        // if so set doShowRefreshColumnButton to false
        doShowRefreshColumnButton = false;
      }

      // if is maturity radar
      if (isMaturityRadar) {
        doShowRefreshColumnButton = false;
      }

      // if is requirements table
      if (isRequirementsTable) {
        // check also if columnIndex is not 0 (only show refresh column button for the columns after the first one)
        doShowRefreshColumnButton =
          doShowRefreshColumnButton && columnIndex !== 0;

        // check also if there is a duplicate headerTitle in the headerTitles array (starting from the second column)
        for (let index = 0; index < headerTitles.length; index++) {
          // if index is different from columnIndex and index is not 0
          if (index !== columnIndex && index !== 0) {
            // get current header title
            const currentHeaderTitle = headerTitles[index];

            // if current header title is equal to header title
            if (currentHeaderTitle === headerTitle) {
              // if so set doShowRefreshColumnButton to false
              doShowRefreshColumnButton = false;
              // stop execution, break
              break;
            }
          }
        }

        // check also if all objects are not checked
        doShowRefreshColumnButton =
          doShowRefreshColumnButton && !areAllObjectsNotChecked;
      }

      // return do show refresh column button
      return doShowRefreshColumnButton;
    },
    [
      areAllObjectsNotChecked,
      headerTitles,
      isMaturityRadar,
      isRequestingRequirementSummary,
      isRequirementsTable,
    ]
  );

  const getDoShowDeleteColumnButton = useCallback(
    (columnIndex: number): boolean => {
      // init do show delete column button
      let doShowDeleteColumnButton = !isRequestingRequirementSummary;

      // if is maturity radar
      if (isMaturityRadar) {
        doShowDeleteColumnButton = false;
      }

      // if is requirements table
      if (isRequirementsTable) {
        // check also if columnIndex is not 0 (only show delete column button for the columns after the first one)
        doShowDeleteColumnButton =
          doShowDeleteColumnButton && columnIndex !== 0;
      }

      // return do show delete column button
      return doShowDeleteColumnButton;
    },
    [isMaturityRadar, isRequestingRequirementSummary, isRequirementsTable]
  );

  const doShowRefreshButtonAsGenerateButton = useCallback(
    (columnIndex: number): boolean => {
      if (focusedTableColumnHeaderIndex !== `${columnIndex}`) {
        return false;
      }
      // go through each row in table object data
      for (const shownTableObjectDataRow of tableObjectData) {
        // go through each cell in row
        const shownTableObjectDataCell = shownTableObjectDataRow[columnIndex];

        // if shown table object data cell is defined and is empty
        return shownTableObjectDataCell?.name === "";
      }

      return false;
    },
    [tableObjectData, focusedTableColumnHeaderIndex]
  );

  return (
    <div
      className={`${!isTableEditable ? styles.viewOnly : ""} ${styles.tableContainer
        } ${extraClassNames.container}`}
    >
      <table ref={tableElementRef}>
        <thead>
          <tr>
            {headerTitles.map((headerTitle, index) => {
              const hideRatingCheckbox = isRequirementsTable || isMaturityRadar;
              let hideRatingStar = false;
              if (isMaturityRadar || (isRequirementsTable && index !== 0)) {
                hideRatingStar = true;
              }
              const showRefreshButtonAsGenerate =
                doShowRefreshButtonAsGenerateButton(index);
              return (
                <th
                  title={!isTableEditable ? headerTitle : ""}
                  className={styles.tableHeaderCell}
                  // using something else than index as key can cause issues with the table
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}`}
                >
                  <div className={styles.headerContent}>
                    {isRequestingRequirementSummary ? (
                      <div className={styles.columnTitle}>
                        {headerTitle ?? ""}
                      </div>
                    ) : isTableEditable && isRequirementsTable ? (
                      <EditableInput
                        shouldAutoFocus={!headerTitle}
                        placeholder={newColumnHeaderPlaceholder}
                        className={styles.columnTitle}
                        value={headerTitle ?? ""}
                        setValue={(newValue: string) => {
                          changeHeaderTitle?.(newValue, index);
                        }}
                        handleFocus={() => {
                          setFocusedTableColumnHeaderIndex(`${index}`);
                        }}
                        handleBlur={() => {
                          setFocusedTableColumnHeaderIndex("");
                        }}
                      />
                    ) : (
                      <div className={styles.columnTitle}>
                        {headerTitle ?? ""}
                      </div>
                    )}
                    <div className={styles.columnActionsContainer}>
                      {isRatingEnabled && (
                        <div className={styles.tableHeaderCellRatingContainer}>
                          {!hideRatingCheckbox &&
                            updateIsRatingEnabledPerLayerIndex && (
                              <Checkbox
                                theme="black"
                                isChecked={
                                  isRatingEnabledPerLayerIndex.get(index)
                                    ? true
                                    : false
                                }
                                onCheckboxChange={(isChecked: boolean) => {
                                  updateIsRatingEnabledPerLayerIndex(
                                    index,
                                    isChecked
                                  );
                                }}
                              />
                            )}
                          {!hideRatingStar && getRatingStarRating && (
                            <RatingStar
                              extraClassNames={{
                                container: styles.ratingStarContainer,
                              }}
                              size="medium"
                              rating={getRatingStarRating(index)}
                            />
                          )}
                        </div>
                      )}
                      {getDoShowRefreshColumnButton(headerTitle, index) &&
                        refreshColumnHandler && (
                          <div
                            title={
                              showRefreshButtonAsGenerate
                                ? "Generate"
                                : "Refresh"
                            }
                            className={`${styles.firstButton} ${styles.tableHeaderCellRefreshContainer
                              } ${showRefreshButtonAsGenerate ? styles.focused : ""
                              }`}
                            onClick={() => {
                              refreshColumnHandler(headerTitle);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={
                                showRefreshButtonAsGenerate
                                  ? faMessageBot
                                  : faRotateRight
                              }
                            />
                          </div>
                        )}
                      {getDoShowDeleteColumnButton(index) &&
                        deleteColumnHandler && (
                          <div
                            title="Delete"
                            className={`${styles.tableHeaderCellRefreshContainer
                              } ${showRefreshButtonAsGenerate ? styles.focused : ""
                              }`}
                            onClick={() => {
                              deleteColumnHandler(headerTitle, index);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        )}
                    </div>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableObjectData.map((tableRow, rowIndex) => {
            return (
              // using something else than row index as key can cause issues with the table
              // eslint-disable-next-line react/no-array-index-key
              <tr key={`table-row-${rowIndex}`}>
                {tableRow.map((tableCell, columnIndex) => {
                  return (
                    <ScoutingServiceTableCell
                      // using something else than row and column indexes as key can cause issues with the table
                      // eslint-disable-next-line react/no-array-index-key
                      key={`table-cell-${rowIndex}-${columnIndex}`}
                      type={type}
                      tableObjectData={tableObjectData}
                      tableCell={tableCell}
                      rowIndex={rowIndex}
                      columnIndex={columnIndex}
                      headerTitles={headerTitles}
                      recursivelyGetAllBottomChildrenCount={
                        recursivelyGetAllBottomChildrenCount
                      }
                      requirementSummaryRequestedPerTableCell={
                        requirementSummaryRequestedPerTableCell
                      }
                      isNumbered={isNumbered}
                      selectedRowOrCell={selectedRowOrCell}
                      setSelectedRowOrCell={setSelectedRowOrCell}
                      refreshCellHandler={refreshCellHandler}
                      refreshRowHandler={refreshRowHandler}
                      setTableObjectData={setTableObjectData}
                      isRequestingRequirementSummary={
                        isRequestingRequirementSummary
                      }
                      resetTableCellSelection={resetTableCellSelection}
                      isRatingEnabled={isRatingEnabled && isRequirementsTable}
                      objectIdEdited={objectIdEdited}
                      objectTypeEdited={objectTypeEdited}
                      getRatingStarRating={
                        isRequirementsTable ? getRatingStarRating : undefined
                      }
                      isCellEditable={isTableEditable}
                      maturityLevelPerEntityId={maturityLevelPerEntityId}
                      setMaturityLevelPerEntityId={setMaturityLevelPerEntityId}
                      extraClassNames={{
                        cellActionsContainer:
                          extraClassNames.cellActionsContainer,
                      }}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
