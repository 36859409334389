import { useContext, useEffect } from "react";
import { PubSubContext } from "Providers";
import { PubSubEventTypeEnum } from "Enums";

export const useDocumentAddedFromBrowserExtensionListener = (
  onDocumentAddedFromBrowserExtension: (userId: string) => void
) => {
  const { pubSubHandler } = useContext(PubSubContext);
  useEffect(() => {
    pubSubHandler.subscribeToEvent(
      PubSubEventTypeEnum.DocumentAddedFromBrowserExtension,
      onDocumentAddedFromBrowserExtension
    );

    return () => {
      pubSubHandler.unsubscribeFromEvent(
        PubSubEventTypeEnum.DocumentAddedFromBrowserExtension,
        onDocumentAddedFromBrowserExtension
      );
    };
  }, [onDocumentAddedFromBrowserExtension, pubSubHandler]);
};
