import { FC } from "react";
import { FindestButton, Modal, ProgressIndicator } from "Components";
import styles from "./fileUploadProgressIndicator.module.scss";

type TFileUploadProgressIndicatorProps = {
    fileUploadProgress?: number,
    onCancelClick?: () => void
};

export const FileUploadProgressIndicator: FC<TFileUploadProgressIndicatorProps> = ({ fileUploadProgress = 0, onCancelClick }: TFileUploadProgressIndicatorProps) => {
    const progress = fileUploadProgress % 100;

    return (
        <Modal extraClassNames={{ container: styles.fileUploadProgressIndicatorModal, header: styles.header }} isOpen={!!fileUploadProgress} header="Uploading file">
            <div className={styles.body}>
                <ProgressIndicator progress={progress} />
                {!!fileUploadProgress && fileUploadProgress > 98 && (
                    <p className={styles.processingText}>Processing file on server...</p>
                )}
            </div>
            {onCancelClick && <div className={styles.footer}>
                <FindestButton
                    title="Cancel"
                    onClick={onCancelClick} 
                    buttonType="cancel"
                />
            </div>}
        </Modal>
    );
};