import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CreateQueryWizard, FindestButton, HasAdvanced, ListHeader, LoadingStatusIndicator } from "Components";
import { QueryDetailsProvidedLoader } from "./QueryDetailsProvidedLoader";
import { QueryItem } from "./QueryItem";
import { OnboardingConstants, QueryConstants } from "Constants";
import { QueryControllerSingleton } from "Controllers";
import {
  LogFeatureNameEnum,
  ObjectTypeEnum,
  OwnershipEnum,
  SearchQueryTypeEnum,
  SortTypeEnum,
  ToastTypeEnum,
} from "Enums";
import {
  FilterHelperSingleton,
  LogHelperSingleton,
  OwnershipHelperSingleton,
  SavedFiltersHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
import {
  TButtonDefinition,
  TIdNameTypeObjectType,
  TOption,
  TQueriesDTO,
} from "Types";
import listHeaderStyles from "Components/Shared/Lists/ListHeader/listHeader.module.scss";
import styles from "./queriesPage.module.scss";
import { useCheckboxedList, useQueryNameChangeListener } from "Hooks";
import { useQueryCreatedListener } from "Hooks/PubSub/useQueryCreatedListener";
import { IQueryDTO } from "Interfaces";
import { AuthContext, OnboardingContext, WindowingContext } from "Providers";

export const QueriesPage: FC = () => {
  // Context
  const { addSearchWindow, deleteWindow, updateQueryWindowState } =
    useContext(WindowingContext);
  const {
    auth: { userEmail },
  } = useContext(AuthContext);
  const { spotlightElement, registerSpotlightElementRefs, clearSpotlight, spotlightDetails } = useContext(OnboardingContext);

  const createQueryWizardContainerRef = useRef<HTMLDivElement>(null);

  // States
  const [queries, setQueries] = useState<IQueryDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    TOption<OwnershipEnum>[]
  >([]);
  const [sortType, setSortType] = useState<SortTypeEnum>(SortTypeEnum.Newest);
  const [totalQueriesCount, setTotalQueriesCount] = useState<number>(0);
  const [lastPaginationFromDates, setLastPaginationFromDates] = useState<
    Date[]
  >([]);

  const queryCreatedHandler = useCallback(
    (query: IQueryDTO) => {
      setQueries((state) => [query, ...state]);
      setIsLoading(false);
    },
    [setQueries]
  );

  // PubSub
  useQueryCreatedListener(queryCreatedHandler);

  // Hooks
  const {
    selectedItems: selectedQueries,
    setSelectedItems: setSelectedQueries,
    areAllItemsSelected: areAllQueriesSelected,
    isAnyItemSelected: isAnyQuerySelected,
    onSelectAllItems,
    onSelectItem,
  } = useCheckboxedList<IQueryDTO>(queries, "Query", "Queries", (query) => ({
    id: query.guid,
    name: query.name,
    type: "Query",
    objectType: ObjectTypeEnum.Query,
  }));

  const refreshQueriesAsync = useCallback(
    async (
      currentSelectedFilterOptions: TOption<OwnershipEnum>[],
      currentSortType: SortTypeEnum,
      fromDate: Date | undefined
    ): Promise<void> => {
      const retrievedQueries: TQueriesDTO =
        await QueryControllerSingleton.getAsync(
          FilterHelperSingleton.getIsCreatedByMeSelected(
            currentSelectedFilterOptions
          ),
          currentSortType,
          fromDate
        );
      setQueries(retrievedQueries.queries);
      setTotalQueriesCount(retrievedQueries.totalQueriesCount);
      setIsLoading(false);
    },
    []
  );

  useEffect(() => {
    (async () => {
      await refreshQueriesAsync(selectedFilterOptions, sortType, undefined);
    })();
  }, [refreshQueriesAsync, selectedFilterOptions, sortType]);

  // Logic
  useEffect(() => {
    // get saved filters in local storage
    const savedFilters: TOption<OwnershipEnum>[] =
      SavedFiltersHelperSingleton.getQueriesFilters();

    // if there are saved filters, set them as selected
    if (savedFilters.length > 0) {
      setSelectedFilterOptions(savedFilters);
    }

    // log
    LogHelperSingleton.log("DisplayQueries");
  }, []);

  const onSelectAllCheckboxChange = (isChecked: boolean) => {
    onSelectAllItems(isChecked, queries);
  };

  // TODO: deduplicate this code somehow across all pages like this
  const updateSortTypeAsync = async (
    newSortType: SortTypeEnum
  ): Promise<void> => {
    // safety-checks
    if (newSortType === sortType) {
      return;
    }

    // set new sort type
    setSortType(newSortType);

    // log
    LogHelperSingleton.log("SortQueries");
  };

  // TODO: deduplicate this code somehow across all pages like this
  const onPaginatePreviousAsync = async (
    currentLastPaginationFromDates: Date[],
    currentSelectedFilterOptions: TOption<OwnershipEnum>[],
    currentSortType: SortTypeEnum,
    currentRefreshQueriesAsync: (
      selectedFilterOptions: TOption<OwnershipEnum>[],
      sortType: SortTypeEnum,
      fromDate: Date | undefined
    ) => Promise<void>
  ): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (
      currentLastPaginationFromDates &&
      currentLastPaginationFromDates.length > 0
    ) {
      const lastPaginationFromDate: Date | undefined =
        currentLastPaginationFromDates.pop();
      if (currentLastPaginationFromDates.length > 1) {
        if (lastPaginationFromDate) {
          fromDate = lastPaginationFromDate;
        }
      }
      setLastPaginationFromDates(currentLastPaginationFromDates);
    }

    // update queries list
    await currentRefreshQueriesAsync(
      currentSelectedFilterOptions,
      currentSortType,
      fromDate
    );

    // log
    LogHelperSingleton.log("GoToPreviousQueriesPage");
  };

  // TODO: deduplicate this code somehow across all pages like this
  const onPaginateNextAsync = async (
    currentLastPaginationFromDates: Date[],
    currentQueries: IQueryDTO[],
    currentSelectedFilterOptions: TOption<OwnershipEnum>[],
    currentSortType: SortTypeEnum,
    currentRefreshQueriesAsync: (
      selectedFilterOptions: TOption<OwnershipEnum>[],
      sortType: SortTypeEnum,
      fromDate: Date | undefined
    ) => Promise<void>
  ): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (currentQueries && currentQueries.length > 0) {
      const lastPaginationFromDate: Date =
        currentQueries[currentQueries.length - 1].dateCreated;
      fromDate = lastPaginationFromDate;
      currentLastPaginationFromDates.push(fromDate);
      setLastPaginationFromDates(currentLastPaginationFromDates);
    }

    // update queries list
    await currentRefreshQueriesAsync(
      currentSelectedFilterOptions,
      currentSortType,
      fromDate
    );

    // log
    LogHelperSingleton.log("GoToNextQueriesPage");
  };

  const deleteQueriesAsync = async (
    queriesToDelete: IQueryDTO[],
    currentTotalQueriesCount: number,
    currentSelectedFilterOptions: TOption<OwnershipEnum>[],
    currentSortType: SortTypeEnum,
    currentRefreshQueriesAsync: (
      selectedFilterOptions: TOption<OwnershipEnum>[],
      sortType: SortTypeEnum,
      fromDate: Date | undefined
    ) => Promise<void>,
    currentSetSelectedQueries: Dispatch<SetStateAction<TIdNameTypeObjectType[]>>
  ): Promise<void> => {
    // safety-checks
    if (!queriesToDelete || queriesToDelete.length < 1) {
      return;
    }

    // Confirm with the user that they want to delete the queries
    const confirmationMessage =
      queriesToDelete.length === 1
        ? "Are you sure you want to delete the query?"
        : "Are you sure you want to delete the selected queries?";

    if (!confirm(confirmationMessage)) return;

    // bulk delete queries
    const isSuccess: boolean = await QueryControllerSingleton.bulkDeleteAsync(
      queriesToDelete.map((query: IQueryDTO) => query.guid)
    );

    // safety-checks
    if (!isSuccess) {
      // show error message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Failed to delete the query(ies)."
      );
      // stop execution
      return;
    }

    // update queries list
    await currentRefreshQueriesAsync(
      currentSelectedFilterOptions,
      currentSortType,
      undefined
    );

    // reset selected queries
    currentSetSelectedQueries([]);

    // set total queries count
    setTotalQueriesCount(currentTotalQueriesCount - queriesToDelete.length);

    // log
    LogHelperSingleton.log("RemoveQuery(ies)");
  };

  // Hooks live update the query name
  useQueryNameChangeListener(setQueries);

  // TODO: deduplicate this code somehow across all pages like this
  const onDeleteClickAsync = useCallback(
    async (
      currentSelectedQueries: TIdNameTypeObjectType[],
      currentQueries: IQueryDTO[],
      currentTotalQueriesCount: number,
      currentSelectedFilterOptions: TOption<OwnershipEnum>[],
      currentSortType: SortTypeEnum,
      currentRefreshQueriesAsync: (
        selectedFilterOptions: TOption<OwnershipEnum>[],
        sortType: SortTypeEnum,
        fromDate: Date | undefined
      ) => Promise<void>,
      currentSetSelectedQueries: Dispatch<
        SetStateAction<TIdNameTypeObjectType[]>
      >
    ): Promise<void> => {
      // safety-checks
      if (currentSelectedQueries.length === 0) {
        return;
      }

      // deleted selected queries
      await deleteQueriesAsync(
        currentQueries.filter(
          (query: IQueryDTO) =>
            currentSelectedQueries.find(
              (currentSelectedQuery: TIdNameTypeObjectType) =>
                currentSelectedQuery.id === query.guid
            ) !== undefined
        ),
        currentTotalQueriesCount,
        currentSelectedFilterOptions,
        currentSortType,
        currentRefreshQueriesAsync,
        currentSetSelectedQueries
      );
    },
    []
  );

  const onQueryCheckboxChange = (isChecked: boolean, id: string): void => {
    const currentQuery = queries.find((query) => query.guid === id);
    if (!currentQuery) {
      return;
    }
    onSelectItem(isChecked, currentQuery, id);
  };

  const onQueryClick = useCallback(
    (query: IQueryDTO, actionOrigin: string) => {
      // add search window
      addSearchWindow(
        query,
        <QueryDetailsProvidedLoader
          queryId={query.guid}
          onDelete={() => {
            // refresh queries
            refreshQueriesAsync(
              selectedFilterOptions,
              sortType,
              lastPaginationFromDates[lastPaginationFromDates.length - 1]
            );

            // delete the window
            deleteWindow(query.guid);
          }}
          onDuplicateAsync={async (duplicateQuery: IQueryDTO) => {
            // refresh queries
            await refreshQueriesAsync(
              selectedFilterOptions,
              sortType,
              lastPaginationFromDates[lastPaginationFromDates.length - 1]
            );

            // delete the window
            deleteWindow(query.guid);

            // open the duplicate query
            onQueryClick(duplicateQuery, "DuplicateButton");
          }}
          onSearchStarted={(
            correlationId: string,
            type: SearchQueryTypeEnum,
            isTechnologySearchOnResults?: boolean | null,
            abortController?: AbortController
          ) => {
            updateQueryWindowState(query.guid, {
              correlationId,
              type,
              isTechnologySearchOnResults,
              abortController,
            });
          }}
          onSearchCompleted={(isTechnologySearchOnResults?: boolean | null) => {
            updateQueryWindowState(query.guid, {
              correlationId: undefined,
              type: undefined,
              isTechnologySearchOnResults,
              abortController: undefined,
            });
          }}
        />,
        actionOrigin
      );
    },
    [
      addSearchWindow,
      deleteWindow,
      lastPaginationFromDates,
      refreshQueriesAsync,
      selectedFilterOptions,
      sortType,
      updateQueryWindowState,
    ]
  );

  const onQueryItemClick = (query: IQueryDTO) => {
    onQueryClick(query, LogFeatureNameEnum.QueriesPage);
  };

  const queriesHeaderButtons = useMemo(() => {
    return [
      {
        title: "Delete",
        icon: faTrashCan,
        onClick: async () => {
          await onDeleteClickAsync(
            selectedQueries,
            queries,
            totalQueriesCount,
            selectedFilterOptions,
            sortType,
            refreshQueriesAsync,
            setSelectedQueries
          );
        },
        className: listHeaderStyles.trashIcon,
      },
    ] as TButtonDefinition[];
  }, [
    onDeleteClickAsync,
    queries,
    refreshQueriesAsync,
    selectedFilterOptions,
    selectedQueries,
    setSelectedQueries,
    sortType,
    totalQueriesCount,
  ]);

  useEffect(() => {
    if (!createQueryWizardContainerRef.current) {
      return;
    }
    registerSpotlightElementRefs({
      [OnboardingConstants.CREATE_QUERY_CONTAINER]: createQueryWizardContainerRef.current,
    });
  }, [registerSpotlightElementRefs]);

  const onCreateAQueryButtonClick = () => {
    spotlightElement(OnboardingConstants.CREATE_QUERY_FLOW, 0);
  };

  return (
    <HasAdvanced>
      <div className={styles.queriesPageContainer}>
        <div ref={createQueryWizardContainerRef}>
          <CreateQueryWizard userEmail={userEmail} onOptionClickCallback={() => { if (spotlightDetails) clearSpotlight() ; }}/>
        </div>
        {isLoading && <LoadingStatusIndicator status={1} shouldCenter />}
        {!isLoading && queries.length === 0 && (
          <div className={styles.emptyStateContent}>
            <h3>Query list is currently empty.</h3>
            <p className={styles.emptyStateDescription}>{QueryConstants.CREATE_QUERY_DESCRIPTION}</p>
            <FindestButton title="Create a query" buttonType="secondary" onClick={onCreateAQueryButtonClick} />
          </div>
        )}
        {queries.length > 0 && (
          <div className={styles.queries}>
            <ListHeader
              isAllListItemsSelected={areAllQueriesSelected}
              onSelectAllCheckboxChange={onSelectAllCheckboxChange}
              sortType={sortType}
              updateSortType={updateSortTypeAsync}
              totalListItemCount={totalQueriesCount}
              onPaginatePrevious={async () => {
                await onPaginatePreviousAsync(
                  lastPaginationFromDates,
                  selectedFilterOptions,
                  sortType,
                  refreshQueriesAsync
                );
              }}
              onPaginateNext={async () => {
                await onPaginateNextAsync(
                  lastPaginationFromDates,
                  queries,
                  selectedFilterOptions,
                  sortType,
                  refreshQueriesAsync
                );
              }}
              filterOptions={[
                ...OwnershipHelperSingleton.ownershipFilterDropdownOptions,
              ]}
              listItemCountInterval={QueryConstants.MAXIMUM_QUERIES_TO_RETRIEVE}
              buttonDefinitions={queriesHeaderButtons}
              isAnyListItemSelected={isAnyQuerySelected}
            />
            <div className={styles.queriesList}>
              {queries.map((query) => {
                const isSelected =
                  selectedQueries.find(
                    (selectedQuery) => selectedQuery.id === query.guid
                  ) !== undefined;
                return (
                  <QueryItem
                    key={query.guid}
                    query={query}
                    isSelected={isSelected}
                    onCheckboxChange={onQueryCheckboxChange}
                    onQueryClick={onQueryItemClick}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </HasAdvanced>
  );
};
