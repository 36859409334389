import {
  faArrowUpRightFromSquare,
  faCheck,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useContext, useState } from "react";
import styles from "./onboardingChecklist.module.scss";
import { ProgressIndicator } from "Components";
import { TUserOnboardingTaskDTO } from "Types";
import { OnboardingTaskNameEnum } from "Enums";
import {
  usePageCreatedListener,
  useContentGeneratedFromAskIgorListener,
  useDocumentLinkedToPageListener,
  useDocumentAddedFromBrowserExtensionListener,
} from "Hooks";
import { OnboardingContext } from "Providers";
import { OnboardingConstants } from "Constants";

type TOnboardingChecklist = {
  userEmail: string;
  initialOnboardingChecklistItems: TUserOnboardingTaskDTO[];
};

export const OnboardingChecklist: FC<TOnboardingChecklist> = ({
  userEmail,
  initialOnboardingChecklistItems,
}: TOnboardingChecklist) => {
  const { spotlightElement } = useContext(OnboardingContext);
  const [onboardingChecklistItems, setOnboardingChecklistItems] = useState<
    TUserOnboardingTaskDTO[]
  >(initialOnboardingChecklistItems);

  const handleListeners = (eventType: OnboardingTaskNameEnum) => {
    const updatedChecklistItems = onboardingChecklistItems.map((item) => {
      if (item.name === eventType) {
        return {
          ...item,
          completedAt: new Date(),
        };
      }
      return item;
    });

    setOnboardingChecklistItems(updatedChecklistItems);
  };

  useDocumentAddedFromBrowserExtensionListener(() =>
    handleListeners(OnboardingTaskNameEnum.DocumentAddedFromBrowserExtension)
  );
  usePageCreatedListener(() =>
    handleListeners(OnboardingTaskNameEnum.CreatePage)
  );
  useContentGeneratedFromAskIgorListener(() =>
    handleListeners(OnboardingTaskNameEnum.GenerateAskIgorContent)
  );
  useDocumentLinkedToPageListener(() =>
    handleListeners(OnboardingTaskNameEnum.LinkDocument)
  );

  const progress =
    onboardingChecklistItems?.length === 0
      ? 0
      : (onboardingChecklistItems.filter((option) => option.completedAt)
        .length /
        onboardingChecklistItems.length) *
      100;

  const handleChecklistItemClick = (item: TUserOnboardingTaskDTO) => {
    if (item.url) {
      window.open(item.url, "_blank", "noopener noreferrer");
      return;
    }

    if (item.name === OnboardingTaskNameEnum.CreatePage) {
      spotlightElement(OnboardingConstants.CREATE_PAGE_FLOW, 0);
    }
  };

  return (
    <div className={styles.onboardingChecklist}>
      <div className={styles.headerContainer}>
        <div>
          <h3 className={styles.header}>Welcome {userEmail}!</h3>
          <p className={styles.description}>
            To get to know your Universe we prepared a small checklist to get
            you on your way!
          </p>
        </div>
        <ProgressIndicator progress={progress} theme="green" />
      </div>
      <div className={styles.listContainer}>
        {onboardingChecklistItems.map((option, index) => (
          <button
            type="button"
            key={option.id}
            className={styles.onboardingChecklistOption}
            onClick={() => handleChecklistItemClick(option)}
          >
            {option.completedAt ? (
              <div className={`${styles.leftIcon} ${styles.isCompleted}`}>
                <FontAwesomeIcon icon={faCheck} />
              </div>
            ) : (
              <div className={styles.leftIcon}>{index + 1}</div>
            )}
            <div className={styles.onboardingChecklistOptionTitle}>
              {option.text}
            </div>
            <div className={styles.onboardingChecklistOptionIcon}>
              {option.name === OnboardingTaskNameEnum.Login ? (
                "🎉"
              ) : (
                <FontAwesomeIcon icon={faChevronRight} />
              )}
            </div>
          </button>
        ))}
      </div>
      <div className={styles.footer}>
        Learn more about the Universe in{" "}
        <a
          className={styles.anchor}
          href={process.env.REACT_APP_FINDEST_DOCS_URL}
        >
          our documentation <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </div>
    </div>
  );
};
