import { FC, useEffect, useRef } from "react";
import styles from "./progressIndicator.module.scss";

type TProgressProps = {
  progress?: number,
  theme?: string,
};

export const ProgressIndicator: FC<TProgressProps> = ({ progress = 0, theme }: TProgressProps) => {
  const progressCircleRef = useRef<SVGCircleElement | null>(null);
  const currentProgress = (progress > 100 ? progress % 100 : progress).toString();

  useEffect(() => {
    if (progressCircleRef?.current?.style) {
      progressCircleRef.current.style.setProperty("--percent", currentProgress);
    }
  }, [currentProgress]);

  return (
    <div className={`${styles.percentage} ${theme ? styles[theme] : ""}`}>
      <svg>
        <circle cx="40" cy="40" r="30"></circle>
        <circle ref={progressCircleRef} cx="40" cy="40" r="30"></circle>
      </svg>
      <div className={styles.number}>
        {`${currentProgress}%`}
      </div>
    </div>
  );
};
