import { OnboardingConstants } from "Constants";
import { ReactNode, createContext, useCallback, useMemo, useRef, useState } from "react";

type TSpotlightDetails = {
  element: HTMLElement;
  width: number;
  height: number;
  x: number;
  y: number;
  borderRadius: number;
  selectedStep: number;
  flowName: string;
};

type TOnboardingContext = {
  spotlightDetails: TSpotlightDetails | null;
  spotlightElement: (flow: string, selectedFlowStep: number, extraSpaces?: { [key: string]: number }) => void;
  registerSpotlightElementRefs: (elements: { [key: string]: HTMLElement }) => void;
  clearSpotlight: () => void;
};

type TOnboardingProviderProps = { children?: ReactNode; };

interface OnboardingStep {
  name: string;
  steps: string[];
}

export const defaultOnboardingContext: TOnboardingContext = {
  spotlightDetails: null,
  spotlightElement: () => { return; },
  registerSpotlightElementRefs: () => { return; },
  clearSpotlight: () => { return; },
};

export const OnboardingContext = createContext<TOnboardingContext>(
  defaultOnboardingContext
);

const onboardingFlow: Record<string, OnboardingStep> = {
  [OnboardingConstants.CREATE_PAGE_FLOW]: {
    name: OnboardingConstants.CREATE_PAGE_FLOW,
    steps: [
      OnboardingConstants.CREATE_NEW_BUTTON,
      OnboardingConstants.CREATE_NEW_MODAL_PAGES_CONTAINER,
    ],
  },
  [OnboardingConstants.ADD_DOCUMENT_FLOW]: {
    name: OnboardingConstants.ADD_DOCUMENT_FLOW,
    steps: [
      OnboardingConstants.CREATE_NEW_BUTTON,
      OnboardingConstants.CREATE_NEW_MODAL_DOCUMENT_CONTAINER,
    ],
  },
  [OnboardingConstants.CREATE_ENTITY_FLOW]: {
    name: OnboardingConstants.CREATE_ENTITY_FLOW,
    steps: [
      OnboardingConstants.CREATE_NEW_BUTTON,
      OnboardingConstants.CREATE_NEW_MODAL_ENTITY_CONTAINER,
    ],
  },
  [OnboardingConstants.CREATE_STUDY_FLOW]: {
    name: OnboardingConstants.CREATE_STUDY_FLOW,
    steps: [
      OnboardingConstants.CREATE_NEW_BUTTON,
      OnboardingConstants.CREATE_NEW_MODAL_STUDY_CONTAINER,
    ],
  },
  [OnboardingConstants.CREATE_QUERY_FLOW]: {
    name: OnboardingConstants.CREATE_QUERY_FLOW,
    steps: [
      OnboardingConstants.CREATE_QUERY_CONTAINER,
    ],
  }
};

export const OnboardingProvider = ({ children }: TOnboardingProviderProps) => {
  const [spotlightDetails, setSpotlightDetails] = useState<TSpotlightDetails | null>(null);
  const spotlightRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  const registerSpotlightElementRefs = (elements: { [key: string]: HTMLElement }) => {
    Object.keys(elements).forEach(key => {
        spotlightRefs.current[key] = elements[key];
    });
  };

  const spotlightElement = useCallback((flow: string, selectedFlowStep: number, extraSpaces: { top?: number, bottom?: number, left?: number, right?: number } = {}) => {

    const element = spotlightRefs.current[onboardingFlow[flow].steps[selectedFlowStep]];
    if (!element) return;

    const { top = 0, bottom = 0, left = 0, right = 0 } = extraSpaces;
    const { y, x, width, height } = element.getBoundingClientRect();

    setSpotlightDetails({
      element,
      selectedStep: selectedFlowStep,
      flowName: onboardingFlow[flow].name,
      width: width + left + right,
      height: height + top + bottom,
      x: x - left,
      y: y - top,
      borderRadius: parseInt(window.getComputedStyle(element).getPropertyValue("border-radius")),
    });
  }, []);

  const clearSpotlight = () => {
    setSpotlightDetails(null);
  };

  const providerValue = useMemo(() => {
    return { spotlightDetails, spotlightElement, registerSpotlightElementRefs, clearSpotlight };
  }, [spotlightDetails, spotlightElement]);

  return (
    <OnboardingContext.Provider value={providerValue}>
      {children}
    </OnboardingContext.Provider>
  );
};
