type TSpotlightProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  borderRadius: number;
  clearSpotlight: () => void;
};

export const Spotlight: React.FC<TSpotlightProps> = ({ x, y, width, height, borderRadius, clearSpotlight }: TSpotlightProps) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        zIndex: 2147483647,
        pointerEvents: "none",
        transition: ".3s ease-in-out",
      }}
    >
      {/* Top Rectangle */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: `${y}px`,
          pointerEvents: "auto",
          transition: ".3s ease-in-out",
        }}
        onClick={clearSpotlight}
        aria-hidden="true"
      />

      {/* Bottom Rectangle */}
      <div
        style={{
          position: "absolute",
          top: `${y + height}px`,
          left: 0,
          width: "100%",
          height: `calc(100vh - ${y + height}px)`,
          pointerEvents: "auto",
          transition: ".3s ease-in-out",
        }}
        onClick={clearSpotlight}
        aria-hidden="true"
      />

      {/* Left Rectangle */}
      <div
        style={{
          position: "absolute",
          top: `${y}px`,
          left: 0,
          width: `${x}px`,
          height: `${height}px`,
          pointerEvents: "auto",
          transition: ".3s ease-in-out",
        }}
        onClick={clearSpotlight}
        aria-hidden="true"
      />

      {/* Right Rectangle */}
      <div
        style={{
          position: "absolute",
          top: `${y}px`,
          left: `${x + width}px`,
          width: `calc(100vw - ${x + width}px)`,
          height: `${height}px`,
          pointerEvents: "auto",
          transition: ".3s ease-in-out",
        }}
        onClick={clearSpotlight}
        aria-hidden="true"
      />

      {/* Center Transparent Area with Rounded Corners */}
      <div
        style={{
          position: "absolute",
          top: `${y}px`,
          left: `${x}px`,
          width: `${width}px`,
          height: `${height}px`,
          pointerEvents: "none", // Makes sure this is non-interactive
          borderRadius: `${borderRadius}px`,
          boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.7)",
          transition: ".3s ease-in-out",
        }}
      />
    </div>
  );
};

export default Spotlight;
