import { JSONContent } from "@tiptap/react";
import { EditorConstants } from "Constants";
import { StudyControllerSingleton } from "Controllers";
import { ToastTypeEnum } from "Enums";
import { ToastHelperSingleton } from "Helpers";
import { IStudyDTO } from "Interfaces";

export const useStudy = () => {
  const updateStudyDescriptionAsync = async (
    studyId: string,
    newDescription: string,
    studyData: Partial<IStudyDTO> = {}
  ): Promise<boolean> => {
    const studyToUpdate: IStudyDTO = {
      id: studyId,
      description: newDescription,
      ...studyData,
    } as IStudyDTO;

    const isSuccess: boolean = await StudyControllerSingleton.updateAsync(
      studyToUpdate
    );

    if (!isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not update study description."
      );

      return false;
    }

    return true;
  };

  const appendContentToStudyDescriptionAsync = async (
    studyId: string,
    content: JSONContent[]
  ): Promise<boolean> => {
    const study: IStudyDTO | undefined =
      await StudyControllerSingleton.getByIdAsync(studyId);

    if (!study) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not get study."
      );

      return false;
    }

    let newDescription: JSONContent =
      EditorConstants.DEFAULT_TIPTAP_EDITOR_JSON_CONTENT;
    try {
      newDescription = study.description
        ? JSON.parse(study.description)
        : EditorConstants.DEFAULT_TIPTAP_EDITOR_JSON_CONTENT;
    } catch (error) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not get study description."
      );
      return false;
    }

    newDescription.content?.push(...content);

    return updateStudyDescriptionAsync(
      study.id,
      JSON.stringify(newDescription),
      {
        id: study.id,
        title: study.title,
        status: study.status,
        type: study.type,
        customTypeName: study.customTypeName,
        conclusion: study.conclusion,
      }
    );
  };

  return { updateStudyDescriptionAsync, appendContentToStudyDescriptionAsync };
};
