import { FindestButton } from "Components";
import { FC, useContext } from "react";
import styles from "./emptyStates.module.scss";
import { OnboardingConstants } from "Constants";
import { OnboardingContext } from "Providers";

type TEmptyDocumentsProps = {
  route: string;
};

export const EmptyDocuments: FC<TEmptyDocumentsProps> = ({ route }: TEmptyDocumentsProps) => {
  const { spotlightElement } = useContext(OnboardingContext);

  const onAddDocumentsManuallyButtonClick = () => {
    spotlightElement(OnboardingConstants.ADD_DOCUMENT_FLOW, 0);
  };

  const onGetTheExtensionButtonClick = () => {
    window.open("https://www.findest.com/universe/browser-extension/tutorial-1", "_blank", "noopener noreferrer");
  };

  return (
    <div className={styles.emptyStateContainer}>
      <h3 className={styles.header}>{route}</h3>
      <div className={styles.content}>
        <div className={styles.contentBox}>
          <h3 className={styles.descriptionHeader}>Your {route} is currently empty.</h3>
          {route === "Inbox" ? (
            <>
              <p className={styles.paragraph}>Here, you can save sources and documents for yourself before linking them to the Universe.</p>
              <p className={styles.paragraph}>Everything added through the extension will land here.</p>
            </>
          ) : (
            <>
              <p className={styles.paragraph}>Here, you can see the documents that is in your Universe.</p>
              <p className={styles.paragraph}>Everything added through the extension will land here after linking it to an entity or study, or making a highlight on the document.</p>
            </>
          )}
          <p className={styles.paragraph}>Install our extension and start capturing information from the web into your Universe.</p>
          <div className={styles.buttonsContainer}>
            <FindestButton buttonType="primary" title="Get the Findest browser extension" onClick={onGetTheExtensionButtonClick}/>
            <FindestButton buttonType="secondary" title="Add documents manually" onClick={onAddDocumentsManuallyButtonClick} />
          </div>
        </div>
      </div>
    </div>
  );
};
