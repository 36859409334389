import { AiConstants, TextConstants, WebsocketFunctionNames } from "Constants";
import { LinkingControllerSingleton } from "Controllers";
import { AskIgorMenuItemEnum, DocumentObjectTypeEnums, ObjectTypeEnum, ToastTypeEnum } from "Enums";
import { AskIgorMenuItemHelperSingleton, LogHelperSingleton, ToastHelperSingleton } from "Helpers";
import { IAskIgorRequest, IGptForTiResponse } from "Interfaces";
import { WebsocketContext } from "Providers";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { TLogEventName } from "Types";

export const useInformationExtraction = (processCompletionResultAsync: (request: IAskIgorRequest, result: IGptForTiResponse, completionId: string) => Promise<void>,
  onAllInformationRequestsDone?: () => void) => {
  const [informationExtractionRequested, setInformationExtractionRequested] =
    useState<Map<string, IAskIgorRequest>>(new Map<string, IAskIgorRequest>());

  const { webSocketController } = useContext(WebsocketContext);

  const timeoutRef = useRef<Map<string, number>>(new Map<string, number>());

  const isRequestingInformationExtraction = useMemo((): boolean => {
    return informationExtractionRequested.size > 0;
  }, [informationExtractionRequested.size]);

  const cancelAllInformationExtractionRequests = useCallback((doSkipCallback = true) => {
    informationExtractionRequested.forEach(
      (_: IAskIgorRequest, completionId: string) => {
        webSocketController.invokeFunction(
          WebsocketFunctionNames.CancelCompletion,
          completionId,
          true
        );

        if (timeoutRef.current.has(completionId)) {
          clearTimeout(timeoutRef.current.get(completionId));
          timeoutRef.current.delete(completionId);
        }
      }
    );
    setInformationExtractionRequested(new Map());

    timeoutRef.current.forEach((timeoutId: number) => {
      clearTimeout(timeoutId);
    });
    timeoutRef.current.clear();

    if (!doSkipCallback) onAllInformationRequestsDone?.();
  }, [informationExtractionRequested, onAllInformationRequestsDone, webSocketController]);

  const cancelInformationExtractionRequest = useCallback((forInput: string): void => {
    setInformationExtractionRequested((prev) => {
      prev.forEach(
        (informationExtractionRequest: IAskIgorRequest, completionId: string) => {
          if (informationExtractionRequest.input === forInput) {
            webSocketController.invokeFunction(
              WebsocketFunctionNames.CancelCompletion,
              completionId,
              true
            );

            if (timeoutRef.current.has(completionId)) {
              clearTimeout(timeoutRef.current.get(completionId));
              timeoutRef.current.delete(completionId);
            }

            prev.delete(completionId);
          }
        }
      );

      if (prev.size === 0) {
        onAllInformationRequestsDone?.();
      }

      return new Map<string, IAskIgorRequest>(prev);
    });
  }, [onAllInformationRequestsDone, webSocketController]);

  const deleteInformationExtractionRequested = useCallback((
    completionId?: string | null
  ): void => {
    if (!completionId) return;

    setInformationExtractionRequested((prev) => {
      prev.delete(completionId);
      if (prev.size === 0) {
        onAllInformationRequestsDone?.();
      }
      return new Map<string, IAskIgorRequest>(prev);
    });

    if (timeoutRef.current.has(completionId)) {
      clearTimeout(timeoutRef.current.get(completionId));
      timeoutRef.current.delete(completionId);
    }
  }, [onAllInformationRequestsDone]);

  const onAiGenerationTimedOut = useCallback((completionId: string): void => {
    webSocketController.invokeFunction(
      WebsocketFunctionNames.CancelCompletion,
      completionId,
      true
    );

    if (!informationExtractionRequested.has(completionId)) return;

    deleteInformationExtractionRequested(completionId);

    ToastHelperSingleton.showToast(
      ToastTypeEnum.Error,
      AiConstants.AI_GENERATION_TIMED_OUT
    );
  }, [deleteInformationExtractionRequested, informationExtractionRequested, webSocketController]);

  const onReceiveCompletionResult = useCallback(
    async (
      result?: IGptForTiResponse | string | null | undefined,
      completionId?: string | null
    ): Promise<void> => {
      if (!completionId || !informationExtractionRequested.has(completionId)) return;

      if (typeof result === "string") {
        ToastHelperSingleton.showToast(ToastTypeEnum.Error, result);
        return;
      } else if (!result || !result.InformationExtraction) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          AskIgorMenuItemHelperSingleton.getRelatedErrorMessage(AskIgorMenuItemEnum.InformationExtraction)
        );
        return;
      }

      await processCompletionResultAsync(informationExtractionRequested.get(completionId) as IAskIgorRequest, result, completionId);

      deleteInformationExtractionRequested(completionId);
    },
    [deleteInformationExtractionRequested, informationExtractionRequested, processCompletionResultAsync]
  );

  const addInformationExtractionRequested = useCallback((requests: IAskIgorRequest[]): void => {
    setInformationExtractionRequested((prev) => {
      requests.forEach((request: IAskIgorRequest) => {
        prev.set(request.completionId, request);

        const timeoutId = window.setTimeout(onAiGenerationTimedOut, AiConstants.AI_GENERATION_TIMEOUT_DURATION, request.completionId);
        timeoutRef.current.set(request.completionId, timeoutId);
      });

      return new Map<string, IAskIgorRequest>(prev);
    });
  }, [onAiGenerationTimedOut]);

  const requestInformationExtractionAsync = useCallback(async (request: IAskIgorRequest): Promise<boolean> => {
    if (!request.dataPoints || request.dataPoints.length === 0) {
      const hasDocumentsOrHighlights =
        await LinkingControllerSingleton.hasLinksOfTypesAsync(request.objectId, [
          ObjectTypeEnum.Highlight,
          ...DocumentObjectTypeEnums,
        ]);

      if (!hasDocumentsOrHighlights) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          TextConstants.AI_REQUIRES_DOCUMENTS_OR_HIGHLIGHTS
        );
        return false;
      }
    }

    const webSocketFunctionName: string =
      AskIgorMenuItemHelperSingleton.getRelatedWebsocketFunctionName(
        AskIgorMenuItemEnum.InformationExtraction
      );

    if (!webSocketFunctionName) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        AiConstants.AI_GENERATION_NOT_SUPPORTED
      );
      return false;
    }

    const startLogEventName: TLogEventName =
      AskIgorMenuItemHelperSingleton.getRelatedStartLogEventName(
        AskIgorMenuItemEnum.InformationExtraction
      );

    LogHelperSingleton.log(startLogEventName);

    await webSocketController.invokeFunction(webSocketFunctionName, request);

    return true;
  }, [webSocketController]);

  useEffect(() => {
    webSocketController.addHandler(
      WebsocketFunctionNames.ReceiveCompletionResult,
      onReceiveCompletionResult
    );

    return () => {
      webSocketController.removeHandler(
        WebsocketFunctionNames.ReceiveCompletionResult,
        onReceiveCompletionResult
      );
    };
  }, [onReceiveCompletionResult, webSocketController]);

  return { informationExtractionRequested, addInformationExtractionRequested, isRequestingInformationExtraction, cancelAllInformationExtractionRequests, cancelInformationExtractionRequest, requestInformationExtractionAsync };
};
