import { FC, useCallback, useContext } from "react";
// Components
import { AddScoutingServiceItemModal } from "Components";
// Enums
import {
  LogFeatureNameEnum,
  ObjectTypeEnum,
  ScoutingServiceTableTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Controllers
import {
  RatingControllerSingleton,
  RequirementsTableControllerSingleton,
} from "Controllers";
// Helpers
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";
// Types
import {
  TInitialScoutingServiceTableProps,
  TLinkGraphDTO,
  TRequirementsTableDTO,
  TRequirementsTableEntryDTO,
  TRequirementsTableRowDTO,
  TScoutingServiceTableObject,
  TTableCellDTO,
} from "Types";
// Contexts
import { LinksContext } from "Providers";
import { AiConstants } from "Constants";

type TRequirementsTableModalProps = {
  objectIdEdited?: string;
  objectTypeEdited?: ObjectTypeEnum;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isEditing?: boolean;
  initialTableProps?: TInitialScoutingServiceTableProps;
  onDeleteRequirementsTableClickAsync?: (
    id: string,
    forObjectId: string,
    forObjectType: ObjectTypeEnum
  ) => Promise<boolean>;
  refreshRequirementsTableAsync: (
    forObjectId: string,
    forObjectType: ObjectTypeEnum,
    currentLinkGraphForObjectEdited: TLinkGraphDTO
  ) => Promise<void>;
};

export const RequirementsTableModal: FC<TRequirementsTableModalProps> = ({
  objectIdEdited,
  objectTypeEdited,
  isOpen,
  setIsOpen,
  isEditing,
  initialTableProps,
  onDeleteRequirementsTableClickAsync,
  refreshRequirementsTableAsync,
}: TRequirementsTableModalProps) => {
  // Contexts
  const { linkGraphForObjectEdited } = useContext(LinksContext);

  // Logic
  const buildNewRequirementsTableDTOAndRatingTargetIds = useCallback(
    (
      modalTitle: string,
      headerTitles: string[],
      selectedLayer: number,
      isNumbered: boolean,
      isRatingEnabled: boolean,
      tableObjectData: (TScoutingServiceTableObject | null)[][]
    ) => {
      // init requirements table objects
      const requirementsTableObjects: TScoutingServiceTableObject[][] = [];

      // init ratingTargetIds array
      const ratingTargetIds: string[] = [];

      const requirementAlreadyAdded: Set<number> = new Set();
      // init requirements table header titles
      const requirementsTableHeaderTitles: string[] = [];
      // go through each header title
      headerTitles.forEach((headerTitle: string, headerTitleIndex: number) => {
        // if header title index is 0 (first column with objects data)
        if (headerTitleIndex === 0) {
          // add header title to requirements table header titles
          requirementsTableHeaderTitles.push(headerTitle);
          // add header title index to requirement already added
          requirementAlreadyAdded.add(headerTitleIndex);
        }
      });

      // go trough each row in table object data
      tableObjectData.forEach((row: (TScoutingServiceTableObject | null)[]) => {
        // init isRowDisabled
        let isRowDisabled = false;

        // init requirements table objects row
        const requirementsTableObjectsRow: TScoutingServiceTableObject[] = [];

        // go trough each cell in the row
        for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
          // if row is disabled
          if (isRowDisabled) {
            // continue to next iteration
            continue;
          }

          // get cell
          const cell: TScoutingServiceTableObject | null = row[cellIndex];

          // if cell is not null, cell is not checked and is row disabled is false
          if (cell && !cell.isChecked && !isRowDisabled) {
            // set is row disabled to true
            isRowDisabled = true;
            // continue to next iteration
            continue;
          }

          // if cell is not null and cell is checked
          if (cell && cell.isChecked) {
            // if cell index is 0 (first column with objects data)
            if (cellIndex === 0) {
              // add cell to requirements table objects row
              requirementsTableObjectsRow.push(cell);
            } else if (cell.isRequirementValue && headerTitles[cellIndex]) {
              // otherwise, if cell is requirement value and headerTitles[cellIndex] is defined
              // if cell index is not in requirement already added
              if (!requirementAlreadyAdded.has(cellIndex)) {
                // add cell index to requirement already added
                requirementAlreadyAdded.add(cellIndex);
                // add requirement name to requirements table header titles
                requirementsTableHeaderTitles.push(headerTitles[cellIndex]);
              }

              // add cell to requirements table objects row
              requirementsTableObjectsRow.push(cell);
            }
          }
        }

        // if row is not disabled
        if (!isRowDisabled) {
          // add row to requirements table objects
          requirementsTableObjects.push(requirementsTableObjectsRow);
        }
      });

      // init requirements table rows
      const requirementsTableRows: TRequirementsTableRowDTO[] = [];

      // go through each requirements table objects
      requirementsTableObjects.forEach(
        (requirementsTableObjectsRow: TScoutingServiceTableObject[]) => {
          // init requirements table row
          const requirementsTableRow: TRequirementsTableEntryDTO[] = [];

          // get requirements table objects row first object
          const requirementsTableObjectsRowFirstObject:
            | TScoutingServiceTableObject
            | undefined = requirementsTableObjectsRow[0];

          // if requirements table objects row first object is not defined or is a study
          if (
            !requirementsTableObjectsRowFirstObject ||
            requirementsTableObjectsRowFirstObject.objectType ===
            ObjectTypeEnum.Study
          ) {
            // continue to next iteration
            return;
          }

          // go through each requirements table objects row
          requirementsTableObjectsRow.forEach(
            (
              requirementsTableObject: TScoutingServiceTableObject,
              requirementsTableObjectIndex: number
            ) => {
              // init requirements table cell
              const requirementsTableCell: TTableCellDTO = {
                objectId: requirementsTableObject.objectId,
                objectType: requirementsTableObject.objectType,
                value: requirementsTableObject.name,
              };

              // if requirements table object objectType is Entity
              // and requirements table object objectId is defined
              if (
                requirementsTableObject.objectType === ObjectTypeEnum.Entity &&
                requirementsTableObject.objectId
              ) {
                // add requirements table cell to rating target ids
                ratingTargetIds.push(requirementsTableObject.objectId);
              }

              // init new requirements table row entry
              const newRequirementsTableEntry: TRequirementsTableEntryDTO = {
                key: requirementsTableHeaderTitles[
                  requirementsTableObjectIndex
                ],
                value: requirementsTableCell,
              };

              // add new requirements table row entry to requirements table row
              requirementsTableRow.push(newRequirementsTableEntry);
            }
          );

          // add requirements table row to requirements table rows
          requirementsTableRows.push(requirementsTableRow);
        }
      );

      // init new TRequirementsTableDTO
      const newRequirementsTableDTO: TRequirementsTableDTO = {
        id: "",
        createdAt: new Date(),
        title: modalTitle,
        isNumbered,
        isRatingEnabled,
        tableRows: requirementsTableRows,
        layerNumber: selectedLayer,
      };

      return { newRequirementsTableDTO, ratingTargetIds };
    },
    []
  );

  const handleInsertScoutingServiceItemAsync = useCallback(
    async (
      modalTitle: string,
      selectedLayer: number,
      isNumbered: boolean,
      isRatingEnabled: boolean,
      tableObjectData: (TScoutingServiceTableObject | null)[][],
      headerTitles: string[],
      resetAndCloseModalCallback: () => void
    ) => {
      // safety-checks
      if (!objectIdEdited || !objectTypeEdited || !linkGraphForObjectEdited) {
        // stop execution, return
        return;
      }

      const { newRequirementsTableDTO, ratingTargetIds } =
        buildNewRequirementsTableDTOAndRatingTargetIds(
          modalTitle,
          headerTitles,
          selectedLayer,
          isNumbered,
          isRatingEnabled,
          tableObjectData
        );

      // safety-checks
      if (
        !newRequirementsTableDTO.tableRows ||
        newRequirementsTableDTO.tableRows.length === 0
      ) {
        // show error toast
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Requirements table must have at least one entity."
        );
        // stop execution, return
        return;
      }

      // call create requirements table
      const requirementsTable: TRequirementsTableDTO | undefined =
        await RequirementsTableControllerSingleton.createAsync(
          objectTypeEdited,
          objectIdEdited,
          newRequirementsTableDTO
        );

      // safety-checks
      if (!requirementsTable) {
        // show error toast
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Something went wrong when creating requirements table."
        );
        // stop execution, return
        return;
      }

      // if isRatingEnabled is true and ratingTargetIds is not empty
      if (isRatingEnabled && ratingTargetIds && ratingTargetIds.length > 0) {
        // predefine rating
        const isSuccess = await RatingControllerSingleton.predefineRatingAsync(
          objectIdEdited,
          objectTypeEdited,
          ratingTargetIds
        );

        // safety-checks
        if (!isSuccess) {
          // show error toast
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Something went wrong when predefining rating for the requirements table."
          );
          // stop execution, return
          return;
        }
      }

      LogHelperSingleton.log(
        `${LogFeatureNameEnum.Reporting}-InsertRequirementsTable`
      );

      // call reset and close modal callback
      resetAndCloseModalCallback();

      // call refresh requirements table
      await refreshRequirementsTableAsync(
        objectIdEdited,
        objectTypeEdited,
        linkGraphForObjectEdited
      );
    },
    [
      objectIdEdited,
      objectTypeEdited,
      linkGraphForObjectEdited,
      buildNewRequirementsTableDTOAndRatingTargetIds,
      refreshRequirementsTableAsync,
    ]
  );

  const handleUpdateScoutingServiceItemAsync = useCallback(
    async (
      itemId: string,
      modalTitle: string,
      selectedLayer: number,
      isNumbered: boolean,
      isRatingEnabled: boolean,
      tableObjectData: (TScoutingServiceTableObject | null)[][],
      headerTitles: string[],
      resetAndCloseModalCallback: () => void
    ) => {
      // safety-checks
      if (!objectIdEdited || !objectTypeEdited || !linkGraphForObjectEdited) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not update requirements table."
        );
        // stop execution, return
        return;
      }

      const { newRequirementsTableDTO, ratingTargetIds } =
        buildNewRequirementsTableDTOAndRatingTargetIds(
          modalTitle,
          headerTitles,
          selectedLayer,
          isNumbered,
          isRatingEnabled,
          tableObjectData
        );

      // safety-checks
      if (
        !newRequirementsTableDTO.tableRows ||
        newRequirementsTableDTO.tableRows.length === 0
      ) {
        // show error toast
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Requirements table must have at least one entity."
        );
        // stop execution, return
        return;
      }

      // call create requirements table
      const updatedRequirementsTable: TRequirementsTableDTO | undefined =
        await RequirementsTableControllerSingleton.updateAsync(
          itemId,
          newRequirementsTableDTO
        );

      // safety-checks
      if (!updatedRequirementsTable) {
        // show error toast
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Something went wrong when updating requirements table."
        );
        // stop execution, return
        return;
      }

      // if isRatingEnabled is true and ratingTargetIds is not empty
      if (isRatingEnabled && ratingTargetIds && ratingTargetIds.length > 0) {
        // predefine rating
        const isSuccess = await RatingControllerSingleton.predefineRatingAsync(
          objectIdEdited,
          objectTypeEdited,
          ratingTargetIds
        );

        // safety-checks
        if (!isSuccess) {
          // show error toast
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Something went wrong when predefining rating for the requirements table."
          );
          // stop execution, return
          return;
        }
      }

      LogHelperSingleton.log(
        `${LogFeatureNameEnum.Reporting}-UpdateRequirementsTable`
      );

      // call reset and close modal callback
      resetAndCloseModalCallback();

      // call refresh requirements table
      await refreshRequirementsTableAsync(
        objectIdEdited,
        objectTypeEdited,
        linkGraphForObjectEdited
      );
    },
    [
      objectIdEdited,
      objectTypeEdited,
      linkGraphForObjectEdited,
      buildNewRequirementsTableDTOAndRatingTargetIds,
      refreshRequirementsTableAsync,
    ]
  );

  const onDeleteScoutingServiceItemClickAsyncHandler = useCallback(
    async (id: string, callback: () => void): Promise<void> => {
      // safety-checks
      if (
        !onDeleteRequirementsTableClickAsync ||
        !objectIdEdited ||
        !objectTypeEdited
      ) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not delete requirements table."
        );
        // stop execution, return
        return;
      }

      // call on delete requirements table click
      const isSuccess: boolean = await onDeleteRequirementsTableClickAsync(
        id,
        objectIdEdited,
        objectTypeEdited
      );

      // if not success
      if (!isSuccess) {
        // stop execution, return
        return;
      }

      // call callback
      callback();
    },
    [objectIdEdited, objectTypeEdited, onDeleteRequirementsTableClickAsync]
  );

  // Render
  return (
    <AddScoutingServiceItemModal
      type={ScoutingServiceTableTypeEnum.RequirementsTable}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showLayerSelectionDropdown
      newColumnHeaderPlaceholder={AiConstants.INFORMATION_EXTRACTION_PLACEHOLDER}
      scoutingServiceItemTitle="Requirements table"
      insertButtonTitle="Insert table"
      updateButtonTitle="Update table"
      optionsTitle="Table options"
      handleInsertScoutingServiceItem={handleInsertScoutingServiceItemAsync}
      handleUpdateScoutingServiceItem={handleUpdateScoutingServiceItemAsync}
      onDeleteScoutingServiceItemClickAsync={
        onDeleteRequirementsTableClickAsync
          ? onDeleteScoutingServiceItemClickAsyncHandler
          : undefined
      }
      isEditing={isEditing}
      initialTableProps={initialTableProps}
    />
  );
};
