// node_modules
import TableOfContents, {
  TableOfContentsStorage,
} from "@tiptap-pro/extension-table-of-contents";
import { Content, Editor, useEditor } from "@tiptap/react";
import { history } from "prosemirror-history";
import {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
// Types
// Helpers
import {
  ExtensionsKit,
  FOCUS_COMMAND,
  GetCustomLink,
  LocalStorageHelperSingleton,
  LogHelperSingleton,
  PlaceholderExtension,
  SET_CONTENT_COMMAND,
  ToastHelperSingleton,
  UserHelperSingleton,
} from "Helpers";
// Providers
import { AuthContext, ClaimEditOnObjectContext } from "Providers";
// Enums
import { AskIgorMenuItemEnum, LogFeatureNameEnum, ToastTypeEnum } from "Enums";
// Controllers
import { LockControllerSingleton } from "Controllers";
// Custom hooks
import { useEditorEmpty, useLoadURL, useTableOfContents } from "Hooks";
// Interfaces
import {
  IAskIgorModalOptions,
  ICollapsibleListItem,
  IObject,
} from "Interfaces";

type TEditorProviderProps = {
  children?: ReactNode;
};

type TEditorContext = {
  editor: Editor | null;
  tryClaimingEditAsync: () => Promise<void>;
  stopClaimingEditAsync: () => Promise<void>;
  isEditOn: boolean | undefined;
  objectEdited: IObject | null;
  setObjectEdited: Dispatch<SetStateAction<IObject | null>>;
  setContent: Dispatch<SetStateAction<string>>;
  isObjectLocked: boolean | undefined;
  setIsObjectLocked: Dispatch<SetStateAction<boolean | undefined>>;
  toggleIsObjectLockedAsync: (isLocked: boolean) => Promise<void>;
  setSavedDocumentsCount: (savedDocumentsCount: number) => void;
  isEditorEmpty: boolean;
  staticTOCItems: ICollapsibleListItem[];
  tableOfContents: TableOfContentsStorage | null;
  setTableOfContents: Dispatch<SetStateAction<TableOfContentsStorage | null>>;
  askIgorModalOptions: IAskIgorModalOptions;
  setAskIgorModalOptions: Dispatch<SetStateAction<IAskIgorModalOptions>>;
  isAddImageModalOpen: boolean;
  setIsAddImageModalOpen: Dispatch<SetStateAction<boolean>>;
  isLinkingModalOpen: boolean;
  setIsLinkingModalOpen: Dispatch<SetStateAction<boolean>>;
  isObjectToDocumentLinkingModalOpen: boolean;
  setIsObjectToDocumentLinkingModalOpen: Dispatch<SetStateAction<boolean>>;
  linkToName: string | undefined;
  setLinkToName: Dispatch<SetStateAction<string | undefined>>;
  fileInputRef: MutableRefObject<HTMLInputElement | null>;
  openFileUploadModal: () => void;
  isOverviewTableModalOpen: boolean;
  setIsOverviewTableModalOpen: Dispatch<SetStateAction<boolean>>;
  updateTOCItems: () => void;
  setTocItemActive: (id: string) => void;
  scrollingParentElement: HTMLDivElement | null;
  setScrollingParentElement: Dispatch<SetStateAction<HTMLDivElement | null>>;
  isRequirementsTableModalOpen: boolean;
  setIsRequirementsTableModalOpen: Dispatch<SetStateAction<boolean>>;
  isMaturityRadarModalOpen: boolean;
  setIsMaturityRadarModalOpen: Dispatch<SetStateAction<boolean>>;
  savedDocumentsCount: number;
  setStaticTOCItems: Dispatch<SetStateAction<ICollapsibleListItem[]>>;
  scrollToItem: (element: Element, id: string) => void;
};

export const defaultEditorContext: TEditorContext = {
  editor: null,
  tryClaimingEditAsync: async () => {
    return;
  },
  stopClaimingEditAsync: async () => {
    return;
  },
  isEditOn: undefined,
  objectEdited: null,
  setObjectEdited: () => {
    return;
  },
  setContent: () => {
    return;
  },
  isObjectLocked: undefined,
  setIsObjectLocked: () => {
    return;
  },
  toggleIsObjectLockedAsync: async () => {
    return;
  },
  setSavedDocumentsCount: () => {
    return;
  },
  savedDocumentsCount: 0,
  isEditorEmpty: true,
  tableOfContents: null,
  staticTOCItems: [],
  setStaticTOCItems: () => {
    return;
  },
  setTableOfContents: () => {
    return;
  },
  askIgorModalOptions: {
    selectedMenuItem: AskIgorMenuItemEnum.QuestionAndAnswer,
    isOpen: false,
    isMinimized: false,
    documentsSelected: [],
  },
  setAskIgorModalOptions: () => {
    return;
  },
  isAddImageModalOpen: false,
  setIsAddImageModalOpen: () => {
    return;
  },
  isLinkingModalOpen: false,
  setIsLinkingModalOpen: () => {
    return;
  },
  isObjectToDocumentLinkingModalOpen: false,
  setIsObjectToDocumentLinkingModalOpen: () => {
    return;
  },
  linkToName: undefined,
  setLinkToName: () => {
    return;
  },
  fileInputRef: { current: null },
  openFileUploadModal: () => {
    return;
  },
  isOverviewTableModalOpen: false,
  setIsOverviewTableModalOpen: () => {
    return;
  },
  updateTOCItems: () => {
    return;
  },
  setTocItemActive: () => {
    return;
  },
  setScrollingParentElement: () => {
    return;
  },
  scrollingParentElement: null,
  scrollToItem: () => {
    return;
  },
  isRequirementsTableModalOpen: false,
  setIsRequirementsTableModalOpen: () => {
    return;
  },
  isMaturityRadarModalOpen: false,
  setIsMaturityRadarModalOpen: () => {
    return;
  },
};

export const EditorContext = createContext(defaultEditorContext);

export const EditorProvider = ({ children }: TEditorProviderProps) => {
  const { auth } = useContext(AuthContext);
  const { tryClaimingEditOnObjectAsync, stopClaimingEditOnObjectAsync } =
    useContext(ClaimEditOnObjectContext);

  const [isEditOn, setIsEditOn] = useState<boolean | undefined>(
    defaultEditorContext.isEditOn
  );
  const [objectEdited, setObjectEdited] = useState<IObject | null>(
    defaultEditorContext.objectEdited
  );
  const [content, setContent] = useState<string>("");
  const [isObjectLocked, setIsObjectLocked] = useState<boolean | undefined>(
    defaultEditorContext.isObjectLocked
  );
  const [askIgorModalOptions, setAskIgorModalOptions] =
    useState<IAskIgorModalOptions>(defaultEditorContext.askIgorModalOptions);
  const [isAddImageModalOpen, setIsAddImageModalOpen] =
    useState<boolean>(false);
  const [isLinkingModalOpen, setIsLinkingModalOpen] = useState<boolean>(false);
  const [isObjectToDocumentLinkingModalOpen, setIsObjectToDocumentLinkingModalOpen] = useState<boolean>(false);
  const [linkToName, setLinkToName] = useState<string | undefined>(undefined);
  const [savedDocumentsCount, setSavedDocumentsCount] = useState<number>(0);
  const [isOverviewTableModalOpen, setIsOverviewTableModalOpen] =
    useState<boolean>(false);
  const [isRequirementsTableModalOpen, setIsRequirementsTableModalOpen] =
    useState<boolean>(false);
  const [isMaturityRadarModalOpen, setIsMaturityRadarModalOpen] =
    useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const navigation = useNavigate();

  const editor = useEditor({
    extensions: [
      ...ExtensionsKit,
      TableOfContents,
      PlaceholderExtension,
      GetCustomLink(navigation, true),
    ],
    content: "",
    autofocus: true,
    injectCSS: false,
  });

  const { isEditorEmpty } = useEditorEmpty({ editor });
  const { loadImagesAsync, loadFilesAsync, loadRatingsAsync } = useLoadURL();

  const {
    updateTOCItems,
    setTocItemActive,
    tableOfContents,
    setTableOfContents,
    staticTOCItems,
    setStaticTOCItems,
    setScrollingParentElement,
    scrollingParentElement,
    scrollToItem,
  } = useTableOfContents();

  useEffect(() => {
    if (!editor || !content || !objectEdited) return;

    editor.storage.objectEdited = objectEdited;

    let newContent: Content = "";
    try {
      newContent = content ? JSON.parse(content) : "";
    } catch (error) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not get object content."
      );
      newContent = content ?? "";
    }

    SET_CONTENT_COMMAND.action(editor, { content: newContent });

    (async () => {
      await loadImagesAsync(editor);
      await loadFilesAsync(editor);
      await loadRatingsAsync(editor, objectEdited.id, objectEdited.objectType);

      editor.unregisterPlugin("history");
      editor.registerPlugin(history());

      FOCUS_COMMAND.action(editor, {
        focusPosition: editor.getText().length > 0 ? "end" : undefined,
        focusOptions: { scrollIntoView: false },
      });
    })();
  }, [
    content,
    editor,
    loadFilesAsync,
    loadImagesAsync,
    loadRatingsAsync,
    objectEdited,
  ]);

  useEffect(() => {
    if (isEditOn && editor) {
      FOCUS_COMMAND.action(editor, {
        focusPosition: editor.getText().length > 0 ? "end" : undefined,
        focusOptions: { scrollIntoView: false },
      });
    }
  }, [editor, isEditOn]);

  const stopClaimingEditAsync = useCallback(async (): Promise<void> => {
    if (!objectEdited || !editor) {
      return;
    }

    LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-TurnEditModeOff`);

    editor.setEditable(false);
    setIsEditOn(false);

    await stopClaimingEditOnObjectAsync(objectEdited.id);
  }, [editor, objectEdited, stopClaimingEditOnObjectAsync]);

  const tryClaimingEditAsync = useCallback(async (): Promise<void> => {
    if (
      !UserHelperSingleton.isUserAtLeastContributor(auth) ||
      !objectEdited ||
      !editor
    ) {
      return;
    }

    if (isObjectLocked) {
      editor.setEditable(false);
      setIsEditOn(false);
      return;
    }

    LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-TurnEditModeOn`);

    const result: boolean = await tryClaimingEditOnObjectAsync(objectEdited.id);

    if (result) {
      editor.setEditable(true);
      setIsEditOn(true);
    } else {
      editor.setEditable(false);
      setIsEditOn(false);

      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "A coworker is already editing this element."
      );
    }
  }, [
    auth,
    editor,
    isObjectLocked,
    objectEdited,
    tryClaimingEditOnObjectAsync,
  ]);

  const toggleIsObjectLockedAsync = useCallback(
    async (isLocked: boolean): Promise<void> => {
      if (
        !UserHelperSingleton.isUserAtLeastContributor(auth) ||
        !objectEdited
      ) {
        return;
      }

      if (isLocked) {
        await stopClaimingEditAsync();
      }

      const isSuccess: boolean = await LockControllerSingleton.updateObjectLock(
        objectEdited.id,
        objectEdited.objectType,
        isLocked
      );

      if (!isSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not change page lock."
        );
        return;
      }

      setIsObjectLocked(isLocked);
    },
    [auth, objectEdited, stopClaimingEditAsync]
  );

  useEffect(() => {
    if (!editor) return;

    const storedIsEditOn: boolean | undefined =
      LocalStorageHelperSingleton.getItem<boolean>("isEditModeOn");

    if (storedIsEditOn) {
      tryClaimingEditAsync();
    }

    editor.setEditable(storedIsEditOn ?? false, false);
  }, [editor, tryClaimingEditAsync]);

  useEffect(() => {
    if (isObjectLocked && editor) {
      editor.setEditable(false);
    }
  }, [editor, isObjectLocked]);

  useEffect(() => {
    if (isEditOn === undefined) {
      return;
    }

    LocalStorageHelperSingleton.setItem(isEditOn, "isEditModeOn");
  }, [isEditOn]);

  const openFileUploadModal = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const editorProviderValue = useMemo((): TEditorContext => {
    return {
      editor,
      tryClaimingEditAsync,
      stopClaimingEditAsync,
      isEditOn,
      objectEdited,
      setObjectEdited,
      setContent,
      isObjectLocked,
      setIsObjectLocked,
      toggleIsObjectLockedAsync,
      isEditorEmpty,
      savedDocumentsCount,
      setSavedDocumentsCount,
      tableOfContents,
      setTableOfContents,
      staticTOCItems,
      setStaticTOCItems,
      askIgorModalOptions,
      setAskIgorModalOptions,
      isAddImageModalOpen,
      setIsAddImageModalOpen,
      isLinkingModalOpen,
      setIsLinkingModalOpen,
      isObjectToDocumentLinkingModalOpen,
      setIsObjectToDocumentLinkingModalOpen,
      linkToName,
      setLinkToName,
      fileInputRef,
      openFileUploadModal,
      isOverviewTableModalOpen,
      setIsOverviewTableModalOpen,
      updateTOCItems,
      setTocItemActive,
      setScrollingParentElement,
      scrollingParentElement,
      scrollToItem,
      isRequirementsTableModalOpen,
      setIsRequirementsTableModalOpen,
      isMaturityRadarModalOpen,
      setIsMaturityRadarModalOpen,
    };
  }, [
    askIgorModalOptions,
    editor,
    isAddImageModalOpen,
    isEditOn,
    isEditorEmpty,
    isLinkingModalOpen,
    isObjectToDocumentLinkingModalOpen,
    isMaturityRadarModalOpen,
    isObjectLocked,
    isOverviewTableModalOpen,
    isRequirementsTableModalOpen,
    linkToName,
    objectEdited,
    scrollingParentElement,
    setTocItemActive,
    staticTOCItems,
    stopClaimingEditAsync,
    tableOfContents,
    toggleIsObjectLockedAsync,
    tryClaimingEditAsync,
    updateTOCItems,
    setStaticTOCItems,
    savedDocumentsCount,
    setScrollingParentElement,
    setTableOfContents,
    scrollToItem,
  ]);

  return (
    <EditorContext.Provider value={editorProviderValue}>
      {children}
    </EditorContext.Provider>
  );
};
