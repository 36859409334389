import { faRainbow } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FindestButton, TextArea, ToggleSwitch } from "Components";
import { useNaturalLanguageQuery } from "Hooks";
import { IQueryDTO, IQuerySearchingState } from "Interfaces";
import { FC, useEffect, useState } from "react";
import { TQueryFiltersDTO } from "Types";
import styles from "./technologyOverviewWizard.module.scss";

type TTechnologyOverviewWizardProps = {
  query: IQueryDTO;
  updateQueryFilters: (queryFilters: TQueryFiltersDTO) => void;
  updateNaturalLanguageQuery: (naturalLanguageQuery: string) => void;
  isExpanded?: boolean;
  isEditing?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
  querySearchingState: IQuerySearchingState;
  setQuerySearchingState: (
    newQuerySearchingState: IQuerySearchingState
  ) => void;
  onSearchClick: () => void;
  cancelSearch: () => void;
};

export const TechnologyOverviewWizard: FC<TTechnologyOverviewWizardProps> = ({
  query,
  updateQueryFilters,
  updateNaturalLanguageQuery,
  isExpanded = false,
  isEditing,
  setIsEditing,
  querySearchingState,
  setQuerySearchingState,
  onSearchClick,
  cancelSearch,
}: TTechnologyOverviewWizardProps) => {
  const [areOptionsVisible, setAreOptionsVisible] =
    useState<boolean>(isExpanded);
  const [techSearchQueryTextAreaRef, setTechSearchQueryTextAreaRef] =
    useState<HTMLTextAreaElement | null>(null);

  const { naturalLanguageQuery, onNaturalLanguageQueryUpdate } =
    useNaturalLanguageQuery(query, updateNaturalLanguageQuery);

  useEffect(() => {
    if (areOptionsVisible && techSearchQueryTextAreaRef) {
      techSearchQueryTextAreaRef.focus();
      techSearchQueryTextAreaRef.selectionStart =
        techSearchQueryTextAreaRef.value.length;
    }
  }, [areOptionsVisible, techSearchQueryTextAreaRef]);

  const onWizardClick = () => {
    if (!areOptionsVisible) {
      setAreOptionsVisible(true);
    }
  };

  const onGenerateButtonClick = () => {
    setQuerySearchingState({
      isRunningDocumentsSearch: false,
      isRunningTechSearch: false,
      isRunningTechSearchOnResults: true,
    });

    onSearchClick();
  };

  const onGroupResultsToggleChange = (checked: boolean) => {
    updateQueryFilters({
      ...query.filters,
      technologyFilters: {
        ...query.filters.technologyFilters,
        doGroupResults: checked,
      },
    });
  };

  const onCancelClick = () => {
    cancelSearch();
  };

  const onCancelEdit = () => {
    setIsEditing?.(false);
  };

  return (
    <div
      className={`${styles.technologyOverviewWizard} ${
        areOptionsVisible ? "" : styles.compactView
      }`}
    >
      <div className={styles.header}>
        <FontAwesomeIcon icon={faRainbow} />
        <h4 className={styles.technologyOverviewHeader}>Technology overview</h4>
      </div>
      {querySearchingState.isRunningTechSearchOnResults && (
        <div className={styles.searchingState}>
          <p className={styles.description}>
            Categorizing the first 100 papers based on your prompt:
          </p>
          <p className={styles.naturalLanguageQuery}>{naturalLanguageQuery}</p>
          <p className={styles.description}>
            The results will appear in the &apos;Technology overview&apos; tab
            in the top left.
          </p>
        </div>
      )}
      {areOptionsVisible &&
        !querySearchingState.isRunningDocumentsSearch &&
        !querySearchingState.isRunningTechSearch &&
        !querySearchingState.isRunningTechSearchOnResults && (
          <>
            <p className={styles.description}>
              Categorize the results by writing what you are looking for in the
              first 100 papers. Example: “I am looking for a process to extract
              salt from seawater”
            </p>
            <TextArea
              setTextAreaRef={setTechSearchQueryTextAreaRef}
              placeholder="I am looking for a process to extract salt from seawater"
              value={naturalLanguageQuery}
              onChange={onNaturalLanguageQueryUpdate}
              extraClassName={styles.textArea}
            />
            <div className={styles.criteriaContainer}>
              <ToggleSwitch
                checked={
                  query.filters.technologyFilters?.doGroupResults !== null
                    ? query.filters.technologyFilters?.doGroupResults
                    : true
                }
                onChange={onGroupResultsToggleChange}
                label="Group results"
                extraClassnames={{ container: styles.toggleSwitch }}
              />
            </div>
          </>
        )}
      <div className={styles.footer}>
        <FindestButton
          buttonType={areOptionsVisible ? "primary" : "secondary"}
          onClick={!areOptionsVisible ? onWizardClick : onGenerateButtonClick}
          title={
            querySearchingState.isRunningTechSearchOnResults ? "" : "Generate"
          }
          extraClassName={styles.searchButton}
          isDisabled={areOptionsVisible && !naturalLanguageQuery}
          isLoading={querySearchingState.isRunningTechSearchOnResults}
        />
        {(querySearchingState.isRunningTechSearchOnResults || isEditing) && (
          <FindestButton
            title="Cancel"
            onClick={
              querySearchingState.isRunningTechSearchOnResults
                ? onCancelClick
                : onCancelEdit
            }
            buttonType="secondary"
          />
        )}
      </div>
    </div>
  );
};
