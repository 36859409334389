export const OnboardingConstants = {
  CREATE_NEW_BUTTON: "createNewButton",
  CREATE_NEW_MODAL_PAGES_CONTAINER: "createNewModalPagesContainer",
  CREATE_NEW_MODAL_DOCUMENT_CONTAINER: "createNewModalDocumentContainer",
  CREATE_NEW_MODAL_ENTITY_CONTAINER: "createNewModalEntityContainer",
  CREATE_NEW_MODAL_STUDY_CONTAINER: "createNewModalStudyContainer",
  CREATE_QUERY_CONTAINER: "createQueryContainer",
  ADD_DOCUMENT_FLOW: "addDocument",
  CREATE_PAGE_FLOW: "createPage",
  CREATE_ENTITY_FLOW: "createEntity",
  CREATE_STUDY_FLOW: "createStudy",
  CREATE_QUERY_FLOW: "createQuery",
} as const;
