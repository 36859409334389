// Enums
import { QuerySortOptionsEnum } from "Enums";

export class QuerySortOptionsHelper {
  public getQuerySortOptionSearchValue(
    querySortOption: QuerySortOptionsEnum
  ): string {
    switch (querySortOption) {
      case QuerySortOptionsEnum.PublicationDate:
        return "pubdatedesc";
      case QuerySortOptionsEnum.CitationScore:
        return "impactfactor";
      case QuerySortOptionsEnum.Relevance:
        return "reldesc";
      default:
        return "reldesc";
    }
  }

  public getQuerySortOptionsEnumFromSearchValue(
    searchValue?: string
  ): QuerySortOptionsEnum {
    switch (searchValue) {
      case "pubdatedesc":
        return QuerySortOptionsEnum.PublicationDate;
      case "impactfactor":
        return QuerySortOptionsEnum.CitationScore;
      case "reldesc":
        return QuerySortOptionsEnum.Relevance;
      default:
        return QuerySortOptionsEnum.Relevance;
    }
  }

  public getQuerySortOptionDisplayValue(
    querySortOption: QuerySortOptionsEnum
  ): string {
    switch (querySortOption) {
      case QuerySortOptionsEnum.PublicationDate:
        return "Publication Date";
      case QuerySortOptionsEnum.CitationScore:
        return "Citation Score";
      case QuerySortOptionsEnum.Relevance:
        return "Relevance";
      default:
        return "Relevance";
    }
  }
}

export const QuerySortOptionsHelperSingleton = new QuerySortOptionsHelper();
