import { FindestTextBox } from "Components";
import { QueryControllerSingleton } from "Controllers";
import { SearchQueryTypeEnum, ToastTypeEnum } from "Enums";
import {
  DateDisplayFormat,
  DateHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
import { IQueryDTO } from "Interfaces";
import { FC, useCallback } from "react";
import { TQueryFiltersDTO } from "Types";
import styles from "./queryFilters.module.scss";
import { QueryConstants } from "Constants";

interface IPublicationDateProps {
  query: IQueryDTO;
  searchQueryType: SearchQueryTypeEnum;
  updateQueryFilters: (newFilters: TQueryFiltersDTO) => Promise<void>;
}

const useFilters = (searchQueryType: SearchQueryTypeEnum, query: IQueryDTO) => {
  return [
    SearchQueryTypeEnum.USEOnScience,
    SearchQueryTypeEnum.UniverseScienceArticles,
  ].includes(searchQueryType)
    ? query.filters.scienceFilters
    : query.filters.patentFilters;
};

const parseDate = (dateString: string) => new Date(dateString);

const isInvalidDateRange = (startDate: string, endDate: string): boolean => {
  if (!startDate || !endDate) return false;
  const start = parseDate(startDate);
  const end = parseDate(endDate);
  return start > end;
};

const updateQueryFiltersAsync = async (
  queryId: string,
  queryFilters: TQueryFiltersDTO
) => {
  if (
    !(await QueryControllerSingleton.updateFiltersAsync(
      queryId,
      queryFilters
    ))
  ) {
    ToastHelperSingleton.showToast(
      ToastTypeEnum.Error,
      "Could not update filters."
    );
    return;
  }
};

const handleDateChange = async (
  query: IQueryDTO,
  filtersKey: "scienceFilters" | "patentFilters",
  dateType: "startDate" | "endDate",
  newDate: string,
  updateQueryFilters: (filters: TQueryFiltersDTO) => Promise<void>
) => {
  query.filters[filtersKey][dateType] = newDate;

  updateQueryFilters(query.filters);

  const newQueryFilters: TQueryFiltersDTO = JSON.parse(JSON.stringify(query.filters));
  newQueryFilters[filtersKey][dateType] = sanitizeNewDate(
    newDate,
    newQueryFilters,
    filtersKey,
    dateType,
    "YYYY-MM-DD",
    false
  );

  await updateQueryFiltersAsync(query.id, newQueryFilters);
};

const sanitizeNewDate = (newDate: string | null | undefined,
  queryFilters: TQueryFiltersDTO,
  filtersKey: "scienceFilters" | "patentFilters",
  dateType: "startDate" | "endDate",
  dateDisplayFormat: DateDisplayFormat,
  showErrorMessage: boolean): string => {
    const lowerBoundDate = QueryConstants.DEFAULT_PUBLICATION_START_DATE;
    const upperBoundDate = DateHelperSingleton.getCurrentDateInFormat(
      "YYYY-MM-DD"
    );

    newDate = DateHelperSingleton.getDateInFormatFromString(
      newDate || "",
      dateDisplayFormat
    );

    const isStartDate = dateType === "startDate";
    const invalidDateMessage = isStartDate
      ? "Invalid start date format."
      : "Invalid end date format.";
    const rangeErrorMessage = isStartDate
      ? "Start date cannot be after end date."
      : "End date cannot be before start date.";
    const lowerBoundErrorMessage = isStartDate
      ? `Start date cannot be before ${lowerBoundDate}.`
      : `End date cannot be before ${lowerBoundDate}.`;
    const upperBoundErrorMessage = isStartDate
      ? `Start date cannot be after ${upperBoundDate}.`
      : `End date cannot be after ${upperBoundDate}.`;


    if (!newDate && queryFilters[filtersKey][dateType]) {
      if (showErrorMessage) ToastHelperSingleton.showToast(ToastTypeEnum.Error, invalidDateMessage);
    }

    if (
      isInvalidDateRange(
        isStartDate ? newDate : lowerBoundDate,
        isStartDate ? upperBoundDate : newDate
      )
    ) {
      if (showErrorMessage) ToastHelperSingleton.showToast(ToastTypeEnum.Error, isStartDate ? upperBoundErrorMessage : lowerBoundErrorMessage);
      newDate = "";
    }

    if (
      isInvalidDateRange(
        isStartDate ? lowerBoundDate : newDate,
        isStartDate ? newDate : upperBoundDate
      )
    ) {
      if (showErrorMessage) ToastHelperSingleton.showToast(ToastTypeEnum.Error, isStartDate ? lowerBoundErrorMessage : upperBoundErrorMessage);
      newDate = "";
    }

    if (
      isInvalidDateRange(
        isStartDate ? newDate : queryFilters[filtersKey].startDate || "",
        isStartDate ? queryFilters[filtersKey].endDate || "" : newDate
      )
    ) {
      if (showErrorMessage) ToastHelperSingleton.showToast(ToastTypeEnum.Error, rangeErrorMessage);
      newDate = "";
    }

    return newDate;
};

const handleBlur = async (
  query: IQueryDTO,
  filtersKey: "scienceFilters" | "patentFilters",
  dateType: "startDate" | "endDate",
  dateDisplayFormat: DateDisplayFormat,
  updateQueryFilters: (filters: TQueryFiltersDTO) => Promise<void>
) => {
  query.filters[filtersKey][dateType] = sanitizeNewDate(
    query.filters[filtersKey][dateType],
    query.filters,
    filtersKey,
    dateType,
    dateDisplayFormat,
    true
  );

  await updateQueryFiltersAsync(query.id, query.filters);

  updateQueryFilters(query.filters);
};

export const PublicationDate: FC<IPublicationDateProps> = ({
  query,
  searchQueryType,
  updateQueryFilters,
}) => {
  const dateDisplayFormat = "YYYY-MM-DD";
  const filtersKey = [
    SearchQueryTypeEnum.USEOnScience,
    SearchQueryTypeEnum.UniverseScienceArticles,
  ].includes(searchQueryType)
    ? "scienceFilters"
    : "patentFilters";

  const filters = useFilters(searchQueryType, query);

  const onDateChange = useCallback(
    async (dateType: "startDate" | "endDate", newDate: string) => {
      await handleDateChange(
        query,
        filtersKey,
        dateType,
        newDate,
        updateQueryFilters
      );
    },
    [query, filtersKey, updateQueryFilters]
  );

  const dateOnBlurHandler = useCallback(
    async (dateType: "startDate" | "endDate"): Promise<void> => {
      await handleBlur(
        query,
        filtersKey,
        dateType,
        dateDisplayFormat,
        updateQueryFilters
      );
    },
    [query, filtersKey, dateDisplayFormat, updateQueryFilters]
  );

  return (
    <div className={styles.dataListItem}>
      <div className={styles.rangeContainer}>
        <FindestTextBox
          placeholder="YYYY-MM-DD"
          onChange={(e) => onDateChange("startDate", e)}
          value={filters?.startDate || undefined}
          onBlur={() => dateOnBlurHandler("startDate")}
        />
        <span>-</span>
        <FindestTextBox
          placeholder="YYYY-MM-DD"
          onChange={(e) => onDateChange("endDate", e)}
          value={filters?.endDate || undefined}
          onBlur={() => dateOnBlurHandler("endDate")}
        />
      </div>
    </div>
  );
};
