import { useCallback, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { IQueryDTO } from "Interfaces";

export const useNaturalLanguageQuery = (query: IQueryDTO, updateNaturalLanguageQuery: (naturalLanguageQuery: string) => void) => {
  const [naturalLanguageQuery, setNaturalLanguageQuery] = useState<string>(query?.naturalLanguageQuery ?? "");

  const onTextAreaChange = useCallback((newNaturalLanguageQuery: string) => {
    query.naturalLanguageQuery = newNaturalLanguageQuery;
    updateNaturalLanguageQuery(newNaturalLanguageQuery);
  }, [query, updateNaturalLanguageQuery]);

  const debouncedOnTextAreaChange = useMemo(
    () => debounce(onTextAreaChange, 1000),
    [onTextAreaChange]
  );

  const onNaturalLanguageQueryUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNaturalLanguageQuery = e.target.value;
    setNaturalLanguageQuery(newNaturalLanguageQuery);
    debouncedOnTextAreaChange(newNaturalLanguageQuery);
  };

  return {
    naturalLanguageQuery,
    onNaturalLanguageQueryUpdate,
  };
};
