// node_modules
import { FC, useContext, useEffect, useState } from "react";
// Components
import {
  SavedInboxDocuments,
  EmptyDocuments,
  LoadingStatusIndicator,
} from "Components";
// Styles
import styles from "./inbox.module.scss";
// Types
import { TInboxListDTO, TOption } from "Types";
// Controllers
import {
  SavedDocumentControllerSingleton,
  SavedPatentControllerSingleton,
  SavedScienceArticleControllerSingleton,
  SavedWeblinkControllerSingleton,
} from "Controllers";
// Enums
import {
  LinkStatusEnum,
  OrderByEnum,
  SavedDocumentTypeEnum,
  SortTypeEnum,
} from "Enums";
// Helpers
import { LogHelperSingleton, SavedFiltersHelperSingleton } from "Helpers";
// Contexts
import { DocumentContext } from "Providers";
// Interfaces
import { IInboxListItem } from "Interfaces";

export const Inbox: FC = () => {
  // Context
  const { refreshDocumentsNotLinkedCount } = useContext(DocumentContext);
  // State
  const [savedDocuments, setSavedDocuments] = useState<IInboxListItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalSavedDocumentsCount, setTotalSavedDocumentsCount] =
    useState<number>(0);
  const [isThereAnyFiltersSelected, setIsThereAnyFiltersSelected] =
    useState<boolean>(false);

  // Logic
  useEffect(() => {
    // get saved filters in local storage
    const savedFilters: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[] =
      SavedFiltersHelperSingleton.getDocumentsFilters();

    refreshDocumentsAsync(savedFilters, SortTypeEnum.Newest, 1);

    // log
    LogHelperSingleton.log("DisplayInbox");
  }, []);

  useEffect(() => {
    (async () => {
      await refreshDocumentsNotLinkedCount();
    })();
  }, [refreshDocumentsNotLinkedCount]);

  const refreshDocumentsAsync = async (
    filterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[],
    sortType: SortTypeEnum,
    page: number
  ): Promise<void> => {
    const newDocuments: TInboxListDTO =
      await SavedDocumentControllerSingleton.getMyInboxAsync(
        sortType === SortTypeEnum.Oldest
          ? OrderByEnum.Ascending
          : OrderByEnum.Descending,
        filterOptions,
        page
      );

    if (newDocuments?.items) {
      setTotalSavedDocumentsCount(newDocuments.totalItemCount);
      setSavedDocuments(newDocuments.items);
    } else {
      setSavedDocuments([]);
      setTotalSavedDocumentsCount(0);
    }
    setIsLoading(false);
    setIsThereAnyFiltersSelected(filterOptions.length > 0);
  };

  const deleteSavedDocumentAsync = async (
    savedDocumentsToDelete: IInboxListItem[]
  ): Promise<void> => {
    for (const savedDocumentToDelete of savedDocumentsToDelete) {
      switch (savedDocumentToDelete.savedDocumentType) {
        case SavedDocumentTypeEnum.ScienceArticle:
          await SavedScienceArticleControllerSingleton.deleteMyAsync(
            savedDocumentToDelete.id
          );
          break;
        case SavedDocumentTypeEnum.MagPatent:
        case SavedDocumentTypeEnum.UsPatent:
          await SavedPatentControllerSingleton.deleteMyAsync(
            savedDocumentToDelete.id
          );
          break;
        case SavedDocumentTypeEnum.Weblink:
          await SavedWeblinkControllerSingleton.deleteMyAsync(
            savedDocumentToDelete.id
          );
          break;
        default:
          break;
      }
    }

    // log
    LogHelperSingleton.log("RemoveDocument(s)FromInbox");
  };

  if (!isThereAnyFiltersSelected) {
    if (isLoading) return <LoadingStatusIndicator shouldCenter status={1} />;

    if (savedDocuments.length === 0) {
      return <EmptyDocuments route="Inbox" />;
    }
  }

  return (
    <div className={styles.inboxContainer}>
      <SavedInboxDocuments
        documents={savedDocuments}
        doUseSavedFilters={true}
        totalDocumentsCount={totalSavedDocumentsCount}
        refreshDocumentsAsync={refreshDocumentsAsync}
        deleteSavedDocumentAsync={deleteSavedDocumentAsync}
        isInboxSection={true}
      />
    </div>
  );
};
