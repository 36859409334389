// React
import { FC } from "react";
// Providers
import { QueryProvider } from "Providers";
// Interfaces
import { IQueryDTO } from "Interfaces";
// Components
import { QueryDetailsLoader } from "./QueryDetailsLoader";
import { SearchQueryTypeEnum } from "Enums";

type TQueryDetailsProvidedLoaderProps = {
  queryId: string;
  onDelete: () => void;
  onDuplicateAsync: (duplicateQuery: IQueryDTO) => Promise<void>;
  onSearchStarted: (
    correlationId: string,
    type: SearchQueryTypeEnum,
    hasTechnologySearchResults?: boolean | null,
    abortController?: AbortController
  ) => void;
  onSearchCompleted: () => void;
};

export const QueryDetailsProvidedLoader: FC<TQueryDetailsProvidedLoaderProps> =
  ({
    queryId,
    onDelete,
    onDuplicateAsync,
    onSearchStarted,
    onSearchCompleted,
  }) => {
    return (
      <QueryProvider>
        <QueryDetailsLoader
          queryId={queryId}
          onDelete={onDelete}
          onDuplicateAsync={onDuplicateAsync}
          onSearchStarted={onSearchStarted}
          onSearchCompleted={onSearchCompleted}
        />
      </QueryProvider>
    );
  };
