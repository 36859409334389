// node_modules
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Connection,
  Edge,
  Node,
  XYPosition,
  addEdge,
  useReactFlow,
} from "reactflow";
// Helpers
import {
  LinkingHelperSingleton,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
  ReactFlowHelper,
  ReactFlowHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Controllers
import { LinkingControllerSingleton } from "Controllers";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Types
import {
  TIdNameTypeObjectType,
  TLinkGraphDTO,
  TLinkGraphNodeDTO,
  TOption,
  TReactFlowNode,
  TUseDragAndDrop,
  fromTRecentSidebarActivityItemDTO,
} from "Types";
// Components
import { LinkingModal } from "../Modals";
import { LinkGraphView } from "./LinkGraphView";
// Constants
import { LinkingConstants } from "Constants";
// Contexts
import {
  AuthContext,
  ElementVisibilityContext,
  LinkGraphContext,
  RecentActivityContext,
} from "Providers";
// Components
import { ObjectItem } from "Components";
// Styles
import styles from "./linkGraphView.module.scss";
// Custom hooks
import {
  useAnyLinkRemovedListener,
  useObjectDeletedListener,
  useObjectLinkedListener,
  useObjectNameChangeListener,
} from "Hooks";

type TLinkGraphProps = {
  isFullscreen: boolean;
  useDragAndDropProps: TUseDragAndDrop;
  setIsLinksWindowSearchBarResultsElementActive?: (
    isLinksWindowSearchBarResultsElementActive: boolean
  ) => void;
};

export const LinkGraph: FC<TLinkGraphProps> = ({
  isFullscreen,
  useDragAndDropProps,
  setIsLinksWindowSearchBarResultsElementActive,
}: TLinkGraphProps) => {
  // Context
  const {
    focusedNodeId,
    focusedNodeType,
    onReanchorClick,
    linkGraphForFocusedNode,
  } = useContext(LinkGraphContext);
  const { mySimpleRecentActivity } = useContext(RecentActivityContext);
  const { canUserEdit } = useContext(ElementVisibilityContext);

  // State
  const [nodes, setNodes] = useState<Node<TReactFlowNode>[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);
  const [selectedObjects, setSelectedObjects] = useState<
    TIdNameTypeObjectType[]
  >([]);
  const [defaultLinkType, setDefaultLinkType] = useState<TOption<string>>(
    LinkingConstants.PARENT_LINK_TYPE
  );

  // Ref
  // Using a ref to store the nodes & edges as well so we can access them in the onLoadMoreClickAsync function
  // without changing the ref of onLoadMoreClickAsync function
  // in order to prevent re-rendering of LinkGraphView component nodeTypes memo
  const nodesRef = useRef<Node<TReactFlowNode>[]>([]);
  const edgesRef = useRef<Edge[]>([]);

  // Contexts
  const { isUserExternal } = useContext(AuthContext);

  // Hooks
  const { fitView } = useReactFlow();

  // Logic
  // filter out edges with source or target not in nodes list
  // and remove duplicate edges
  const cleanEdges = useCallback(
    (edgesToClean: Edge[], fromNodes: Node[]): Edge[] => {
      return edgesToClean
        .filter(
          (edge) =>
            fromNodes.find((node) => node.id === edge.source) &&
            fromNodes.find((node) => node.id === edge.target)
        )
        .filter(
          (edge, index, self) =>
            index === self.findIndex((e) => e.id === edge.id)
        );
    },
    []
  );

  // remove duplicate nodes
  const cleanNodes = useCallback(
    (nodesToClean: Node<TReactFlowNode>[]): Node<TReactFlowNode>[] => {
      return nodesToClean.filter(
        (node, index, self) => index === self.findIndex((n) => n.id === node.id)
      );
    },
    []
  );

  const getFocusedReactFlowGraph = useCallback(
    async (
      doFitView: boolean,
      currentLinkGraphForFocusedNode?: TLinkGraphDTO
    ) => {
      // if currentLinkGraphForFocusedNode is undefined
      if (!currentLinkGraphForFocusedNode) {
        // return, do nothing
        return;
      }

      // get new nodes from link graph
      const newNodesFromLinkGraph: Node<TReactFlowNode>[] =
        ReactFlowHelperSingleton.FromLinkGraphToNodes(
          currentLinkGraphForFocusedNode,
          { x: 0, y: 0 },
          LinkingConstants.GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT
        );
      // clean nodes
      const cleanedNodes = cleanNodes(newNodesFromLinkGraph);

      // set nodes
      nodesRef.current = [...cleanedNodes];
      setNodes([...cleanedNodes]);

      // get new edges from link graph
      const newEdgesFromLinkGraph: Edge[] =
        ReactFlowHelperSingleton.FromLinkGraphToEdges(
          currentLinkGraphForFocusedNode,
          LinkingConstants.GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT
        );
      // clean edges
      const cleanedEdges = cleanEdges(newEdgesFromLinkGraph, cleanedNodes);
      // set edges
      edgesRef.current = [...cleanedEdges];
      setEdges([...cleanedEdges]);

      // if do fit view, then fit view
      if (doFitView) {
        setTimeout(fitView, 500);
      }
    },
    [cleanEdges, cleanNodes, fitView]
  );

  // Logic
  useEffect(() => {
    // safety-checks
    if (!linkGraphForFocusedNode) {
      return;
    }

    getFocusedReactFlowGraph(true, linkGraphForFocusedNode);
  }, [getFocusedReactFlowGraph, linkGraphForFocusedNode]);

  useEffect(() => {
    // handle focused node name and object type changes
    for (const node of nodesRef.current) {
      if (node.id === focusedNodeId) {
        // update text and object type of focused node
        node.data = {
          ...node.data,
        };
      }
    }

    // clean nodes
    const cleanedNodes = cleanNodes(nodesRef.current);
    // set nodesRef
    nodesRef.current = [...cleanedNodes];
    // set nodes
    setNodes([...cleanedNodes]);
  }, [focusedNodeId, focusedNodeType, cleanNodes]);

  // get load more async
  const onLoadMoreClickAsync = useCallback(
    async (relatedNodeId: string, nodeType: ObjectTypeEnum) => {
      // get related node
      const relatedNode: Node<TReactFlowNode> | undefined =
        nodesRef.current.find(
          (node: Node<TReactFlowNode>) => node.id === relatedNodeId
        );

      // safety-checks
      if (!relatedNode) {
        return;
      }

      // get node ids in load more count of the related node
      const nodeIdsInLoadMoreCount: string[] =
        relatedNode.data.lowerLevelNodes.map(
          (lowerLevelNode: TLinkGraphNodeDTO) => lowerLevelNode.id
        );
      nodeIdsInLoadMoreCount.push(
        ...relatedNode.data.otherUpperLevelNodes.map(
          (otherUpperLevelNode: TLinkGraphNodeDTO) => otherUpperLevelNode.id
        )
      );

      // build the node ids list we don't want to get from the server
      // because we already have them currently (either shown or hidden in load more count)
      // except the ones in load more count of related node
      let nodeIdsToExclude: string[] = [];
      for (const node of nodesRef.current) {
        if (node.id !== relatedNodeId) {
          // node id already shown
          nodeIdsToExclude.push(node.id);
          // node ids in load more count
          nodeIdsToExclude.push(
            ...node.data.otherUpperLevelNodes.map(
              (otherUpperLevelNode: TLinkGraphNodeDTO) => otherUpperLevelNode.id
            )
          );
          nodeIdsToExclude.push(
            ...node.data.lowerLevelNodes.map(
              (lowerLevelNode: TLinkGraphNodeDTO) => lowerLevelNode.id
            )
          );
          // remove node ids which are in load more count of related node or the related node itself
          nodeIdsToExclude = [
            ...nodeIdsToExclude.filter(
              (nodeId) =>
                !nodeIdsInLoadMoreCount.includes(nodeId) &&
                nodeId !== relatedNodeId
            ),
          ];
        }
      }

      // get link graph async for the focused node
      const loadMoreLinkGraph: TLinkGraphDTO | undefined =
        await LinkingControllerSingleton.postLoadMoreLinkGraph(
          relatedNodeId,
          nodeType,
          nodeIdsToExclude
        );

      // safety-checks
      if (!loadMoreLinkGraph) {
        return;
      }

      // build new nodes list
      const newNodes: Node<TReactFlowNode>[] = [
        ...ReactFlowHelperSingleton.FromLinkGraphToNodes(
          loadMoreLinkGraph,
          { x: 0, y: 0 },
          1,
          false
        ),
      ];

      // get all new nodes ids
      const newNodesIds: string[] = newNodes.map((node) => node.id);
      // update load more count on old nodes
      for (const node of nodesRef.current) {
        if (node.id === relatedNodeId) {
          node.data = {
            ...node.data,
            loadMoreCount: 0,
          };
          continue;
        }

        if (node.data.loadMoreCount > 0) {
          // get new nodes which are also in other upper level nodes or lower level nodes of the current node
          const newNodesInOtherUpperLevelNodes =
            node.data.otherUpperLevelNodes.filter(
              (otherUpperLevelNode: TLinkGraphNodeDTO) =>
                newNodesIds.includes(otherUpperLevelNode.id)
            );
          const newNodesInLowerLevelNodes = node.data.lowerLevelNodes.filter(
            (lowerLevelNode: TLinkGraphNodeDTO) =>
              newNodesIds.includes(lowerLevelNode.id)
          );
          // update load more count
          node.data = {
            ...node.data,
            loadMoreCount:
              node.data.loadMoreCount -
              newNodesInOtherUpperLevelNodes.length -
              newNodesInLowerLevelNodes.length,
          };
        }
      }

      // add old nodes to new nodes list
      newNodes.push(...nodesRef.current);

      // build new edges list
      const newEdges: Edge[] = [
        ...edgesRef.current,
        ...ReactFlowHelperSingleton.FromLinkGraphToEdges(loadMoreLinkGraph, 1),
      ];

      // clean nodes
      const cleanedNodes = cleanNodes(newNodes);
      // set nodes & edges
      setNodes(cleanedNodes);
      nodesRef.current = cleanedNodes;
      // clean edges
      const cleanedEdges = cleanEdges(newEdges, cleanedNodes);
      setEdges(cleanedEdges);
      edgesRef.current = cleanedEdges;

      LogHelperSingleton.log("StructureGraphLoadMore");
    },
    [cleanEdges, cleanNodes]
  );

  const onLinkRemoved = useCallback(
    (fromId: string, toId: string) => {
      // if user is readonly
      if (isUserExternal) {
        // stop execution, return
        return;
      }

      // set edges
      setEdges((previousEdges) => {
        // filter out edges with contain both fromId and ToId as either source or target
        const newEdges = previousEdges.filter(
          (edge) =>
            !(edge.source === fromId && edge.target === toId) &&
            !(edge.source === toId && edge.target === fromId)
        );

        // clean edges
        const cleanedEdges = cleanEdges(newEdges, nodesRef.current);

        // set edgesRef
        edgesRef.current = cleanedEdges;

        // return new edges
        return cleanedEdges;
      });
    },
    [cleanEdges, isUserExternal]
  );

  const createEdgeBetweenNodes = useCallback(
    (sourceId: string, targetId: string) => {
      setEdges((previousEdges) => {
        const newEdges = addEdge(
          {
            id: `${sourceId}-${targetId}`,
            source: sourceId,
            target: targetId,
            ...ReactFlowHelper.generalEdgeProps,
          },
          previousEdges
        );

        // clean edges
        const cleanedEdges = cleanEdges(newEdges, nodesRef.current);

        edgesRef.current = cleanedEdges;
        return cleanedEdges;
      });
    },
    [cleanEdges]
  );

  const onObjectLinkedAsync = useCallback(
    async (
      fromObject: TIdNameTypeObjectType,
      toObject: TIdNameTypeObjectType
    ) => {
      // if user is readonly
      // or from or to object is undefined
      // or from or to object is not a entity or study
      if (
        isUserExternal ||
        !fromObject ||
        !toObject ||
        (fromObject.objectType !== ObjectTypeEnum.Entity &&
          fromObject.objectType !== ObjectTypeEnum.Study) ||
        (toObject.objectType !== ObjectTypeEnum.Entity &&
          toObject.objectType !== ObjectTypeEnum.Study)
      ) {
        // stop execution, return
        return;
      }

      // check if the nodes are already in the graph
      const fromNode = nodesRef.current.find(
        (node) => node.id === fromObject.id
      );
      const toNode = nodesRef.current.find((node) => node.id === toObject.id);

      // if both nodes are not in the graph
      if (!fromNode && !toNode) {
        // stop execution, return
        return;
      }

      // if both nodes are in the graph
      if (fromNode && toNode) {
        // add edge between nodes
        createEdgeBetweenNodes(fromObject.id, toObject.id);
      } else if (!fromNode) {
        // otherwise, if fromNode is not in the graph
        // load more from server starting from toId
        await onLoadMoreClickAsync(toObject.id, toObject.objectType);
      } else if (!toNode) {
        // otherwise, if toNode is not in the graph
        // load more from server starting from fromId
        await onLoadMoreClickAsync(fromObject.id, fromObject.objectType);
      }
    },
    [createEdgeBetweenNodes, isUserExternal, onLoadMoreClickAsync]
  );

  const onLinkGraphNodeNameChange = useCallback(
    (objectId: string, name: string) => {
      // go through all nodes in nodesRef
      for (const node of nodesRef.current) {
        // if node id is equal to objectId
        if (node.id === objectId) {
          // update text of node
          node.data = {
            ...node.data,
            text: name,
          };
        }
      }

      // set nodesRef
      nodesRef.current = [...nodesRef.current];
      // set nodes
      setNodes([...nodesRef.current]);
    },
    []
  );

  const onObjectDeleted = useCallback((objectId: string) => {
    nodesRef.current = nodesRef.current.filter((node) => node.id !== objectId);
    setNodes([...nodesRef.current]);

    edgesRef.current = edgesRef.current.filter(
      (edge) => edge.source !== objectId && edge.target !== objectId
    );
    setEdges([...edgesRef.current]);
  }, []);

  // Custom hooks for Pub/Sub events handling
  useObjectNameChangeListener(onLinkGraphNodeNameChange);
  useAnyLinkRemovedListener(onLinkRemoved);
  useObjectLinkedListener(onObjectLinkedAsync);
  useObjectDeletedListener(onObjectDeleted);

  const onAddNewLinkClick = useCallback(
    (data: TReactFlowNode, defaultLinkTypeValue: string) => {
      // If the user is readonly then do nothing
      if (isUserExternal) return;

      if (LinkingConstants.CHILD_LINK_TYPE.value === defaultLinkTypeValue) {
        setDefaultLinkType(LinkingConstants.CHILD_LINK_TYPE);
      } else if (
        LinkingConstants.PARENT_LINK_TYPE.value === defaultLinkTypeValue
      ) {
        setDefaultLinkType(LinkingConstants.PARENT_LINK_TYPE);
      } else {
        return;
      }
      setSelectedObjects([
        {
          id: data.id,
          type: data.objectType,
          name: data.text,
          objectType: data.objectTypeEnum,
        },
      ]);
      setIsLinkModalOpen(true);
      LogHelperSingleton.log("StructureGraphStartAddNewLink");
    },
    [isUserExternal]
  );

  const onLinkingDoneAsync = async (
    isLinkingDone: boolean,
    selectedLinkToObject: TIdNameTypeObjectType | undefined,
    linkType: string | undefined
  ) => {
    // safety-checks
    if (selectedObjects.length === 0 || !isLinkingDone) {
      return;
    }

    // log
    LogHelperSingleton.log("StructureGraphLinkingDone");

    // if the selectedLinkToObject node is already in the graph, then just create an edge
    const selectedLinkToNode = nodesRef.current.find(
      (node) => node.id === selectedLinkToObject?.id
    );
    const source =
      linkType === LinkingConstants.CHILD_LINK_TYPE.value
        ? selectedLinkToNode
        : selectedObjects[0];
    const target =
      linkType === LinkingConstants.CHILD_LINK_TYPE.value
        ? selectedObjects[0]
        : selectedLinkToNode;
    if (selectedLinkToNode && source && target) {
      createEdgeBetweenNodes(source.id, target.id);
      return;
    }

    // trigger a load more click on the node previously selected for linking
    onLoadMoreClickAsync(selectedObjects[0].id, selectedObjects[0].objectType);
  };

  const collapseOrExpandNodeRecursively = useCallback(
    (
      nodeIdToBeHidden: string,
      currentNodes: Node<TReactFlowNode>[],
      isCollapsed: boolean,
      nodeIdToBeCollapsed: string
    ) => {
      const oneLevelChildEdges = edgesRef.current.filter(
        (edge) => edge.source === nodeIdToBeHidden
      );

      if (oneLevelChildEdges.length === 0) {
        return currentNodes;
      }

      let newNodes = currentNodes;
      oneLevelChildEdges.forEach((edge) => {
        const childId = edge.target;

        // prevent looping -trying to hide/show current collapsed/expanded node-
        if (childId === nodeIdToBeCollapsed) return;

        // prevent looping -if current child is already shown/hidden, then return-
        const isAlreadyChanged =
          newNodes.find((node) => node.id === childId)?.data.isHidden ===
          !isCollapsed;
        if (isAlreadyChanged) return;

        // hide/show target node which is connected to the collapsed/expanded or hidden/shown node
        newNodes = newNodes.map((node) => {
          if (node.id === childId) {
            return { ...node, data: { ...node.data, isHidden: !isCollapsed } };
          }
          return node;
        });

        // if target node is already collapsed, then don't do anything for its childs
        const isTargetNodeCollapsed = newNodes.find(
          (node) => node.id === childId
        )?.data.isCollapsed;
        if (isTargetNodeCollapsed) return;

        // hide/show child nodes of hidden/shown node
        newNodes = collapseOrExpandNodeRecursively(
          childId,
          newNodes,
          isCollapsed,
          nodeIdToBeCollapsed
        );
      });

      return newNodes;
    },
    []
  );

  const onCollapseOrExpandClick = useCallback(
    (isCollapsed: boolean, id: string) => {
      const currentNodes = nodesRef.current.map((node) => {
        if (node.id === id) {
          const oneLevelChildEdges = edgesRef.current.filter(
            (edge) => edge.source === id
          );
          return {
            ...node,
            data: {
              ...node.data,
              isCollapsed: !isCollapsed,
              collapsedDirectChildrenCount: !isCollapsed
                ? oneLevelChildEdges.length
                : 0,
            },
          };
        }
        return node;
      });
      const initiallyCollapsedOrExpandedNodes = collapseOrExpandNodeRecursively(
        id,
        currentNodes,
        isCollapsed,
        id
      );

      // check all nodes, if a node has hidden direct child, add "collapsed (X)" text
      const newNodes = checkAllNodesAndCollapseIfNecessary(
        initiallyCollapsedOrExpandedNodes
      );
      // clean nodes
      const cleanedNodes = cleanNodes(newNodes);
      setNodes(cleanedNodes);
      nodesRef.current = cleanedNodes;
      if (!isCollapsed) {
        LogHelperSingleton.log("StructureGraphCollapse");
      } else {
        LogHelperSingleton.log("StructureGraphExpand");
      }
    },
    [collapseOrExpandNodeRecursively, cleanNodes]
  );

  const checkAllNodesAndCollapseIfNecessary = (
    newNodes: Node<TReactFlowNode>[]
  ) => {
    return newNodes.map((node) => {
      // get direct child edges
      const oneLevelChildEdges = edgesRef.current.filter(
        (edge) => edge.source === node.id
      );

      const hiddenTargetNodesCount = oneLevelChildEdges.reduce(
        (previous, currentEdge) => {
          const isTargetHidden = newNodes.find(
            (innerNode) => innerNode.id === currentEdge.target
          )?.data.isHidden;
          if (isTargetHidden) return previous + 1;
          return previous;
        },
        0
      );

      let isCollapsed = node.data.isCollapsed;
      if (hiddenTargetNodesCount === 0) {
        isCollapsed = false;
      } else if (
        oneLevelChildEdges.length === hiddenTargetNodesCount &&
        !node.data.isHidden
      ) {
        isCollapsed = true;
      }

      return {
        ...node,
        data: {
          ...node.data,
          isCollapsed,
          collapsedDirectChildrenCount: hiddenTargetNodesCount,
        },
      };
    });
  };

  const onEdgesDelete = useCallback(
    async (currentEdges: Edge[]) => {
      // If the user is readonly then do nothing
      if (isUserExternal) return;

      const edge = currentEdges[0];

      // Delete link between linked objects
      const isSuccess = await LinkingControllerSingleton.deleteAsync(
        edge.source,
        edge.target
      );

      // Check if objects were unlinked successfully
      if (!isSuccess) {
        // clean edges
        const cleanedEdges = cleanEdges(edgesRef.current, nodesRef.current);
        // set edges
        setEdges([...cleanedEdges]);
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not unlink linked item."
        );
        return;
      }

      LogHelperSingleton.log("StructureGraphDeleteEdges");
    },
    [cleanEdges, isUserExternal]
  );

  const onEdgeDelete = useCallback(
    async (selectedEdgeId: string) => {
      const selectedEdge = edgesRef.current.find(
        (edge) => edge.id === selectedEdgeId
      );
      if (selectedEdge) {
        return await onEdgesDelete([selectedEdge]);
      }
    },
    [onEdgesDelete]
  );

  const onLinkFromEdgeAsync = useCallback(
    async (sourceNode: Node, targetNode: Node): Promise<boolean> => {
      // get data from source node
      const { id, data } = sourceNode;

      // set selected objects to the source node
      const newSelectedObjects = [
        {
          id,
          type: data.objectType,
          name: data.text,
          objectType: data.objectTypeEnum,
        },
      ];
      setSelectedObjects(newSelectedObjects);

      // log
      LogHelperSingleton.log("StructureGraphLinkingDone");
      LogHelperSingleton.log("StructureGraphLinkFromEdge");

      // create link async
      return await LinkingHelperSingleton.linkAsync(
        targetNode.id,
        targetNode.data.objectTypeEnum,
        newSelectedObjects,
        "2"
      );
    },
    []
  );

  /** This function is only called when linking from edges on graph. */
  const onConnect = useCallback(
    async (connection: Connection) => {
      if (isUserExternal) {
        return;
      }

      const doesConnectionExist = edgesRef.current.find(
        (edge) =>
          (edge.source === connection.source &&
            edge.target === connection.target) ||
          (edge.source === connection.target &&
            edge.target === connection.source)
      );

      if (doesConnectionExist) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "There is already a link between these objects."
        );
        return;
      }
      const targetNode = nodesRef.current.find(
        (node) => node.id === connection.target
      );
      const sourceNode = nodesRef.current.find(
        (node) => node.id === connection.source
      );

      if (targetNode && sourceNode) {
        // create link between nodes
        const isLinkingDone: boolean = await onLinkFromEdgeAsync(
          sourceNode,
          targetNode
        );
        if (!isLinkingDone) {
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Could not link objects."
          );
        }
      }
    },
    [isUserExternal, onLinkFromEdgeAsync]
  );

  const onDrop = useCallback(
    async (position: XYPosition, draggedObject: TIdNameTypeObjectType) => {
      // If the user is readonly then do nothing
      if (isUserExternal) {
        return;
      }

      if (draggedObject?.id) {
        const newNode = {
          id: draggedObject.id,
          type: ReactFlowHelper.getNodeType(draggedObject.objectType),
          data: {
            id: draggedObject.id,
            objectType: ObjectTypeHelperSingleton.getObjectTypeFullDisplayName(
              draggedObject.objectType,
              "",
              draggedObject?.customTypeName
            ),
            objectTypeEnum: draggedObject.objectType,
            text: draggedObject.name ?? draggedObject.title ?? "",
            loadMoreCount: 0,
            otherUpperLevelNodes: [],
            lowerLevelNodes: [],
            customPosition: position,
          },
          position,
        };

        nodesRef.current = [...nodesRef.current, newNode];
        setNodes(nodesRef.current);
        return;
      }
    },
    [isUserExternal]
  );

  /** Gets called after end of edge gets dragged to another source or target */
  const onEdgeUpdate = useCallback(
    async (oldEdge: Edge, newConnection: Connection) => {
      // If the user is readonly then do nothing
      if (isUserExternal) {
        return;
      }

      // if the old connection & the new connection are same, then do nothing
      if (
        oldEdge.source === newConnection.source &&
        oldEdge.target === newConnection.target
      ) {
        return;
      }

      // prevent linking node to itself
      if (
        oldEdge.source === newConnection.target ||
        oldEdge.target === newConnection.source
      ) {
        return;
      }

      // delete existing link
      await onEdgeDelete(oldEdge.id);

      // create link between nodes
      const sourceNode = nodes.find((node) => node.id === newConnection.source);
      const targetNode = nodes.find((node) => node.id === newConnection.target);
      if (sourceNode && targetNode) {
        const isLinkingDone: boolean = await onLinkFromEdgeAsync(
          sourceNode,
          targetNode
        );
        if (!isLinkingDone) {
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Could not link objects."
          );
        }
      }
    },
    [onLinkFromEdgeAsync, onEdgeDelete, nodes, isUserExternal]
  );

  const onNodeDragStop = (
    _event: React.MouseEvent,
    currNode: Node,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _currNodes: Node[]
  ) => {
    // onNodeDragStop is triggered on node click, too. See: https://github.com/wbkd/react-flow/issues/2622
    // safety-check, do nothing if node position did not change.
    const existingNode = nodes.find((existing) => existing.id === currNode.id);
    const existingNodePosition =
      existingNode?.data.customPosition ?? existingNode?.position;
    const newNodePosition = currNode?.position;
    if (
      existingNodePosition?.x === newNodePosition.x &&
      existingNodePosition?.y === newNodePosition.y
    ) {
      return;
    }

    const newNodes = nodes.map((node) => {
      if (node.id === currNode.id) {
        node.data = {
          ...node.data,
          customPosition: newNodePosition,
        };
      }
      return node;
    });

    // set nodesRef
    nodesRef.current = [...newNodes];
    // set nodes
    setNodes([...nodesRef.current]);
  };

  return (
    // TODO: Ron - Create a message for the user what to do when 'linkGraphForFocusedNode' is undefined
    linkGraphForFocusedNode && linkGraphForFocusedNode.focusedNode ? (
      <>
        <LinkGraphView
          initialNodes={nodes.filter((node) => node.data.isHidden !== true)}
          initialEdges={edges}
          onLoadMoreClickAsync={onLoadMoreClickAsync}
          onAddNewLinkClick={onAddNewLinkClick}
          onCollapseOrExpandClick={onCollapseOrExpandClick}
          onEdgesDelete={onEdgesDelete}
          onEdgeDelete={onEdgeDelete}
          onConnect={onConnect}
          onEdgeUpdate={onEdgeUpdate}
          isViewOnlyMode={!canUserEdit || isUserExternal}
          isFullscreen={isFullscreen}
          onDropEnd={onDrop}
          onNodeDragStop={onNodeDragStop}
          useDragAndDropProps={useDragAndDropProps}
          setIsLinksWindowSearchBarResultsElementActive={
            setIsLinksWindowSearchBarResultsElementActive
          }
        />
        {canUserEdit && (
          <LinkingModal
            isOpen={isLinkModalOpen}
            setIsOpen={setIsLinkModalOpen}
            selectedObjects={selectedObjects}
            onLinkingDoneAsync={onLinkingDoneAsync}
            defaultLinkType={defaultLinkType}
          />
        )}
      </>
    ) : (
      <div className={styles.noFocusScrollContainer}>
        <div className={styles.noFocusContainer}>
          <h6>
            Please navigate or select one of your recent active studies and
            entities below to load the tree view.
          </h6>
          {mySimpleRecentActivity.map((recentActivity) => (
            <ObjectItem
              key={recentActivity.id}
              objectItem={fromTRecentSidebarActivityItemDTO(recentActivity)}
              onClick={() =>
                onReanchorClick(recentActivity.id, recentActivity.objectType)
              }
              isEditable={false}
              isUserExternal={isUserExternal}
              extraClassName={styles.noFocusRecentActive}
            />
          ))}
        </div>
      </div>
    )
  );
};
