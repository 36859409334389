export const QueryConstants = {
  MAXIMUM_QUERIES_TO_RETRIEVE: 10,
  SEARCH_FOR_TECHNOLOGIES: "Search for technologies/materials",
  SEARCH_FOR_SCIENTIFIC_PAPERS: "Search for scientific papers",
  SEARCH_FOR_PATENTS: "Search for patents",
  SCIENCE_DOCUMENTS: "Science documents",
  PATENT_DOCUMENTS: "Patent documents",
  TECHNOLOGY_OVERVIEW: "Technology overview",
  STARTED_SEARCH: "Started search",
  SEARCH_FINISHED: "Search finished",
  SEARCH_CANCELLED: "Search cancelled",
  DEFAULT_PUBLICATION_START_DATE: "1850-01-01",
  TECHNOLOGY_SEARCH: "Technology search",
  DOCUMENT_SEARCH: "Document search",
  CREATE_QUERY_DESCRIPTION: "Create a query in order to search through millions of documents.",
} as const;
