import axios from "axios";
import { OnboardingTaskNameEnum } from "Enums";
import { AxiosHelperSingleton } from "Helpers";
import { TUserOnboardingTaskDTO } from "Types";

export class UserOnboardingTasksController {
  private readonly resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/useronboardingtasks`;

  public async getOnboardingChecklistItemsAsync(): Promise<
    TUserOnboardingTaskDTO[]
  > {
    try {
      const response = await axios.get<TUserOnboardingTaskDTO[]>(
        `${this.resourcePath}`
      );
      if (response?.data) {
        return response.data.sort((taskA, taskB) => taskA.name - taskB.name);
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }

  public async completeUserOnboardingTaskAsync(
    onboardingTaskName: OnboardingTaskNameEnum
  ): Promise<void> {
    try {
      await axios.post(`${this.resourcePath}`, {
        OnboardingTaskName: onboardingTaskName,
      });
    } catch {
      return;
    }
  }
}

export const UserOnboardingTasksControllerSingleton =
  new UserOnboardingTasksController();
