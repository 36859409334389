import { Dispatch, FC, SetStateAction } from "react";
import { RadioButton, TextArea, Warning } from "Components";
import { SearchQueryCriteriaMatchEnum } from "Enums";
import { useNaturalLanguageQuery } from "Hooks";
import { IQueryDTO, ITechnologySearchResult } from "Interfaces";
import { TQueryFiltersDTO } from "Types";
import styles from "./technologySearchFields.module.scss";

type TTechnologySearchFields = {
  setTechSearchQueryTextAreaRef: Dispatch<
    SetStateAction<HTMLTextAreaElement | null>
  >;
  query: IQueryDTO;
  updateQueryFilters: (queryFilters: TQueryFiltersDTO) => void;
  updateNaturalLanguageQuery: (naturalLanguageQuery: string) => void;
  technologySearchResults: ITechnologySearchResult[];
};

export const TechnologySearchFields: FC<TTechnologySearchFields> = ({
  setTechSearchQueryTextAreaRef,
  query,
  updateQueryFilters,
  updateNaturalLanguageQuery,
  technologySearchResults,
}: TTechnologySearchFields) => {
  const { naturalLanguageQuery, onNaturalLanguageQueryUpdate } =
    useNaturalLanguageQuery(query, updateNaturalLanguageQuery);

  const onIgorHitsUpdate = (igorHits: number) => {
    updateQueryFilters({
      ...query.filters,
      technologyFilters: {
        ...query.filters.technologyFilters,
        igorHits,
      },
    });
  };

  return (
    <div className={styles.technologySearchFields}>
      <h2>Technology search</h2>
      <p className={`${styles.description} ${styles.queryDescription}`}>
        Write your main search query here.
      </p>
      <TextArea
        setTextAreaRef={setTechSearchQueryTextAreaRef}
        placeholder="I am looking for a process to extract salt from seawater"
        value={naturalLanguageQuery}
        onChange={onNaturalLanguageQueryUpdate}
        extraClassName={styles.textArea}
      />
      <h3 className={styles.criteriaMatchingHeading}>Criteria matching</h3>
      <div className={styles.criteriaContainer}>
        <p className={`${styles.description} ${styles.criteriaDescription}`}>
          Based on your input, keywords are extracted that the results must or
          should match. With this setting you can decide how strict these
          keywords must match.
        </p>
      </div>
      <RadioButton
        text="Stricter (Higher accuracy)"
        value={SearchQueryCriteriaMatchEnum.Strict}
        checked={
          !query?.filters?.technologyFilters?.igorHits ||
          query?.filters?.technologyFilters?.igorHits ===
            SearchQueryCriteriaMatchEnum.Strict
        }
        onChange={() => {
          onIgorHitsUpdate(SearchQueryCriteriaMatchEnum.Strict);
        }}
      />
      <RadioButton
        text="Looser (More results)"
        value={SearchQueryCriteriaMatchEnum.Loose}
        checked={
          query?.filters?.technologyFilters?.igorHits ===
          SearchQueryCriteriaMatchEnum.Loose
        }
        onChange={() => {
          onIgorHitsUpdate(SearchQueryCriteriaMatchEnum.Loose);
        }}
      />
      {technologySearchResults && technologySearchResults.length > 0 && (
        <Warning extraClassNames={{ container: styles.warningContainer }}>
          You have a technology overview under the Technologies results tab that
          will be overridden when you execute the query here.
        </Warning>
      )}
    </div>
  );
};
