/* eslint-disable @typescript-eslint/no-explicit-any */
// node_modules
import { useCallback, useContext, useEffect, useRef, useState } from "react";
// Enums
import { AskIgorMenuItemEnum, ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import {
  AskIgorMenuItemHelperSingleton,
  LogHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Types
import {
  TAskIgorRequirement,
  TIdNameTypeObjectType,
  TLogEventName,
  TReferenceTextAndLinkDTO,
} from "Types";
// Controllers
import {
  GptControllerSingleton,
  LinkingControllerSingleton,
} from "Controllers";
// Constants
import {
  AiConstants,
  TextConstants,
  WebsocketFunctionNames
} from "Constants";
// Providers
import { WebsocketContext } from "Providers";
// Interfaces
import { IAskIgorRequest, IGptForTiResponse, IUseAskIgor } from "Interfaces";

interface IUseAskIgorProps {
  askIgorMenuItem?: AskIgorMenuItemEnum;
  documentsSelected: string[];
}

type Document = { [key: string]: any };
type Highlight = { [key: string]: any };

export const useAskIgor = ({
  askIgorMenuItem,
  documentsSelected,
}: IUseAskIgorProps): IUseAskIgor => {
  const linkPropertyName = "link";

  const { webSocketController } = useContext(WebsocketContext);

  const [isGeneratingText, setIsGeneratingText] = useState<boolean>(false);
  const [completionId, setCompletionId] =
    useState<string | undefined>(undefined);
  const [aiGeneratedText, setAIGeneratedText] = useState<string>("");

  const abortControllerRef = useRef<AbortController>(new AbortController());
  const timeoutRef = useRef<number | null>(null);

  const onSubmitHandlerAsync = async (
    object: TIdNameTypeObjectType,
    text: string,
    documentTypes: ObjectTypeEnum[],
    dataPointsAmount?: number,
    menuItem?: AskIgorMenuItemEnum,
    requirements?: TAskIgorRequirement[]
  ): Promise<void> => {
    menuItem ??= askIgorMenuItem;
    if (!menuItem) {
      return;
    }

    LogHelperSingleton.logWithProperties("AskIgorAISettingsUpdated", {
      DataPointsAmount: dataPointsAmount,
      DocumentTypes: documentTypes.join(", "),
    });

    if (menuItem === AskIgorMenuItemEnum.Table && requirements) {
      text = requirements
        .map((requirement) => requirement.text.trim())
        .filter((requirement) => requirement)
        .join(", ")
        .trim();

      if (!text) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          AskIgorMenuItemHelperSingleton.getRelatedErrorMessage(menuItem)
        );
        return;
      }
    }

    if (menuItem === AskIgorMenuItemEnum.InformationExtraction) {
      text = text?.trim() || "";

      if (!text) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          AskIgorMenuItemHelperSingleton.getRelatedErrorMessage(menuItem)
        );
        return;
      }
    }

    await requestIgorAssistanceAsync(
      object,
      text,
      menuItem,
      documentTypes,
      dataPointsAmount
    );
  };

  const onCancelCompletion = useCallback(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (completionId) {

      webSocketController.invokeFunction(
        WebsocketFunctionNames.CancelCompletion,
        completionId,
        askIgorMenuItem && [AskIgorMenuItemEnum.GeneralDescription, AskIgorMenuItemEnum.GeneralDescriptionUsingLinks, AskIgorMenuItemEnum.ExecutiveSummary, AskIgorMenuItemEnum.InformationExtraction, AskIgorMenuItemEnum.Table].includes(askIgorMenuItem)
      );

      setCompletionId(undefined);
    }

    abortControllerRef.current.abort();
  }, [askIgorMenuItem, completionId, webSocketController]);

  const onReceiveCompletionPart = useCallback(
    (completionPart: string) => {
      if (
        completionPart === null ||
        completionPart === undefined ||
        !completionId
      ) {
        onCancelCompletion();

        setIsGeneratingText(false);

        return;
      }

      setAIGeneratedText(
        (prevAIGeneratedText) => prevAIGeneratedText + completionPart
      );
    },
    [completionId, onCancelCompletion]
  );

  const replaceReferencesInContent = useCallback(
    (
      content: string,
      referenceDict?: { [key: string]: TReferenceTextAndLinkDTO }
    ): string => {
      let contentCopy = content;

      const referencesInContent: RegExpMatchArray | null = contentCopy.match(
        /\[REF_(abstract|highlight)_\d+_\d+\]/g
      );

      if (referencesInContent && referenceDict) {
        for (const referenceInContent of referencesInContent) {
          let referenceId = referenceInContent;
          referenceId = referenceId.replace("[", "");
          referenceId = referenceId.replace("]", "");

          if (!referenceDict[referenceId]) {
            continue;
          }

          const reference: TReferenceTextAndLinkDTO | undefined =
            referenceDict[referenceId];

          if (!reference?.fulltextlink) {
            continue;
          }

          contentCopy = contentCopy.replace(
            referenceInContent,
            `<a target="_blank" rel="noopener noreferrer" href="${reference.fulltextlink}">[Ref]</a>`
          );
        }
      }

      return contentCopy;
    },
    []
  );

  const extractKeys = (
    obj: { [key: string]: string },
    dataToExtract: Set<string>
  ): void => {
    Object.keys(obj).forEach((key) => {
      if (key !== linkPropertyName) {
        dataToExtract.add(key);
      }
    });
  };

  const integrateHighlights = useCallback(
    (
      documents: { [key: string]: Document },
      highlights: { [key: string]: Highlight },
      dataToExtract: Set<string>
    ): void => {
      Object.values(documents).forEach((document) =>
        extractKeys(document, dataToExtract)
      );

      for (const [highlightId, highlight] of Object.entries(highlights)) {
        let highlightNotFoundInDocuments = true;

        extractKeys(highlight, dataToExtract);

        for (const document of Object.values(documents)) {
          if (highlight.link === document.link) {
            highlightNotFoundInDocuments = false;

            if (!document.highlights) {
              document.highlights = {};
            }

            document.highlights[highlightId] = highlight;
          }
        }

        if (highlightNotFoundInDocuments) {
          documents[highlightId] = highlight;
        }
      }
    },
    []
  );

  const generateContent = (
    documents: { [key: string]: Document },
    dataToExtract: Set<string>,
    formatType: "MARKDOWN" | "HTML_UNORDERED_LIST" | "HTML_PARAGRAPH",
    forDataPoint?: string
  ): string => {
    let content = formatType === "HTML_UNORDERED_LIST" ? "<ul>" : "";
    for (const [documentId, document] of Object.entries(documents)) {
      if (formatType === "HTML_UNORDERED_LIST") {
        content += `<li>${documentId}<ul>`;
      }

      for (const dataPoint of Array.from(dataToExtract)) {
        if (forDataPoint && forDataPoint !== dataPoint) continue;

        let hasValidContent = false;
        const docContent = document[dataPoint]?.trim();

        if (docContent) {
          switch (formatType) {
            case "HTML_UNORDERED_LIST":
              content += `<li>${dataPoint}: ${docContent} <a target="_blank" rel="noopener noreferrer" href="${document.link}">[Ref]</a></li>`;
              break;
            case "HTML_PARAGRAPH":
              content += `<p>${docContent} <a target="_blank" rel="noopener noreferrer" href="${document.link}">[Ref]</a>, `;
              break;
            case "MARKDOWN":
            default:
              content += `${docContent} [[Ref]](${document.link}), `;
              break;
          }
          hasValidContent = true;
        }

        const documentHighlights = document.highlights || {};

        for (const highlightId of Object.keys(documentHighlights)) {
          const highlight = documentHighlights[highlightId];
          const highlightContent = highlight[dataPoint]?.trim();

          if (highlightContent) {
            switch (formatType) {
              case "HTML_UNORDERED_LIST":
                if (!hasValidContent) {
                  content += `<li>${dataPoint}: `;
                }
                content += `${highlightContent} <a target="_blank" rel="noopener noreferrer" href="${highlight.link}">[Ref]</a>, `;
                break;
              case "HTML_PARAGRAPH":
                if (!hasValidContent) {
                  content += "<p>";
                }
                content += `${highlightContent} <a target="_blank" rel="noopener noreferrer" href="${highlight.link}">[Ref]</a>, `;
                break;
              case "MARKDOWN":
              default:
                content += `${highlightContent} [[Ref]](${highlight.link}), `;
                break;
            }
            hasValidContent = true;
          }
        }

        if (!hasValidContent && formatType === "MARKDOWN") continue;

        if (formatType === "HTML_UNORDERED_LIST") {
          if (!hasValidContent) {
            content += `<li>${dataPoint}: N/A</li>`;
          } else if (content.endsWith(", ")) {
            content = `${content.slice(0, -2)}</li>`;
          }
        }
      }

      if (formatType === "HTML_UNORDERED_LIST") {
        content += "</ul></li>";
      }
    }

    switch (formatType) {
      case "HTML_UNORDERED_LIST":
        content += "</ul>";
        break;
      case "HTML_PARAGRAPH":
        if (content) content = `${content.slice(0, -2)}</p>`;
        break;
      case "MARKDOWN":
      default:
        if (content.endsWith(", ")) content = `${content.slice(0, -2)}`;
        break;
    }

    if (!content) {
      switch (formatType) {
        case "HTML_UNORDERED_LIST":
          return "<ul><li>N/A</li></ul>";
        case "HTML_PARAGRAPH":
          return "<p>N/A</p>";
        case "MARKDOWN":
        default:
          return "N/A";
      }
    }

    return content;
  };

  const generateContentFromGptForTiResponse = useCallback(
    (
      formatType: "MARKDOWN" | "HTML_UNORDERED_LIST" | "HTML_PARAGRAPH",
      documents: {
        [key: string]: {
          [key: string]: string;
        };
      },
      highlights: {
        [key: string]: {
          [key: string]: string;
        };
      },
      forDataPoint?: string
    ): string => {
      const dataToExtract = new Set<string>();

      integrateHighlights(documents, highlights, dataToExtract);

      return generateContent(
        documents,
        dataToExtract,
        formatType,
        forDataPoint
      );
    },
    [integrateHighlights]
  );

  const getGptForTiGenerationContent = useCallback(
    (result: IGptForTiResponse): string => {
      if (!askIgorMenuItem) return "";

      const getContentWithReplacedRefs = (content: string, referenceDict?: {
        [key: string]: TReferenceTextAndLinkDTO;
      }) =>
        replaceReferencesInContent(`<p>${content}</p>`, referenceDict);

      switch (askIgorMenuItem) {
        case AskIgorMenuItemEnum.GeneralDescriptionUsingLinks: {
          const { TechnologyDescription } = result;
          if (TechnologyDescription?.Description) {
            return getContentWithReplacedRefs(TechnologyDescription.Description, TechnologyDescription.ReferenceDict);
          }
          break;
        }
        case AskIgorMenuItemEnum.ExecutiveSummary: {
          const { ExecutiveSummary } = result;
          if (ExecutiveSummary?.Summary) {
            return getContentWithReplacedRefs(ExecutiveSummary.Summary, ExecutiveSummary.ReferenceDict);
          }
          break;
        }
        case AskIgorMenuItemEnum.Table: {
          const { Table } = result;
          if (Table) {
            return generateContentFromGptForTiResponse("HTML_UNORDERED_LIST", Table.Documents, Table.Highlights);
          }
          break;
        }
        case AskIgorMenuItemEnum.InformationExtraction: {
          const { InformationExtraction } = result;
          if (InformationExtraction) {
            return generateContentFromGptForTiResponse("HTML_UNORDERED_LIST", InformationExtraction.Documents, InformationExtraction.Highlights);
          }
          break;
        }
        default:
          return "";
      }

      return "";
    },
    [
      askIgorMenuItem,
      generateContentFromGptForTiResponse,
      replaceReferencesInContent,
    ]
  );

  const onReceiveCompletionResult = useCallback(
    (result: IGptForTiResponse | string | null | undefined) => {
      if (!completionId) return;

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      if (!result) {
        setAIGeneratedText(
          `${AiConstants.ERROR_START_MESSAGE
          }${AskIgorMenuItemHelperSingleton.getRelatedErrorMessage(
            askIgorMenuItem
          )}`
        );
        return;
      } else if (typeof result === "string") {
        setAIGeneratedText(`${AiConstants.ERROR_START_MESSAGE}${result}`);
        return;
      }

      setAIGeneratedText(getGptForTiGenerationContent(result));

      setCompletionId(undefined);

      setIsGeneratingText(false);
    },
    [askIgorMenuItem, completionId, getGptForTiGenerationContent]
  );

  useEffect(() => {
    webSocketController.addHandler(
      WebsocketFunctionNames.ReceiveCompletionPart,
      onReceiveCompletionPart
    );
    webSocketController.addHandler(
      WebsocketFunctionNames.ReceiveCompletionResult,
      onReceiveCompletionResult
    );

    return () => {
      webSocketController.removeHandler(
        WebsocketFunctionNames.ReceiveCompletionPart,
        onReceiveCompletionPart
      );
      webSocketController.removeHandler(
        WebsocketFunctionNames.ReceiveCompletionResult,
        onReceiveCompletionResult
      );
    };
  }, [onReceiveCompletionPart, onReceiveCompletionResult, webSocketController]);

  const requestNonWebsocketBasedIgorAssistanceAsync = async (
    object: TIdNameTypeObjectType,
    selectedMenuItem: AskIgorMenuItemEnum,
    documentTypes: ObjectTypeEnum[],
    dataPoints: string[],
    dataPointsAmount?: number
  ) => {
    abortControllerRef.current = new AbortController();

    if (selectedMenuItem === AskIgorMenuItemEnum.GenerateReport) {
      const description: string | undefined =
        await GptControllerSingleton.generateReportFromDocumentsAndHighlightsAsync(
          object.id,
          object.objectType,
          abortControllerRef.current.signal,
          dataPointsAmount,
          documentTypes,
          dataPoints
        );

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      if (abortControllerRef.current.signal.aborted) return;

      if (description === undefined || description === null) {
        setAIGeneratedText(
          `${AiConstants.ERROR_START_MESSAGE
          }${AskIgorMenuItemHelperSingleton.getRelatedErrorMessage(
            selectedMenuItem
          )}`
        );

        return;
      }

      setAIGeneratedText(description);
    } else if (
      selectedMenuItem ===
      AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge
    ) {
      const name = object.name ?? object.title ?? "";
      const description: string | undefined =
        await GptControllerSingleton.generateDescriptionAsync(
          name,
          abortControllerRef.current.signal
        );

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      if (abortControllerRef.current.signal.aborted) return;

      if (description === undefined || description === null) {
        setAIGeneratedText(
          `${AiConstants.ERROR_START_MESSAGE
          }${AskIgorMenuItemHelperSingleton.getRelatedErrorMessage(
            selectedMenuItem
          )}`
        );

        return;
      }

      setAIGeneratedText(description);
    }
  };

  const requestIgorAssistanceAsync = async (
    object: TIdNameTypeObjectType,
    text: string,
    selectedMenuItem: AskIgorMenuItemEnum,
    documentTypes: ObjectTypeEnum[],
    dataPointsAmount?: number
  ) => {
    if (isGeneratingText) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        AiConstants.AI_GENERATION_ALREADY_RUNNING
      );
      return;
    }

    onCancelCompletion();

    setIsGeneratingText(false);

    setAIGeneratedText("");

    const doRequireDocumentsOrHighlights =
      AskIgorMenuItemHelperSingleton.getDoRequireDocumentsOrHighlights(
        selectedMenuItem
      );

    if (doRequireDocumentsOrHighlights && documentsSelected.length <= 0) {
      const hasDocumentsOrHighlights =
        await LinkingControllerSingleton.hasLinksOfTypesAsync(object.id, [
          ObjectTypeEnum.Highlight,
          ...documentTypes,
        ]);

      if (!hasDocumentsOrHighlights) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          TextConstants.AI_REQUIRES_DOCUMENTS_OR_HIGHLIGHTS
        );
        return;
      }
    }

    const isWebsocketBased: boolean = [
      AskIgorMenuItemEnum.QuestionAndAnswer,
      AskIgorMenuItemEnum.GeneralDescriptionUsingLinks,
      AskIgorMenuItemEnum.WriteSection,
      AskIgorMenuItemEnum.Table,
      AskIgorMenuItemEnum.ExecutiveSummary,
      AskIgorMenuItemEnum.InformationExtraction,
    ].includes(selectedMenuItem);

    dataPointsAmount =
      documentsSelected.length > 0 ? undefined : dataPointsAmount;

    const webSocketFunctionName: string =
      AskIgorMenuItemHelperSingleton.getRelatedWebsocketFunctionName(
        selectedMenuItem
      );

    if (isWebsocketBased && !webSocketFunctionName) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        AiConstants.AI_GENERATION_NOT_SUPPORTED
      );
      return;
    }

    const newCompletionId = window.crypto.randomUUID();
    setCompletionId(newCompletionId);
    setIsGeneratingText(true);

    const startLogEventName: TLogEventName =
      AskIgorMenuItemHelperSingleton.getRelatedStartLogEventName(
        selectedMenuItem
      );

    LogHelperSingleton.log(startLogEventName);

    timeoutRef.current = window.setTimeout(() => {
      onCancelCompletion();

      setAIGeneratedText(
        `${AiConstants.ERROR_START_MESSAGE}${AiConstants.AI_GENERATION_TIMED_OUT}`
      );

      setIsGeneratingText(false);
    }, AiConstants.AI_GENERATION_TIMEOUT_DURATION);

    if (isWebsocketBased) {
      await webSocketController.invokeFunction(webSocketFunctionName, {
        completionId: newCompletionId,
        objectId: object.id,
        objectType: object.objectType,
        input: text,
        dataPointsAmount: dataPointsAmount,
        documentTypes: documentTypes,
        dataPoints: documentsSelected,
      } as IAskIgorRequest);
    } else {
      await requestNonWebsocketBasedIgorAssistanceAsync(
        object,
        selectedMenuItem,
        documentTypes,
        documentsSelected,
        dataPointsAmount
      );

      setIsGeneratingText(false);
    }
  };

  return {
    isGeneratingText,
    setIsGeneratingText,
    onSubmitHandlerAsync,
    aiGeneratedText,
    setAIGeneratedText,
    onCancelCompletion,
    generateContentFromGptForTiResponse,
  };
};
