import { useCallback, useContext } from "react";
import { WebsocketContext } from "Providers";
import { LogFeatureNameEnum, SearchQueryTypeEnum, ToastTypeEnum } from "Enums";
import { IDocumentSearchResult, IExecuteQueryParams } from "Interfaces";
import { LogHelperSingleton, ToastHelperSingleton } from "Helpers";
import { WebsocketFunctionNames } from "Constants";
import {
  UseOnScienceControllerSingleton,
  UseOnPatentsControllerSingleton,
} from "Controllers";

export const useQuery = () => {
  const { webSocketController } = useContext(WebsocketContext);

  const startUSESearchAsync = useCallback(async ({
    query,
    searchQueryType,
    abortSignal,
  }: Partial<IExecuteQueryParams>): Promise<
    | {
      response?: string;
      documents: (IDocumentSearchResult & {
        score?: number | null;
        citation_score?: number | null;
      })[];
    }
    | undefined
  > => {
    if (!query || !query.naturalLanguageQuery) return undefined;

    let response: string | undefined;
    let documents: (IDocumentSearchResult & {
      score?: number | null;
      citation_score?: number | null;
    })[] = [];

    if (searchQueryType === SearchQueryTypeEnum.USEOnScience) {
      LogHelperSingleton.logWithProperties(
        `${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-USEOnScience`,
        { QueryGuid: query?.guid }
      );

      response = await UseOnScienceControllerSingleton.chatAsync(
        query.naturalLanguageQuery,
        query.filters.scienceFilters.startDate,
        query.filters.scienceFilters.endDate,
        abortSignal
      );

      documents = await UseOnScienceControllerSingleton.searchAsync(
        query.naturalLanguageQuery,
        query.filters.useOnScienceFilters.hasCitationScore,
        query.filters.scienceFilters.startDate,
        query.filters.scienceFilters.endDate,
        abortSignal
      );
    } else if (searchQueryType === SearchQueryTypeEnum.USEOnPatents) {
      LogHelperSingleton.logWithProperties(
        `${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-USEOnPatents`,
        { QueryGuid: query?.guid }
      );

      response = await UseOnPatentsControllerSingleton.chatAsync(
        query.naturalLanguageQuery,
        query.filters.patentFilters.startDate,
        query.filters.patentFilters.endDate,
        abortSignal
      );

      documents = await UseOnPatentsControllerSingleton.searchAsync(
        query.naturalLanguageQuery,
        query.filters.patentFilters.startDate,
        query.filters.patentFilters.endDate,
        abortSignal
      );
    }

    if (!response && !abortSignal?.aborted) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not retrieve answer."
      );
    }

    if ((!documents || documents.length === 0) && !abortSignal?.aborted) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "No documents found."
      );
    }

    return { response, documents };
  }, []);

  const startDocumentSearchAsync = async ({
    query,
    searchQueryType,
    doGroupDocumentSearchResults,
    searchAmount,
    filteredResultNames,
    groupName,
    sortQuery,
    page: currentPage,
    isPaginate,
  }: Partial<IExecuteQueryParams>) => {
    if (searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles) {
      LogHelperSingleton.logWithProperties(
        `${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-Science${isPaginate ? "-Paginate" : ""
        }`,
        { QueryGuid: query?.guid }
      );
    } else if (searchQueryType === SearchQueryTypeEnum.UniversePatents) {
      LogHelperSingleton.logWithProperties(
        `${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-Patent${isPaginate ? "-Paginate" : ""
        }`,
        { QueryGuid: query?.guid }
      );
    }

    await webSocketController.invokeFunction(
      WebsocketFunctionNames.StartSearchQueryById,
      query?.guid,
      searchQueryType,
      currentPage,
      doGroupDocumentSearchResults,
      searchAmount,
      filteredResultNames ?? [],
      groupName ?? null,
      sortQuery
    );
  };

  const startTechnologySearchAsync = async ({
    query,
    isTechnologySearchOnResults,
    documents,
  }: Partial<IExecuteQueryParams>) => {
    if (isTechnologySearchOnResults) {
      LogHelperSingleton.logWithProperties(
        `${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-TechnologyOnResults`,
        { QueryGuid: query?.guid }
      );
      await webSocketController.invokeFunction(
        WebsocketFunctionNames.StartTechnologySearchOnResults,
        query?.id,
        query?.naturalLanguageQuery,
        query?.filters?.technologyFilters?.doGroupResults,
        documents
      );
    } else {
      LogHelperSingleton.logWithProperties(
        `${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-Technology`,
        { QueryGuid: query?.guid }
      );
      await webSocketController.invokeFunction(
        WebsocketFunctionNames.StartTechnologySearch,
        query?.id,
        query?.naturalLanguageQuery,
        query?.filters?.technologyFilters?.igorHits
      );
    }
  };

  const cancelSearchAsync = async (
    correlationId: string,
    searchQueryType: SearchQueryTypeEnum,
    isTechnologySearchOnResults: boolean
  ) => {
    if (
      searchQueryType === SearchQueryTypeEnum.UniverseTechnologies ||
      isTechnologySearchOnResults
    ) {
      await webSocketController.invokeFunction(
        WebsocketFunctionNames.StopTechnologySearch,
        correlationId
      );
      return;
    }

    await webSocketController.invokeFunction(
      WebsocketFunctionNames.CancelSearch,
      correlationId
    );
  };

  return {
    startUSESearchAsync,
    startDocumentSearchAsync,
    startTechnologySearchAsync,
    cancelSearchAsync,
  };
};
