import { useContext, useEffect } from "react";
import { PubSubContext } from "Providers";
import { PubSubEventTypeEnum } from "Enums";

export const useDocumentLinkedToPageListener = (onDocumentLinkedToPage: (userId: string) => void) => {
  const { pubSubHandler } = useContext(PubSubContext);
  useEffect(() => {
    pubSubHandler.subscribeToEvent(PubSubEventTypeEnum.DocumentLinkedToPage, onDocumentLinkedToPage);

    return () => {
      pubSubHandler.unsubscribeFromEvent(PubSubEventTypeEnum.DocumentLinkedToPage, onDocumentLinkedToPage);
    };
  }, [onDocumentLinkedToPage, pubSubHandler]);
};
