import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import {
  faArrowRightLong,
  faAngleLeft,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, SunburstChart } from "Components";
import { LogFeatureNameEnum } from "Enums";
import {
  EntityHelperSingleton,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
} from "Helpers";
import {
  fromIDocumentDetails,
  fromIDocumentSearchResult,
  IDocumentDetails,
  IDocumentExtractedMetadata,
  IDocumentSearchResult,
  IEntityDTO,
  IQueryDTO,
  ITechnologySearchResult,
} from "Interfaces";
import {
  fromITechnologySearchResultsToTSunburstChart,
  TQueryViewOptions,
} from "Types";
import { DocumentSearchResult } from "../SearchResults";
import { ExtractedTechnology } from "../SearchResults/ExtractedTechnology/ExtractedTechnology";
import styles from "./technologySearchResults.module.scss";
import { QuerySaveResults } from "../QuerySaveResults";

type TTechnologySearchResultsProps = {
  query: IQueryDTO;
  technologySearchResults: ITechnologySearchResult[];
  openDocumentModal: (dataItem: string) => void;
  updateDocument: (document: IDocumentSearchResult) => void;
  allQueryViewOptions: TQueryViewOptions;
  selectedItem?: string;
  setSelectedItem: Dispatch<SetStateAction<string | undefined>>;
};

type TSelectedTechnologyDocumentsPairs = {
  technology: string;
  documents: (IDocumentSearchResult & {
    extractedMetadata: IDocumentExtractedMetadata;
  })[];
}[];

export const TechnologySearchResults: FC<TTechnologySearchResultsProps> = ({
  query,
  technologySearchResults,
  openDocumentModal,
  updateDocument,
  allQueryViewOptions,
  selectedItem,
  setSelectedItem,
}: TTechnologySearchResultsProps) => {
  const [
    selectedTechnologyDocumentsPairs,
    setSelectedTechnologyDocumentsPairs,
  ] = useState<TSelectedTechnologyDocumentsPairs>([]);

  const documentsCount = useMemo(
    () =>
      technologySearchResults.reduce(
        (acc, technologySearchResult) =>
          acc + technologySearchResult.documents.length,
        0
      ),
    [technologySearchResults]
  );

  const allDocuments = useMemo(
    () =>
      technologySearchResults.reduce(
        (acc, technologySearchResult) =>
          acc.concat(technologySearchResult.documents),
        [] as (IDocumentSearchResult & {
          extractedMetadata: IDocumentExtractedMetadata;
        })[]
      ),
    [technologySearchResults]
  );

  const onEntityLinkToAll = (
    isChecked: boolean,
    documents?: (IDocumentSearchResult & {
      extractedMetadata: IDocumentExtractedMetadata;
    })[]
  ) => {
    if (isChecked) {
      const allSelectedPairs = technologySearchResults.map((technology) => ({
        technology: technology.name,
        documents: technology.documents,
      }));
      let currentSelectedPairs = allSelectedPairs;
      if (documents && selectedItem) {
        currentSelectedPairs = [{ technology: selectedItem, documents }];
      }
      setSelectedTechnologyDocumentsPairs(currentSelectedPairs);
      return;
    }
    setSelectedTechnologyDocumentsPairs([]);
  };

  const changeSelectedTechnology = (item: string, depth?: number) => {
    // If the user clicks on a document, open the document modal
    if (depth && depth === 2) {
      openDocumentModal(item);
      return;
    }

    // If the user clicks on a technology, update the selected item
    setSelectedItem(item);
    setSelectedTechnologyDocumentsPairs([]);

    // Log the click on the technology
    if (item) {
      LogHelperSingleton.logWithProperties("ClickOnTechnology", {
        ActionOrigin: depth === 1 ? "SunburstChart" : "TechnologyList",
      });
    }
  };

  const addExtractedTechnologyAsEntityCallbackAsync = async (
    technologySearchResult: ITechnologySearchResult,
    createdEntity: IEntityDTO
  ): Promise<void> => {
    let selectedDocumentsInTechnology: (IDocumentSearchResult & {
      extractedMetadata: IDocumentExtractedMetadata;
    })[] = [];
    const selectedPair = selectedTechnologyDocumentsPairs.find(
      (pair) => pair.technology === technologySearchResult.name
    );
    if (selectedPair) {
      selectedDocumentsInTechnology = selectedPair.documents;
    }

    if (selectedDocumentsInTechnology.length === 0) {
      selectedDocumentsInTechnology = technologySearchResult.documents;
    }

    selectedDocumentsInTechnology.forEach(async (document) => {
      await EntityHelperSingleton.addDocumentToEntityAsync(
        fromIDocumentSearchResult(document),
        ObjectTypeHelperSingleton.documentTypeToObjectType(
          document.documentType
        ),
        createdEntity,
        {
          ActionOrigin: LogFeatureNameEnum.AdvancedSearch,
          QueryGuid: query.guid,
        },
        LogFeatureNameEnum.AdvancedSearch,
        (documentToUpdate: IDocumentDetails) => {
          updateDocument(fromIDocumentDetails(documentToUpdate));
        }
      );
    });
    setSelectedTechnologyDocumentsPairs([]);
  };

  const selectAllDocumentsInATechnology = (
    technologySearchResult: ITechnologySearchResult
  ) => {
    if (isAllDocumentsUnderTechnologySelected(technologySearchResult)) {
      setSelectedTechnologyDocumentsPairs(
        selectedTechnologyDocumentsPairs.filter(
          (pair) => pair.technology !== technologySearchResult.name
        )
      );
    } else if (isSomeDocumentsUnderTechnologySelected(technologySearchResult)) {
      setSelectedTechnologyDocumentsPairs(
        selectedTechnologyDocumentsPairs.map((pair) => {
          if (pair.technology === technologySearchResult.name) {
            return {
              technology: pair.technology,
              documents: technologySearchResult.documents,
            };
          }
          return pair;
        })
      );
    } else {
      setSelectedTechnologyDocumentsPairs([
        ...selectedTechnologyDocumentsPairs,
        {
          technology: technologySearchResult.name,
          documents: technologySearchResult.documents,
        },
      ]);
    }
  };

  const isAllDocumentsUnderTechnologySelected = (
    technologySearchResult: ITechnologySearchResult
  ) => {
    const selectedPair = selectedTechnologyDocumentsPairs.find(
      (pair) => pair.technology === technologySearchResult.name
    );
    if (selectedPair) {
      return technologySearchResult.documents.every((document) =>
        selectedPair.documents.some(
          (doc) => doc.documentId === document.documentId
        )
      );
    }
    return false;
  };

  const isSomeDocumentsUnderTechnologySelected = (
    technologySearchResult: ITechnologySearchResult
  ) => {
    if (isAllDocumentsUnderTechnologySelected(technologySearchResult)) {
      return false;
    }
    const selectedPair = selectedTechnologyDocumentsPairs.find(
      (pair) => pair.technology === technologySearchResult.name
    );
    if (selectedPair) {
      return technologySearchResult.documents.some((document) =>
        selectedPair.documents.some(
          (doc) => doc.documentId === document.documentId
        )
      );
    }
    return false;
  };

  const onAddOrRemoveDocumentFromSelected = (
    document: IDocumentSearchResult & {
      extractedMetadata: IDocumentExtractedMetadata;
    },
    technologySearchResult: ITechnologySearchResult
  ) => {
    const selectedPair = selectedTechnologyDocumentsPairs.find(
      (pair) => pair.technology === technologySearchResult.name
    );
    if (selectedPair) {
      if (
        selectedPair.documents.some(
          (doc) => doc.documentId === document.documentId
        )
      ) {
        // remove the document from the selectedTechnologyDocumentsPairs
        const updatedPairs = selectedTechnologyDocumentsPairs
          .map((pair) => {
            if (pair.technology === technologySearchResult.name) {
              const updatedDocuments = pair.documents.filter(
                (doc) => doc.documentId !== document.documentId
              );
              return {
                technology: pair.technology,
                documents: updatedDocuments,
              };
            }
            return pair;
          })
          .filter((pair) => pair.documents.length > 0); // remove the pair if no documents left

        setSelectedTechnologyDocumentsPairs(updatedPairs);
      } else {
        setSelectedTechnologyDocumentsPairs(
          selectedTechnologyDocumentsPairs.map((pair) => {
            if (pair.technology === technologySearchResult.name) {
              return {
                technology: pair.technology,
                documents: [...pair.documents, document],
              };
            }
            return pair;
          })
        );
      }
    } else {
      setSelectedTechnologyDocumentsPairs([
        ...selectedTechnologyDocumentsPairs,
        { technology: technologySearchResult.name, documents: [document] },
      ]);
    }
  };

  const isDocumentSelected = (
    selectedPairs: TSelectedTechnologyDocumentsPairs,
    technologyName: string,
    documentId: string
  ) => {
    return selectedPairs.some(
      (pair) =>
        pair.technology === technologyName &&
        pair.documents.some((doc) => doc.documentId === documentId)
    );
  };

  return (
    <>
      <div className={styles.leftContainer}>
        <div className={styles.chartContainer}>
          <SunburstChart
            data={fromITechnologySearchResultsToTSunburstChart(
              technologySearchResults
            )}
            selectedDataItem={selectedItem}
            changeSelectedDataItem={changeSelectedTechnology}
          />
        </div>
        <div className={styles.entitiesList}>
          <button
            type="button"
            className={styles.showAll}
            onClick={() => changeSelectedTechnology("")}
          >
            Show all
          </button>
          <ul>
            {technologySearchResults.map((child) => (
              <li key={child.name}>
                <button
                  type="button"
                  className={`${styles.entityItem} ${
                    child.name === selectedItem ? styles.selected : ""
                  }`}
                  onClick={() => changeSelectedTechnology(child.name)}
                >
                  {child.name}
                  {child.name === selectedItem && (
                    <FontAwesomeIcon
                      icon={faArrowRightLong}
                      className={styles.icon}
                    />
                  )}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div>
          {!selectedItem && (
            <QuerySaveResults
              filteredDocumentResults={allDocuments}
              documentCount={documentsCount}
              documentsSelected={selectedTechnologyDocumentsPairs
                .map((pair) => pair.documents)
                .flat()}
              query={query}
              onEntityLinkToAll={onEntityLinkToAll}
              onDeselectAllDocuments={() => {
                setSelectedTechnologyDocumentsPairs([]);
              }}
            />
          )}
          {technologySearchResults
            .filter(
              (technologySearchResult) =>
                !selectedItem || selectedItem === technologySearchResult.name
            )
            .map((technologySearchResult) => (
              <div
                key={technologySearchResult.name}
                className={styles.entityContainer}
              >
                <div className={styles.entityTitleContainer}>
                  {!selectedItem ? (
                    <Checkbox
                      theme="black"
                      isPartiallySelected={isSomeDocumentsUnderTechnologySelected(
                        technologySearchResult
                      )}
                      isChecked={isAllDocumentsUnderTechnologySelected(
                        technologySearchResult
                      )}
                      onCheckboxChange={() =>
                        selectAllDocumentsInATechnology(technologySearchResult)
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      className={styles.backIcon}
                      onClick={() => changeSelectedTechnology("")}
                    />
                  )}
                  <ExtractedTechnology
                    query={query}
                    extractedTechnology={technologySearchResult.name}
                    addExtractedTechnologyAsEntityCallbackAsync={(
                      createdEntity: IEntityDTO
                    ) => {
                      addExtractedTechnologyAsEntityCallbackAsync(
                        technologySearchResult,
                        createdEntity
                      );
                    }}
                    extraClassNames={{ title: styles.technologyTitle }}
                  />
                </div>
                {selectedItem && (
                  <QuerySaveResults
                    filteredDocumentResults={technologySearchResult.documents}
                    documentCount={technologySearchResult.documents.length}
                    documentsSelected={selectedTechnologyDocumentsPairs
                      .map((pair) => pair.documents)
                      .flat()}
                    query={query}
                    onEntityLinkToAll={(isChecked) =>
                      onEntityLinkToAll(
                        isChecked,
                        technologySearchResult.documents
                      )
                    }
                    onDeselectAllDocuments={() => {
                      setSelectedTechnologyDocumentsPairs([]);
                    }}
                  />
                )}
                {technologySearchResult.documents.map((document) => (
                  <DocumentSearchResult
                    checked={isDocumentSelected(
                      selectedTechnologyDocumentsPairs,
                      technologySearchResult.name,
                      document.documentId
                    )}
                    key={document.documentId}
                    document={document}
                    updateDocument={updateDocument}
                    hideZeroScoreMetadata
                    queryViewOptions={allQueryViewOptions}
                    extractedMetadata={document.extractedMetadata}
                    doIncludeCheckbox
                    onAddToSelected={() =>
                      onAddOrRemoveDocumentFromSelected(
                        document,
                        technologySearchResult
                      )
                    }
                  />
                ))}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
