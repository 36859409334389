// node_modules
import { FC, useState, useRef, MouseEvent, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// Types
import { TIdNameTypeObjectType } from "Types";
// Components
import { ListItemLink, Popover, Tooltip, ObjectPinElement } from "Components";
// Helpers
import { ObjectTypeHelperSingleton, LogHelperSingleton } from "Helpers";
// Controllers
import { PinControllerSingleton } from "Controllers";
// Custom hooks
import { useObjectReferenceModal, useClickOutsideRef } from "Hooks";
// Providers
import { EditorContext, PinnedContext } from "Providers";
// Enum
import { ObjectTypeEnum } from "Enums";
// Styles
import styles from "../../Topbar/topbar.module.scss";
// Constants
import { GeneralConstants } from "Constants";

// Component props type
type TTopbarDropdownButtonProps = {
  buttonIcon: IconProp;
  items: TIdNameTypeObjectType[];
  emptyItemsMessage: string;
  tooltipText: string;
  onClickCallback?: (param: number) => void;
  displaySubItemType: (item: TIdNameTypeObjectType) => string | undefined;
};

// Component
export const TopbarDropdownButton: FC<TTopbarDropdownButtonProps> = ({
  buttonIcon,
  items,
  emptyItemsMessage,
  tooltipText,
  onClickCallback,
  displaySubItemType,
}: TTopbarDropdownButtonProps) => {
  // State
  const [arePinnedItemsShown, setArePinnedItemsShown] =
    useState<boolean>(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [isPinnedTooltipOpen, setIsPinnedTooltipOpen] =
    useState<boolean>(false);
  const [itemsEdited, setItemsEdited] = useState<
    (TIdNameTypeObjectType & { justChanged: boolean })[]
  >([]);
  const [currentObjectPin, setCurrentObjectPin] = useState<boolean>(false);
  const [grouped, setGrouped] = useState<ObjectTypeEnum[]>([]);

  // Contexts
  const { refreshPins } = useContext(PinnedContext);
  const { objectEdited } = useContext(EditorContext);

  // Ref
  const pinnedContainerElementRef = useRef<HTMLDivElement>(null);
  const prevItemsRef = useRef<TIdNameTypeObjectType[]>(items);

  // Custom Hooks
  const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();
  useClickOutsideRef(
    pinnedContainerElementRef,
    () => {
      hidePinnedItems();
    },
    [],
    GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
  );

  /** Open related object reference modal  */
  const openReferenceModal = (objectId: string, objectType: ObjectTypeEnum) => {
    setReferenceModalProps({
      id: objectId,
      type: objectType,
      isOpen: true,
      doIgnoreIsDeleted: false,
    });
  };

  // OnClick the pinned button will show the pinned item popover and disables the pinned tooltip when onHover
  const onPinnedButtonClickHandler = (callback?: () => void): void => {
    if (arePinnedItemsShown) {
      setArePinnedItemsShown(false);
    } else {
      setArePinnedItemsShown(true);
      setIsPinnedTooltipOpen(false);

      // if callback is passed
      if (callback) {
        // call it
        callback();
      }
    }
  };

  const onMouseEnterHandler = (): void => {
    // Check if the pinned item popover is show otherwise don't show the tooltip onHover
    if (!arePinnedItemsShown) {
      setIsPinnedTooltipOpen(true);
    }
  };

  const hidePinnedItems = (mouseEvent?: MouseEvent): void => {
    // if a mouse event is passed, stop the propagation
    // because we do not want to call on pinned button click handler when calling this method from ListItemLink
    if (mouseEvent) {
      mouseEvent.stopPropagation();
    }

    // hide pinned items
    setArePinnedItemsShown(false);
    // hide pinned tooltip
    setIsPinnedTooltipOpen(false);
  };

  const onEditPinList = (id: string, type: number, action: string) => {
    setItemsEdited((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, justChanged: !item.justChanged } : item
      )
    );

    setCurrentObjectPin(false);
    togglePin(id, type, action);
  };

  const togglePin = async (
    objectId: string,
    objectType: number,
    action: string
  ) => {
    if (!objectId) return;

    if (action === "add") {
      LogHelperSingleton.log("AddPinToObject");
      await PinControllerSingleton.addAsync(objectId, objectType);
    } else {
      LogHelperSingleton.log("RemovePinFromObject");
      await PinControllerSingleton.removeAsync(objectId);
    }

    await refreshPins();
  };

  // Unpin the current object
  const onCurrentObjectUnpin = () => {
    setCurrentObjectPin(false);
    setItemsEdited((prevState) =>
      prevState.map((item) =>
        item.id === objectEdited?.id ? { ...item, justChanged: true } : item
      )
    );
  };

  // Track previous pinned items to set the opened list of itemsEdited
  useEffect(() => {
    const prevItems = prevItemsRef.current;
    const newItem = items.find(
      (item) => !prevItems.some((prevItem) => prevItem.id === item.id)
    );

    if (
      newItem &&
      !itemsEdited.some((pinnedItem) => pinnedItem.id === newItem.id)
    ) {
      setItemsEdited((prevState) => [
        { ...newItem, justChanged: false },
        ...prevState,
      ]);
    }

    prevItemsRef.current = items;
  }, [items, itemsEdited]);

  // refresh current pinned items list on popover close
  useEffect(() => {
    if (
      tooltipText === "Pinned" &&
      !items.some((item) => item.id === objectEdited?.id)
    ) {
      setCurrentObjectPin(true);
    }
    !arePinnedItemsShown && setCurrentObjectPin(false);
    tooltipText === "Pinned"
      ? setGrouped([ObjectTypeEnum.Study, ObjectTypeEnum.Entity])
      : setGrouped([0]);
    setItemsEdited(items.map((item) => ({ ...item, justChanged: false })));
  }, [arePinnedItemsShown, items, objectEdited?.id, tooltipText]);

  // Render
  return (
    <div ref={pinnedContainerElementRef}>
      <div
        className={`${styles.navigationButton} ${
          arePinnedItemsShown ? styles.active : ""
        }`}
        ref={setReferenceElement}
        onClick={() => {
          onPinnedButtonClickHandler(() => onClickCallback?.(100));
        }}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={() => setIsPinnedTooltipOpen(false)}
      >
        <FontAwesomeIcon icon={buttonIcon} />
      </div>
      {arePinnedItemsShown && (
        <Popover
          extraClassName={styles.navigationPopoverContainer}
          referenceEl={referenceElement}
          placement="bottom-start"
        >
          {currentObjectPin && (
            <ObjectPinElement
              pinType={"topBar"}
              onEditPinList={onCurrentObjectUnpin}
            />
          )}
          {itemsEdited.length > 0 && (
            <div className={grouped.length > 1 ? styles.pinnedSection : ""}>
              {grouped.map((type) => {
                return (
                  <div key={type}>
                    {grouped.length > 1 &&
                      itemsEdited.filter(
                        (
                          item: TIdNameTypeObjectType & { justChanged: boolean }
                        ) => item.objectType === type
                      ).length > 0 &&
                      itemsEdited.some(
                        (item) => item.objectType === ObjectTypeEnum.Study
                      ) &&
                      itemsEdited.some(
                        (item) => item.objectType === ObjectTypeEnum.Entity
                      ) && (
                        <h4
                          key={`header-${type}`}
                          className={styles.pinnedSectionGroupName}
                        >
                          {ObjectTypeHelperSingleton.getObjectTypeDisplayName(
                            type
                          ).replace(/y$/, "ies")}
                        </h4>
                      )}
                    <ul>
                      {tooltipText !== "Pinned"
                        ? items.map((item: TIdNameTypeObjectType) => (
                            <ListItemLink
                              key={item.id}
                              theme={[tooltipText]}
                              onClick={hidePinnedItems}
                              navigateTo={`/library/${ObjectTypeHelperSingleton.getObjectTypeReactRouteName(
                                item.objectType
                              )}/${item.id}`}
                              iconHasColor={true}
                              icon={ObjectTypeHelperSingleton.getObjectTypeIcon(
                                item.objectType
                              )}
                              date={item.date}
                              object={item}
                              subItemType={displaySubItemType(item)}
                              openReferenceModal={openReferenceModal}
                              moreActionsDropdownPopoverDataIdentifier={
                                GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
                              }
                              moreActionsDropdownOnCloseCallback={() => {
                                hidePinnedItems();
                              }}
                            />
                          ))
                        : itemsEdited
                            .filter(
                              (
                                item: TIdNameTypeObjectType & {
                                  justChanged: boolean;
                                }
                              ) => item.objectType === type
                            )
                            .map(
                              (
                                item: TIdNameTypeObjectType & {
                                  justChanged: boolean;
                                }
                              ) => (
                                <div key={item.id}>
                                  {!item.justChanged ? (
                                    <div
                                      className={styles.pinnedSectionItem}
                                      key={item.id}
                                    >
                                      <ListItemLink
                                        key={item.id + 1}
                                        onClick={hidePinnedItems}
                                        navigateTo={`/library/${ObjectTypeHelperSingleton.getObjectTypeReactRouteName(
                                          item.objectType
                                        )}/${item.id}`}
                                        iconHasColor={true}
                                        icon={ObjectTypeHelperSingleton.getObjectTypeIcon(
                                          item.objectType
                                        )}
                                        object={item}
                                        subItemType={displaySubItemType(item)}
                                        openReferenceModal={openReferenceModal}
                                        moreActionsDropdownPopoverDataIdentifier={
                                          GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
                                        }
                                        moreActionsDropdownOnCloseCallback={() => {
                                          hidePinnedItems();
                                        }}
                                      />
                                      <ObjectPinElement
                                        key={item.id + 2}
                                        pinType={"pinList"}
                                        onEditPinList={() => {
                                          onEditPinList(
                                            item.id,
                                            item.objectType,
                                            "remove"
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <ObjectPinElement
                                      pinType={"unpinned"}
                                      onEditPinList={() => {
                                        onEditPinList(
                                          item.id,
                                          item.objectType,
                                          "add"
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                              )
                            )}
                    </ul>
                    {itemsEdited.some(
                      (item) => item.objectType === ObjectTypeEnum.Study
                    ) &&
                      itemsEdited.some(
                        (item) => item.objectType === ObjectTypeEnum.Entity
                      ) &&
                      type === ObjectTypeEnum.Study && <hr />}
                  </div>
                );
              })}
            </div>
          )}
          {items.length === 0 && (
            <div
              className={`${styles.emptyNavigationPopoverContainer} ${
                itemsEdited.length > 0 ? styles.borderTop : ""
              }`}
            >
              <p
                className={`${
                  tooltipText === "Pinned" ? styles.emptyText : ""
                }`}
              >
                {emptyItemsMessage}
              </p>
            </div>
          )}
        </Popover>
      )}
      <Tooltip
        referenceEl={referenceElement}
        isOpen={isPinnedTooltipOpen}
        tooltipText={tooltipText}
      />
      {referenceModal}
    </div>
  );
};
