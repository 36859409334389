// React
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
// Types
import { ActivityControllerSingleton } from "Controllers";
import { TRecentSidebarActivityItemDTO } from "Types";
import { AuthContext } from "./AuthProvider";

type TRecentActivityContext = {
    mySimpleRecentActivity: TRecentSidebarActivityItemDTO[],
    refreshMySimpleRecentActivityAsync: () => Promise<void>
};

export const defaultRecentActivityContext: TRecentActivityContext = {
    mySimpleRecentActivity: [],
    refreshMySimpleRecentActivityAsync: async () => { return; }
};

type TRecentActivityProviderProps = {
    children?: ReactNode
};

export const RecentActivityContext = createContext<TRecentActivityContext>(defaultRecentActivityContext);

export const RecentActivityProvider = ({ children }: TRecentActivityProviderProps) => {

    // Contexts
    const { auth } = useContext(AuthContext);

    // State
    const [mySimpleRecentActivity, setMySimpleRecentActivity] = useState<TRecentSidebarActivityItemDTO[]>([]);

    // Logic
    const refreshMySimpleRecentActivityAsync = useCallback(async (param?: number): Promise<void> => {
        if (!param) {
            setMySimpleRecentActivity(prev => {
                const filtered = prev.filter(a => a && a.name && a.name.trim().length > 0);
                return filtered.length > 5 ? filtered.slice(0, 5) : filtered;
            });
        }

        // get my simple recent activity
        const retrievedMySimpleRecentActivity = await ActivityControllerSingleton
            .getMyActivityAsync(param);

        // if there is no my simple recent activity, set an empty array
        if (!retrievedMySimpleRecentActivity) {
            setMySimpleRecentActivity([]);
            return;
        }

        // otherwise set my simple recent activity
        setMySimpleRecentActivity(retrievedMySimpleRecentActivity
            .filter((a) => a && a.name && a.name.trim().length > 0));
    }, []);

    // Use effects
    useEffect(() => {
        // make sure we are not requesting auth information (i.e. user is not logging in)
        if (auth.isRequestingAuthInformation) {
            return;
        }

        refreshMySimpleRecentActivityAsync();
    }, [refreshMySimpleRecentActivityAsync, auth.isRequestingAuthInformation]);

    const providerValue = useMemo(() => {
        return { mySimpleRecentActivity, refreshMySimpleRecentActivityAsync };
    }, [mySimpleRecentActivity, refreshMySimpleRecentActivityAsync]);

    // Render
    return (
        <RecentActivityContext.Provider value={providerValue}>
            {children}
        </RecentActivityContext.Provider>
    );
};