/* eslint-disable react/no-danger */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
// Components
import { ListItemLink, Popover, Tooltip } from "Components";
// Enums
import { ObjectTypeEnum, SidebarItemTypeEnum } from "Enums";
// Hooks
import { useObjectReferenceModal } from "Hooks";
// Types
import { TSidebarListItem, fromTSidebarListItem } from "Types";
// Styles
import styles from "./sidebarItem.module.scss";

type TSidebarItemProps = {
  sidebarItem: TSidebarListItem;
  activeSidebarItem: string;
  extraClassNames?: {
    sidebarListItem?: string;
    sidebarListItemTitle?: string;
    sidebarListItemIcon?: string;
    sidebarListItemIconColor?: string;
    sidebarListItemIconContainer?: string;
    listItem?: string;
    listItemLeft?: string;
    sidebarRightIconStyle?: string;
    sidebarRightIconContainer?: string;
  };
  isCollapsed: boolean;
  onItemClick?: (sidebarItem: TSidebarListItem) => void;
  onIconClick?: () => void;
  showInsideAnchor?: boolean;
  onMouseEnterHandler?: () => void;
  onMouseLeaveHandler?: () => void;
};

export const SidebarItem: FC<TSidebarItemProps> = ({
  activeSidebarItem,
  sidebarItem,
  extraClassNames = {},
  isCollapsed,
  onItemClick,
  onIconClick,
  showInsideAnchor = true,
  onMouseEnterHandler,
  onMouseLeaveHandler,
}: TSidebarItemProps) => {
  const {
    icon,
    title,
    subItems,
    navigateTo,
    homePath,
    count,
    isSelected,
    rightContent,
    rightContentColor,
    type,
    htmlTitle,
    externalUrl,
  } = sidebarItem;
  const [isSubMenuShown, setIsSubMenuShown] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLAnchorElement | HTMLDivElement | null>(null);
  const isActive =
    isSelected ??
    ((navigateTo &&
      navigateTo.length > 0 &&
      activeSidebarItem.startsWith(navigateTo)) ||
      activeSidebarItem === homePath); //&& !subItems);
  const isAnySubItemSelected =
    type === SidebarItemTypeEnum.Library ||
    type === SidebarItemTypeEnum.Queries; // subItems && !!subItems.find(item => activeSidebarItem.startsWith(item.navigateTo));

  useEffect(() => {
    setIsSubMenuShown(false);
    setIsTooltipOpen(false);
  }, [activeSidebarItem]);

  // Custom Hooks
  const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

  const openReferenceModal = (objectId: string, objectType: ObjectTypeEnum) => {
    setReferenceModalProps({
      id: objectId,
      type: objectType,
      isOpen: true,
      doIgnoreIsDeleted: false,
    });
  };

  const onMouseEnter = () => {
    if (isCollapsed) {
      if (subItems) {
        if (type) {
          setIsSubMenuShown(true);
        }
      } else {
        setIsTooltipOpen(true);
      }
    }
  };

  const onMouseLeave = () => {
    if (isCollapsed) {
      if (subItems) {
        if (type) {
          setIsSubMenuShown(false);
        }
      } else {
        setIsTooltipOpen(false);
      }
    }
  };

  const SelectedTag = useMemo(() => {
    return externalUrl
      ? "a"
      : navigateTo
      ? showInsideAnchor
        ? Link
        : "div"
      : "div";
  }, [externalUrl, navigateTo, showInsideAnchor]);

  // Render
  return (
    <>
      <li
        onMouseEnter={onMouseEnterHandler ?? undefined}
        onMouseLeave={onMouseLeaveHandler ?? undefined}
        className={`${styles.sidebarListItem} ${
          extraClassNames.sidebarListItem ?? ""
        } ${isCollapsed ? styles.isCollapsed : ""}`}
      >
        <SelectedTag
          to={navigateTo ?? "#"}
          {...(externalUrl
            ? {
                rel: "noopener noreferrer",
                target: "_blank",
                href: externalUrl,
              }
            : {})}
          ref={setReferenceElement}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={
            onItemClick
              ? () => {
                  onItemClick(sidebarItem);
                }
              : undefined
          }
          className={[
            styles.listItem,
            extraClassNames.listItem ?? "",
            isActive ? styles.active : "",
            isSubMenuShown ? styles.hover : "",
          ].join(" ")}
        >
          <div
            className={`${styles.listItemLeft} ${
              !icon ? extraClassNames.listItemLeft : ""
            }`}
          >
            {icon && (
              <div
                onClick={(e) => {
                  if (onIconClick) {
                    e.preventDefault();
                    e.stopPropagation();
                    onIconClick();
                  }
                }}
                className={`${styles.sidebarListItemIconContainer} ${
                  extraClassNames.sidebarListItemIconContainer ?? ""
                }`}
              >
                <FontAwesomeIcon
                  icon={icon}
                  className={`${extraClassNames.sidebarListItemIcon ?? ""}`}
                  color={extraClassNames.sidebarListItemIconColor}
                />
                {count !== undefined && count > 0 && (
                  <div className={styles.notificationIndicator} />
                )}
              </div>
            )}
            {htmlTitle ? (
              <div
                className={`${styles.itemTitle} ${
                  extraClassNames.sidebarListItemTitle ?? ""
                }`}
                dangerouslySetInnerHTML={{ __html: htmlTitle }}
              />
            ) : (
              <div
                title={title}
                className={`${styles.itemTitle} ${
                  extraClassNames.sidebarListItemTitle ?? ""
                }`}
              >
                {!isCollapsed && title}
              </div>
            )}
          </div>
          {rightContent && !isCollapsed && (
            <div
              className={`${styles.listItemRight} ${
                extraClassNames.sidebarRightIconContainer ?? ""
              }`}
            >
              {typeof rightContent === "number" ? (
                rightContent
              ) : (
                <FontAwesomeIcon
                  color={rightContentColor}
                  icon={rightContent}
                  className={extraClassNames.sidebarRightIconStyle}
                />
              )}
            </div>
          )}
          {sidebarItem.badgeProperties && !isCollapsed && (
            <span
              className={`${styles.badge} ${
                styles[sidebarItem.badgeProperties.type]
              }`}
            >
              {sidebarItem.badgeProperties.text}
            </span>
          )}
        </SelectedTag>
        {!subItems && isCollapsed && (
          <Tooltip
            popoverOffset={8}
            placement="right"
            referenceEl={referenceElement}
            isOpen={isTooltipOpen}
            tooltipText={title ?? ""}
          />
        )}
        {subItems && isSubMenuShown && (
          <Popover
            extraClassName={`${styles.subMenuPopup}`}
            referenceEl={referenceElement}
            placement="right-start"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            popoverOffset={8}
            showInPortal
          >
            {Array.isArray(subItems) ? (
              <ul>
                {subItems.map((item: TSidebarListItem) => (
                  <ListItemLink
                    key={item.id}
                    navigateTo={item.navigateTo ?? ""}
                    iconHasColor={item.iconHasColor ?? false}
                    icon={icon ? item.icon : undefined}
                    object={fromTSidebarListItem(item)}
                    extraClassNames={{
                      listItem: styles.listSubItem,
                      listItemContent: styles.listSubItemContent,
                    }}
                    openReferenceModal={openReferenceModal}
                    onMoreActionsOptionClick={onMouseLeave}
                  >
                    {item.badgeProperties ? (
                      <span
                        className={`${styles.badge} ${
                          styles[item.badgeProperties.type]
                        }`}
                      >
                        {item.badgeProperties.text}
                      </span>
                    ) : (
                      <></>
                    )}
                  </ListItemLink>
                ))}
              </ul>
            ) : (
              <div className={styles.emptySubItemsContainer}>{subItems}</div>
            )}
          </Popover>
        )}
        {Array.isArray(subItems) && isAnySubItemSelected && !isCollapsed && (
          <ul className={styles.subItemsContainer}>
            {subItems
              .filter((item) => !item.onlyVisibleWhenCollapsed)
              .map((subItem) => {
                return (
                  <SidebarItem
                    isCollapsed={isCollapsed} // ?
                    activeSidebarItem={activeSidebarItem}
                    key={subItem.title}
                    sidebarItem={subItem}
                    onItemClick={subItem.onItemClick}
                  />
                );
              })}
          </ul>
        )}
      </li>
      {referenceModal}
    </>
  );
};
