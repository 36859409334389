import { faLongArrowAltRight } from "@fortawesome/pro-solid-svg-icons";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { FindestButton, LoadingStatusIndicator } from "Components";
import { ForceDirectedGraphView } from "./ForceDirectedGraphView";
import { OverviewDetails } from "./OverviewDetails/OverviewDetails";
import { PackGraphView } from "./PackGraphView";
import { RecentCompanyActivity } from "./RecentCompanyActivity";
import { AuthContext } from "Providers";
import { ActivityControllerSingleton, LinkingControllerSingleton, UserOnboardingTasksControllerSingleton } from "Controllers";
import { TRecentSidebarActivityItemDTO, TTypeGraphNodeDTO, TUserOnboardingTaskDTO } from "Types";
import styles from "./universeOverview.module.scss";
import { GraphViewContainerTypeEnum, OnboardingTaskNameEnum, ToastTypeEnum } from "Enums";
import { useForceDirectedLinkGraph } from "Hooks";
import { OnboardingChecklist } from "./OnboardingChecklist/OnboardingChecklist";
import { RecentActivity } from "./RecentActivity";
import { ToastHelperSingleton } from "Helpers";

export const UniverseOverview: FC = () => {
  const [overviewDetailsPageType, setOverviewDetailsPageType] = useState<"Link" | "Pack" | null>(null);
  const [packGraphData, setPackGraphData] = useState<TTypeGraphNodeDTO[] | undefined>(undefined);
  const [recentActivity, setRecentActivity] = useState<TRecentSidebarActivityItemDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [onboardingChecklistItems, setOnboardingChecklistItems] = useState<TUserOnboardingTaskDTO[]>([]);

  // Context
  const { auth } = useContext(AuthContext);

  // Custom hooks
  const forceDirectedLinkGraph = useForceDirectedLinkGraph();

  const loadTypeGraph = useCallback(async () => {
    const allData = await LinkingControllerSingleton.getGraphByTypesAsync();
    setPackGraphData(allData);
  }, []);

  useEffect(() => {
    (async () => {
      const checklistItems = await UserOnboardingTasksControllerSingleton.getOnboardingChecklistItemsAsync();
      if (!checklistItems) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not retrieve onboarding checklist items",
        );
        setIsLoading(false);
        return;
      }
      const staticLoginChecklistItem: TUserOnboardingTaskDTO = {
        id: "0",
        text: "Log in to your Universe",
        name: OnboardingTaskNameEnum.Login,
        completedAt: new Date(),
      };
      setOnboardingChecklistItems([staticLoginChecklistItem, ...checklistItems]);
  
      if (checklistItems.length === 0) {
        const recentActiveItems = await ActivityControllerSingleton.getMyActivityAsync(10);
        if (recentActiveItems) {
          setRecentActivity(recentActiveItems);
        }
      }
      setIsLoading(false);
    })();
  }, [auth.userId]);

  useEffect(() => {
    loadTypeGraph();
  }, [loadTypeGraph]);

  const showDashboardScreen = () => {
    setOverviewDetailsPageType(null);
  };

  const showRelationsGraph = () => {
    setOverviewDetailsPageType("Link");
  };

  const showTypesBreakdownGraph = () => {
    setOverviewDetailsPageType("Pack");
  };

  if (overviewDetailsPageType && forceDirectedLinkGraph) {
    return (
      <OverviewDetails
        packData={packGraphData}
        data={forceDirectedLinkGraph}
        graphType={overviewDetailsPageType}
        goBackHandler={showDashboardScreen}
        setOverviewDetailsPageType={setOverviewDetailsPageType}
      />
    );
  }

  return (
    <div className={styles.universeOverview}>
      <div className={`${styles.box} ${styles.left}`}>
        {isLoading && <LoadingStatusIndicator shouldCenter size={32} status={1} />}
        {!isLoading && (
          onboardingChecklistItems.length > 1 ? (
            <OnboardingChecklist userEmail={auth.userEmail} initialOnboardingChecklistItems={onboardingChecklistItems} />
          ) : (
            <>
              <div className={styles.title}>Pick up where you left off…</div>
              <RecentActivity recentActivity={recentActivity} />
            </>
          )
        )}
      </div>
      <div className={styles.box}>
        <div className={styles.title}>Relations graph</div>
        <div className={styles.graphBoxContainer}>
          {forceDirectedLinkGraph ? (
            <>
              <ForceDirectedGraphView
                data={forceDirectedLinkGraph}
                searchKeyword=""
                selectedFilterOptions={[]}
                containerType={GraphViewContainerTypeEnum.UniverseOverview}
              />
              <div
                onClick={showRelationsGraph}
                className={styles.hoverContentContainer}
              >
                <FindestButton
                  rightIconName={faLongArrowAltRight}
                  extraClassName={styles.seeRelationsButton}
                  title="See relations graph"
                />
                <div
                  className={styles.hoverText}
                >{`Relations between entities and studies in ${auth.tenantName} Universe`}</div>
              </div>
            </>
          ) : (
            <LoadingStatusIndicator shouldCenter size={32} status={1} />
          )}
        </div>
      </div>
      <div className={`${styles.box} ${styles.left}`}>
        <div
          className={styles.title}
        >{`What's happening at ${auth.tenantName}?`}</div>
        <RecentCompanyActivity />
      </div>
      <div className={styles.box}>
        <div className={styles.title}>Page type breakdown</div>
        <div className={styles.graphBoxContainer}>
          {packGraphData ? (
            <>
              <PackGraphView
                data={packGraphData}
                extraClassName={styles.fitToView}
              />
              <div
                onClick={showTypesBreakdownGraph}
                className={styles.hoverContentContainer}
              >
                <FindestButton
                  rightIconName={faLongArrowAltRight}
                  extraClassName={styles.seeItemTypesButton}
                  title="See Page type breakdown"
                />
                <div
                  className={styles.hoverText}
                >{`Presentation of Entities & Studies in ${auth.tenantName} Universe - based on their types`}</div>
              </div>
            </>
          ) : (
            <LoadingStatusIndicator shouldCenter size={32} status={1} />
          )}
        </div>
      </div>
    </div>
  );
};
