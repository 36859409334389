export enum LogFeatureNameEnum {
    StructureGraph = "StructureGraph",
    Reporting = "Reporting",
    AdvancedSearch = "AdvancedSearch",
    TextSelectionMenu = "TextSelectionMenu",
    ConnectedQueries = "ConnectedQueries",
    QueriesPage = "QueriesPage",
    CreateQueryModal = "CreateQueryModal",
    GlobalSearchBar = "GlobalSearchBar",
    ActiveQueries = "ActiveQueries",
    FindPapersButton = "FindPapersButton",
}