// node_modules
import {
  faClockRotateLeft,
  faTrashCan,
  faLocationDot,
  faArrowRight,
  faXmark
} from "@fortawesome/pro-solid-svg-icons";
import {
  faBarsFilter,
  faSquareDashedCirclePlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useContext,
} from "react";
// Components
import {
  DraggableNewObject,
  FindestButton,
  ForceDirectedGraphViewMenu,
  ObjectItem,
} from "Components";
// Contexts
import { AuthContext, EditorContext, RecentActivityContext } from "Providers";
// Enums
import {
  EntityTypeEnum,
  LinksWindowTabsEnum,
  ObjectTypeEnum,
  StudyTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Helpers
import { ObjectTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Types
import {
  TCheckedLinks,
  TOption,
  TRecentSidebarActivityItemDTO,
  TUseDragAndDrop,
  fromTRecentSidebarActivityItemDTO,
} from "Types";
// Controllers
import { LinkingControllerSingleton } from "Controllers";
// Styles
import styles from "../linksWindow.module.scss";
import { useObjectNameChangeListener } from "Hooks";

type TLinksWindowMenuProps = {
  linksWindowSelectedTab: LinksWindowTabsEnum;
  useDragAndDropProps: TUseDragAndDrop;
  doShowReanchorButton: boolean;
  onReanchorClick: () => void;
  checkedLinks: TCheckedLinks[];
  canUserEdit: boolean;
  selectedFilterOptions: TOption<EntityTypeEnum | StudyTypeEnum>[];
  setSelectedFilterOptions: Dispatch<
    SetStateAction<TOption<EntityTypeEnum | StudyTypeEnum>[]>
  >;
  setSearchKeyword: Dispatch<SetStateAction<string>>;
  setCurrentPage: Dispatch<SetStateAction<boolean>>;
  currentPage: boolean;
  minimizeAllWindows?: (exceptId?: string) => void;
};

export const LinksWindowMenu: FC<TLinksWindowMenuProps> = ({
  linksWindowSelectedTab,
  useDragAndDropProps,
  doShowReanchorButton,
  onReanchorClick,
  checkedLinks,
  canUserEdit,
  setSearchKeyword,
  selectedFilterOptions,
  setSelectedFilterOptions,
  setCurrentPage,
  currentPage,
  minimizeAllWindows,
}: TLinksWindowMenuProps) => {
  // Context
  const { mySimpleRecentActivity, refreshMySimpleRecentActivityAsync } = useContext(RecentActivityContext);
  const { isUserExternal } = useContext(AuthContext);
  const { objectEdited } = useContext(EditorContext);

  // Refreshes recent pages on name change because they are ordered alphabetically
  // and the name influences the order
  useObjectNameChangeListener(() => {
    (async () => await refreshMySimpleRecentActivityAsync())();
  });

  // Memo
  const doShowRemoveLinksButton = useMemo((): boolean => {
    // return true if there are checked links
    return checkedLinks.length > 0;
  }, [checkedLinks]);

  const linksDisplayValue = useMemo((): string => {
    // get remove links
    return checkedLinks.length > 1 ? "links" : "link";
  }, [checkedLinks]);

  // Logic
  // on remove links click handler async
  const onRemoveLinksClickAsync = useCallback(async (): Promise<void> => {
    // go through each checked link
    for (const checkedLink of checkedLinks) {
      // delete link
      const isSuccess: boolean = await LinkingControllerSingleton.deleteAsync(
        checkedLink.from.id,
        checkedLink.to.id
      );

      // if not success
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          `Failed to delete link between ${checkedLink.from.name} and ${checkedLink.to.name}.`
        );
      }
    }
  }, [checkedLinks]);

  const renderRecentActivitySection = useCallback((): JSX.Element => {
    // if there is recent activity
    if (mySimpleRecentActivity.length > 0) {
      // return recent activity section
      return (
        <div className={styles.recentActivityContainer}>
          <h6 className={styles.linksWindowTitle}>
            <FontAwesomeIcon icon={faClockRotateLeft} />
            Recent active
          </h6>
          <div className={styles.recentActiveObjects}>
            {mySimpleRecentActivity
              .sort((a: TRecentSidebarActivityItemDTO, b: TRecentSidebarActivityItemDTO) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
              ).map((recentActivity) => (
                <ObjectItem
                  key={recentActivity.id}
                  objectItem={fromTRecentSidebarActivityItemDTO(recentActivity)}
                  isEditable={false}
                  isUserExternal={isUserExternal}
                  isNavigateToObjectItemDisabled
                  extraClassName={styles.recentActivityObjectItem}
                  useDragAndDropProps={
                    linksWindowSelectedTab !== LinksWindowTabsEnum.GraphView
                      ? useDragAndDropProps
                      : undefined
                  }
                  minimizeAllWindows={minimizeAllWindows}
                />
              ))}
          </div>
        </div>
      );
    }

    // return empty fragment
    return <></>;
  }, [
    mySimpleRecentActivity,
    isUserExternal,
    linksWindowSelectedTab,
    useDragAndDropProps,
    minimizeAllWindows,
  ]);

  const renderCurrentPageSection = useCallback((): JSX.Element => {
    if (objectEdited) {
      const rightIconName = () => {
        if (!doShowReanchorButton && linksWindowSelectedTab !== LinksWindowTabsEnum.GraphView) {
          return undefined;
        } else if (currentPage && linksWindowSelectedTab === LinksWindowTabsEnum.GraphView) {
          return faXmark;
        } else {
          return faArrowRight;
        }
      };

      return (
        <div
          className={`${styles.currentPageContent} ${
            (linksWindowSelectedTab === LinksWindowTabsEnum.TreeView ||
              linksWindowSelectedTab === LinksWindowTabsEnum.ListView) &&
            canUserEdit
              ? styles.fullWidth
              : ""
          }`}
        >
          <div
            className={styles.currentPageIcon}
            style={{
              backgroundColor: ObjectTypeHelperSingleton.getObjectTypeColor(
                objectEdited.objectType
              ),
            }}
          >
            <FontAwesomeIcon icon={faLocationDot} />
          </div>
          <FindestButton
            title="Current page"
            onClick={() => {
              if (linksWindowSelectedTab === LinksWindowTabsEnum.GraphView) {
                setCurrentPage(!currentPage);
              } else {
                onReanchorClick();
              }
            }}
            extraClassName={styles.currentPageButton}
            rightIconName={rightIconName()}
          />
        </div>
      );
    }

    return <></>;
  }, [
    objectEdited,
    canUserEdit,
    currentPage,
    setCurrentPage,
    linksWindowSelectedTab,
    onReanchorClick,
    doShowReanchorButton,
  ]);

  // Render
  return (
    <>
      {linksWindowSelectedTab === LinksWindowTabsEnum.GraphView && (
        <div className={styles.linksWindowContentMenu}>
          {renderCurrentPageSection()}
          <div className={styles.linksWindowContentMenuTop}>
            <h6 className={styles.linksWindowTitle}>
              <FontAwesomeIcon icon={faBarsFilter} />
              Filter the graph
            </h6>
            <ForceDirectedGraphViewMenu
              graphType={"Link"}
              selectedFilterOptions={selectedFilterOptions}
              setSelectedFilterOptions={setSelectedFilterOptions}
              setSearchKeyword={setSearchKeyword}
              textFilterPlaceholder="Filter on title"
              filtersTitle="Add filter"
              extraClassNames={{
                filtersContainer: styles.filtersContainer,
                filteredItems: styles.filteredItems,
                filteredItem: styles.filteredItem,
                textFilterContainer: styles.linksWindowTextFilterContainer,
                filtersParentContainer: styles.linksWindowOptionFilterContainer,
              }}
            />
          </div>
          {canUserEdit && renderRecentActivitySection()}
        </div>
      )}
      {(linksWindowSelectedTab === LinksWindowTabsEnum.TreeView ||
        linksWindowSelectedTab === LinksWindowTabsEnum.ListView) &&
        canUserEdit ? (
        <div className={styles.linksWindowContentMenu}>
          <div className={styles.linksWindowContentMenuTop}>
            {doShowRemoveLinksButton && (
              <>
                <h6
                  className={`${styles.linksWindowTitle} ${styles.smallMarginBottom}`}
                >
                  Selection
                </h6>
                <FindestButton
                  title={`Remove ${linksDisplayValue}`}
                  titleAttribute={`Remove the selected ${linksDisplayValue}`}
                  onClick={onRemoveLinksClickAsync}
                  leftIconName={faTrashCan}
                  extraClassName={styles.removeLinksButton}
                />
              </>
            )}
            {renderCurrentPageSection()}
            <h6 className={styles.linksWindowTitle}>
              <FontAwesomeIcon icon={faSquareDashedCirclePlus} />
              Add new objects
            </h6>
            <DraggableNewObject
              linksWindowSelectedTab={linksWindowSelectedTab}
              objectType={ObjectTypeEnum.Entity}
              useDragAndDropProps={useDragAndDropProps}
            />
            <DraggableNewObject
              linksWindowSelectedTab={linksWindowSelectedTab}
              objectType={ObjectTypeEnum.Study}
              useDragAndDropProps={useDragAndDropProps}
            />
          </div>
          {renderRecentActivitySection()}
        </div>
      ) : (
        (linksWindowSelectedTab === LinksWindowTabsEnum.TreeView || linksWindowSelectedTab === LinksWindowTabsEnum.ListView) && (
          <div className={`${styles.linksWindowContentMenu} ${styles.absolute}`}>
            {renderCurrentPageSection()}
          </div>
        )
      )}
    </>
  );
};
