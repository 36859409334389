// node_modules
import { Dispatch, FC, SetStateAction, useState } from "react";
// Enums
import { SearchPriorityEnum, ToastTypeEnum } from "Enums";
// Types
import { TEnvironmentVariableDTO } from "Types";
// Styles
import styles from "./environmentVariables.module.scss";
// Components
import { FindestTextBox } from "Components";
import { EnvironmentVariable } from "./EnvironmentVariable";
// Helpers
import { EnvironmentVariableHelperSingleton, ToastHelperSingleton } from "Helpers";
// Controllers
import { EnvironmentVariableControllerSingleton } from "Controllers";
// Interfaces
import { IQueryDTO } from "Interfaces";
// Icons
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TEnvironmentVariablesProps = {
    query: IQueryDTO,
    setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>,
    isSearchTermPriorityDropdown: boolean
}

export const EnvironmentVariables: FC<TEnvironmentVariablesProps> = ({ query, setQuery, isSearchTermPriorityDropdown }: TEnvironmentVariablesProps) => {
    // State
    const [environmentInput, setEnvironmentInput] = useState<string>("");
    const [emptyInput, setEmptyInput] = useState<boolean>(false);
    
    // Logic
    const isEnvironmentVariableValueValid = (environmentVariableValue: string, currentQuery: IQueryDTO): boolean => {
        // trim environmentVariableValue
        environmentVariableValue = environmentVariableValue.trim();
        
        // safety-checks
        if (!environmentVariableValue) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "A environment variable needs a value.");
            return false;
        }

        // check if current query already has environment variable with this value
        const environmentVariable: TEnvironmentVariableDTO | undefined = currentQuery.environmentVariables.find((envVariable: TEnvironmentVariableDTO) => envVariable.environment === environmentVariableValue);

        // if environment variable already exists, show error
        if (environmentVariable) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Environment variable already exists.");
            return false;
        }

        // return true
        return true;
    };


    const addEnvironmentVariableAsync = async (environmentVariable: string, currentQuery: IQueryDTO): Promise<void> => {
        // safety-checks
        if (!isEnvironmentVariableValueValid(environmentVariable, currentQuery)) {
            return;
        }

        // create environment variable and update query
        await EnvironmentVariableHelperSingleton.addEnvironmentVariableAsync(environmentVariable, currentQuery, setQuery);

        setEmptyInput(!emptyInput);
        setEnvironmentInput("");
    };

    const updateEnvironmentVariableValueAsync = async (environmentVariableId: number, updatedEnvironmentVariableValue: string,
            currentQuery: IQueryDTO,
            currentIsEnvironmentVariableValueValid: (environmentVariableValue: string, currentQuery: IQueryDTO) => boolean): Promise<void> => {
        // get related environment variable from query
        const environmentVariableToUpdate = currentQuery.environmentVariables.find((environmentVariable: TEnvironmentVariableDTO) => environmentVariable.id === environmentVariableId);

        // safety-checks
        if (!environmentVariableToUpdate) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not find environment variable to update.");
            return;
        }
        
        // safety-checks
        if (!currentIsEnvironmentVariableValueValid(updatedEnvironmentVariableValue, currentQuery)) {
            return;
        }

        // trim newEnvironmentVariableValue
        updatedEnvironmentVariableValue = updatedEnvironmentVariableValue.trim();

        // update environment variable field
        environmentVariableToUpdate.environment = updatedEnvironmentVariableValue;

        // update environment variable
        const updatedEnvironmentVariable: TEnvironmentVariableDTO | undefined = await EnvironmentVariableControllerSingleton
            .updateAsync(currentQuery.guid, environmentVariableToUpdate);

        // safety-checks
        if (!updatedEnvironmentVariable) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not update environment variable.");
            return;
        }

        setQuery((prevQuery: IQueryDTO | undefined) => {
            // safety-checks
            if (!prevQuery) {
                return prevQuery;
            }

            // update environment variable in query
            // return query
            return {
                ...prevQuery,
                environmentVariables: [...prevQuery.environmentVariables.map((environmentVariable) => {
                    if (environmentVariable.id === environmentVariableToUpdate.id) {
                        return { ...environmentVariableToUpdate };
                    } else {
                        return environmentVariable;
                    }
                })]
            };
        });
    };

    const updateEnvironmentVariableSearchPriorityAsync = async (environmentVariableId: number, environmentVariableUpdatedSearchPriority: SearchPriorityEnum,
                currentQuery: IQueryDTO): Promise<void> => {
        // get related environment variable from query
        const environmentVariableToUpdate = currentQuery.environmentVariables.find((environmentVariable: TEnvironmentVariableDTO) => environmentVariable.id === environmentVariableId);

        // safety-checks
        if (!environmentVariableToUpdate) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not find environment variable to update.");
            return;
        }

        // update environment variable field
        environmentVariableToUpdate.searchPriority = environmentVariableUpdatedSearchPriority;
        
        // update environment variable
        const updatedEnvironmentVariable: TEnvironmentVariableDTO | undefined = await EnvironmentVariableControllerSingleton
            .updateAsync(currentQuery.guid, environmentVariableToUpdate);

        // safety-checks
        if (!updatedEnvironmentVariable) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not update environment variable.");
            return;
        }

        setQuery((prevQuery: IQueryDTO | undefined) => {
            // safety-checks
            if (!prevQuery) {
                return prevQuery;
            }

            // return query
            // update environment variable in query
            return {
                ...prevQuery,
                environmentVariables: [...prevQuery.environmentVariables.map((environmentVariable) => {
                    if (environmentVariable.id === environmentVariableToUpdate.id) {
                        return { ...environmentVariableToUpdate };
                    } else {
                        return environmentVariable;
                    }
                })]
            };
        });
    };

    const deleteEnvironmentVariableAsync = async (environmentVariableId: number, currentQuery: IQueryDTO): Promise<void> => {
        // get related environment variable from query
        const environmentVariableToDelete = currentQuery.environmentVariables.find((environmentVariable: TEnvironmentVariableDTO) => environmentVariable.id === environmentVariableId);

        // safety-checks
        if (!environmentVariableToDelete) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not find environment variable to delete.");
            return;
        }

        // delete environment variable
        const isSuccess: boolean = await EnvironmentVariableControllerSingleton
            .deleteAsync(currentQuery.guid, environmentVariableToDelete.id);

        // safety-checks
        if (!isSuccess) {
            ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not delete environment variable.");
            return;
        }

        setQuery((prevQuery: IQueryDTO | undefined) => {
            // safety-checks
            if (!prevQuery) {
                return prevQuery;
            }
            
            // return query
            // delete environment variable from query
            return {
                ...prevQuery,
                environmentVariables: [...prevQuery.environmentVariables.filter((environmentVariable) => environmentVariable.id !== environmentVariableId)]
            };
        });
    };

    const onEnterAsync = async (text: string, inputRef: HTMLInputElement, currentQuery: IQueryDTO, 
            currentIsEnvironmentVariableValueValid: (environmentVariableValue: string, currentQuery: IQueryDTO) => boolean,
            addEnvironmentVariableHandlerAsync: (environmentVariable: string, currentQuery: IQueryDTO) => Promise<void>): Promise<void> => {
        // safety-checks
        if (!currentIsEnvironmentVariableValueValid(text, currentQuery)) {
            return;
        }

        // trim text
        text = text.trim();

        // add environment variable
        await addEnvironmentVariableHandlerAsync(text, currentQuery);

        // empty add environment variable input
        inputRef.value = "";
    };

    const getEnvironmentVariableHitsCount = (environmentVariable: TEnvironmentVariableDTO): number => {
        // safety-checks
        if (!query.searchTermHitsCounts || !query.searchTermHitsCounts.hitsCountsPerSearchTermId) {
            // return 0
            return 0;
        }

        // get action object hits count if exists otherwise return 0
        return query.searchTermHitsCounts.hitsCountsPerSearchTermId[`env${environmentVariable.id}`] ? query.searchTermHitsCounts.hitsCountsPerSearchTermId[`env${environmentVariable.id}`] : 0;
    };

    return (
        <div className={styles.actionObjectsContainer}>
            <p className={styles.title}>Keywords</p>
            <div className={styles.addKeywordContainer}>
                <p>Add keyword</p>
                <FindestTextBox
                    emptyInput={emptyInput}
                    extraClassName={styles.inputField}
                    onChange={(text: string) => { setEnvironmentInput(text); }}
                    onEnter={async (text: string, inputRef: HTMLInputElement) => { await onEnterAsync(text, inputRef, query, isEnvironmentVariableValueValid, addEnvironmentVariableAsync); }} />
                <button type="button" onClick={async () => {await addEnvironmentVariableAsync(environmentInput, query);}} title="Add keyword">
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
            {query.environmentVariables.map((environmentVariable: TEnvironmentVariableDTO) => {
                return <EnvironmentVariable
                    key={environmentVariable.id}
                    environmentVariable={environmentVariable}
                    updateEnvironmentVariableSearchPriorityAsync={async (environmentVariableId: number, environmentVariableUpdatedSearchPriority: SearchPriorityEnum) => { updateEnvironmentVariableSearchPriorityAsync(environmentVariableId, environmentVariableUpdatedSearchPriority, query); }}
                    deleteEnvironmentVariableAsync={async (environmentVariableId: number) => { deleteEnvironmentVariableAsync(environmentVariableId, query); }}
                    updateEnvironmentVariableValueAsync={async (environmentVariableId: number, updatedEnvironmentVariableValue: string) => { updateEnvironmentVariableValueAsync(environmentVariableId, updatedEnvironmentVariableValue, query, isEnvironmentVariableValueValid) ;}}
                    query={query}
                    setQuery={setQuery} 
                    hitsCount={getEnvironmentVariableHitsCount(environmentVariable)} 
                    isSearchTermPriorityDropdown={isSearchTermPriorityDropdown} />;
            })}
        </div>
    );
};
