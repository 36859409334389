import { FC, useContext, useEffect, useRef, useState } from "react";
import { CreateNewModal } from "./CreateNewModal";
import { OnboardingContext } from "Providers";
import { FindestButton } from "Components";
import { OnboardingConstants } from "Constants";

export const CreateNewButton: FC = () => {
  const { registerSpotlightElementRefs } = useContext(OnboardingContext);

  const elementRef = useRef<HTMLDivElement | null>(null);

  const [isCreateNewModalOpen, setIsCreateNewModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (elementRef.current) {
      registerSpotlightElementRefs({
        [OnboardingConstants.CREATE_NEW_BUTTON]: elementRef.current,
      });
    }
  }, [registerSpotlightElementRefs]);

  const openCreateNewModal = () => {
    setIsCreateNewModalOpen(true);
  };

  return (
    <div>
      <CreateNewModal
        isOpen={isCreateNewModalOpen}
        closeModal={() => setIsCreateNewModalOpen(false)} />
      <div ref={elementRef} style={{ borderRadius: "8px" }}>
        <FindestButton
          buttonType="secondary"
          onClick={openCreateNewModal}
          title="Create new"
        />
      </div>
    </div>
  );
};
