import { FC, useContext } from "react";
import styles from "./emptyStates.module.scss";
import { FindestButton } from "Components";
import { OnboardingConstants } from "Constants";
import { OnboardingContext } from "Providers";
import { ObjectTypeEnum } from "Enums";
import { ObjectTypeHelperSingleton } from "Helpers";

export type TEmptyObjectsProps = {
  route: string;
  objectType: ObjectTypeEnum;
  description: string;
};

export const EmptyObjects: FC<TEmptyObjectsProps> = ({ route, objectType, description }: TEmptyObjectsProps) => {
  const { spotlightElement } = useContext(OnboardingContext);

  const flow = objectType === ObjectTypeEnum.Entity ? OnboardingConstants.CREATE_ENTITY_FLOW : OnboardingConstants.CREATE_STUDY_FLOW;

  const onCreateNewButtonClick = () => {
    spotlightElement(flow, 0);
  };

  return (
    <div className={styles.emptyStateContainer}>
      <h3 className={styles.header}>{route}</h3>
      <div className={styles.content}>
        <div className={styles.contentBox}>
          <h3 className={styles.descriptionHeader}>{route} page is currently empty.</h3>
          <p className={styles.paragraph}>{description}</p>
          <div className={styles.buttonsContainer}>
            <FindestButton buttonType="secondary" title={`Create a new ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(objectType).toLowerCase()}`} onClick={onCreateNewButtonClick} />
          </div>
        </div>
      </div>
    </div>
  );
};
