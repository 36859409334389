// React
import { FC } from "react";
// Types
import { TIdNameTypeObjectType } from "Types";
// Enums
import { ObjectTypeEnum } from "Enums";
// Components
import { ListObjectItem } from "Components";
// Styles
import { ObjectTypeHelperSingleton } from "Helpers";
import styles from "./globalSearchBar.module.scss";

type TGroupedSearchResultsListProps = {
    searchResults: TIdNameTypeObjectType[],
    onObjectClick: (id: string, objectType: ObjectTypeEnum, objectName: string) => void,
    keyName: string,
    headerName: string,
    extraClassname?: string,
    openReferenceModal?: (objectId: string, objectType: ObjectTypeEnum) => void,
    navigateCallback?: () => void,
    moreActionsDropdownPopoverDataIdentifier?: string,
    moreActionsDropdownOnCloseCallback?: () => void
};

export const GroupedSearchResultsList: FC<TGroupedSearchResultsListProps> = ({searchResults, keyName,
        headerName, onObjectClick, extraClassname, openReferenceModal, navigateCallback, moreActionsDropdownPopoverDataIdentifier,
        moreActionsDropdownOnCloseCallback }) => {

    return (
        <div className={styles.searchResultsList}>
            <h6>{headerName}</h6>
            {searchResults.map((searchResult) => {
                return (
                    <ListObjectItem 
                        key={`${keyName}-${searchResult.id}`}
                        onClick={() => onObjectClick(searchResult.id, searchResult.objectType, searchResult.name ?? searchResult.title ?? "")}
                        iconHasColor={true}
                        object={searchResult}
                        subItemType={ObjectTypeHelperSingleton.getObjectTypeFullDisplayName(searchResult.objectType, searchResult.type, searchResult.customTypeName)}
                        extraClassName={extraClassname}
                        openReferenceModal={openReferenceModal}
                        moreActionsDropdownPopoverDataIdentifier={moreActionsDropdownPopoverDataIdentifier}
                        moreActionsDropdownOnCloseCallback={moreActionsDropdownOnCloseCallback}
                        navigateCallback={navigateCallback}
                    />
                );
            })}
        </div>
    );
};