// node_modules
import { Dispatch, FC, SetStateAction, useState, useEffect } from "react";
import {
  faListDropdown,
  faEllipsisStroke,
} from "@fortawesome/pro-solid-svg-icons";
// Components
import { QueryFilters, RadioButton, TextArea, ToggleButton } from "Components";
import { ActionObjects } from "./ActionObjects/ActionObjects";
import { EnvironmentVariables } from "./EnvironmentVariables/EnvironmentVariables";
import { SpecificationUnits } from "./SpecificationUnits/SpecificationUnits";
// Styles
import styles from "./queryFields.module.scss";
// Interfaces
import { IQueryDTO } from "Interfaces";
// Enums
import { SearchQueryTypeEnum, QueryPrioritySelectionStyleEnum } from "Enums";
// Constants
import { FeatureToggleConstants, QueryConstants } from "Constants";
// Helpers
import { QueryPrioritySelectionStyleHelperSingleton } from "Helpers";
// Hooks
import { useNaturalLanguageQuery } from "Hooks";

type TQueryFieldsProps = {
  query: IQueryDTO;
  setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>;
  searchQueryType: SearchQueryTypeEnum;
  onSearchQueryTypeUpdate: (searchQueryType: SearchQueryTypeEnum) => void;
  updateNaturalLanguageQuery: (naturalLanguageQuery: string) => void;
  tab: string;
  isSearching: boolean;
};

export const QueryFields: FC<TQueryFieldsProps> = ({
  query,
  setQuery,
  searchQueryType,
  onSearchQueryTypeUpdate,
  updateNaturalLanguageQuery,
  tab,
  isSearching
}) => {
  // State
  const [isSearchTermPriorityDropdown, setIsSearchTermPriorityDropdown] =
    useState<boolean>(true);
  const { naturalLanguageQuery, onNaturalLanguageQueryUpdate } =
    useNaturalLanguageQuery(query, updateNaturalLanguageQuery);

  // UseEffect
  useEffect(() => {
    const getSelectionStyle =
      QueryPrioritySelectionStyleHelperSingleton.getActivatedSelectionStyle();
    if (getSelectionStyle === "PriorityDropdown") {
      setIsSearchTermPriorityDropdown(true);
    } else {
      setIsSearchTermPriorityDropdown(false);
    }
  }, []);

  const onClickToggleButton = (isLeftToggleActive: boolean) => {
    setIsSearchTermPriorityDropdown(isLeftToggleActive);

    // Save search term priority selection style to local storage
    if (isLeftToggleActive) {
      QueryPrioritySelectionStyleHelperSingleton.saveActivatedSelectionStyle(
        QueryPrioritySelectionStyleEnum.PriorityDropdown
      );
    } else {
      QueryPrioritySelectionStyleHelperSingleton.saveActivatedSelectionStyle(
        QueryPrioritySelectionStyleEnum.PriorityButtons
      );
    }
  };

  return (
    <div className={`${styles.queryOptions} ${isSearching ? styles.disabled : ""}`}>
      <div className={styles.queryFieldsContainer}>
        <div className={styles.queryFieldsTitle}>
          <RadioButton
            checked={
              searchQueryType === SearchQueryTypeEnum.USEOnScience ||
              searchQueryType === SearchQueryTypeEnum.USEOnPatents
            }
            onChange={() => {
              tab === QueryConstants.SCIENCE_DOCUMENTS
                ? onSearchQueryTypeUpdate(SearchQueryTypeEnum.USEOnScience)
                : onSearchQueryTypeUpdate(SearchQueryTypeEnum.USEOnPatents);
            }}
            value={SearchQueryTypeEnum.USEOnScience}
          />
          <p className={styles.querySearchTermsTitle}>Natural language query</p>
        </div>
        <div
          className={`${styles.queryFieldOptions} ${
            searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles ||
            searchQueryType === SearchQueryTypeEnum.UniversePatents
              ? styles.disabled
              : ""
          }`}
        >
          <TextArea
            placeholder={
              "I am looking for a process to extract salt from seawater"
            }
            value={naturalLanguageQuery}
            onChange={onNaturalLanguageQueryUpdate}
          />
          <div className={styles.queryFiltersContainer}>
            <QueryFilters
              query={query}
              setQuery={setQuery}
              searchQueryType={searchQueryType}
            />
          </div>
        </div>
      </div>
      <div className={styles.queryFieldsContainer}>
        <div className={styles.queryFieldsTitle}>
          <RadioButton
            checked={
              searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles ||
              searchQueryType === SearchQueryTypeEnum.UniversePatents
            }
            onChange={() => {
              tab === QueryConstants.SCIENCE_DOCUMENTS
                ? onSearchQueryTypeUpdate(
                    SearchQueryTypeEnum.UniverseScienceArticles
                  )
                : onSearchQueryTypeUpdate(SearchQueryTypeEnum.UniversePatents);
            }}
            value={SearchQueryTypeEnum.USEOnScience}
          />
          <p className={styles.querySearchTermsTitle}>Advanced settings</p>
        </div>
        <div
          className={`${styles.queryFieldOptions} ${
            searchQueryType === SearchQueryTypeEnum.USEOnScience ||
            searchQueryType === SearchQueryTypeEnum.USEOnPatents
              ? styles.displayNone
              : ""
          }`}
        >
          <div className={styles.querySearchTermsContainer}>
            <div className={styles.querySearchTerms}>
              <div className={styles.actionObjectAndSpecsContainer}>
                <ActionObjects
                  query={query}
                  setQuery={setQuery}
                  isSearchTermPriorityDropdown={isSearchTermPriorityDropdown}
                />
              </div>
              <div className={styles.keywordsContainer}>
                <EnvironmentVariables
                  query={query}
                  setQuery={setQuery}
                  isSearchTermPriorityDropdown={isSearchTermPriorityDropdown}
                />
              </div>
            </div>
            <ToggleButton
              toggleIconLeft={faListDropdown}
              toggleTitleAttributeLeft="Searchterm priority dropdown"
              toggleIconRight={faEllipsisStroke}
              toggleTitleAttributeRight="Searchterm priority buttons"
              leftToggleActive={isSearchTermPriorityDropdown}
              onClickToggleButton={onClickToggleButton}
              extraClassName={styles.searchPriorityStyleSelection}
            />
          </div>
          <div className={styles.queryFiltersContainer}>
            <p className={styles.querySearchTermsTitle}>Filters</p>
            <div className={styles.querySearchFilters}>
              {FeatureToggleConstants.UnitSearching && (
                <div>
                  <SpecificationUnits
                    query={query}
                    setQuery={setQuery}
                    isSearchTermPriorityDropdown={isSearchTermPriorityDropdown}
                  />
                </div>
              )}
              <div>
                <QueryFilters
                  query={query}
                  setQuery={setQuery}
                  searchQueryType={searchQueryType}
                  advancedSearch={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
