// React
import { FC, useContext } from "react";
// Components
import {
  ErrorHandling,
  MaintenanceStatusBanner,
  ObjectTopbar,
  RightSidebar,
  Sidebar,
  Topbar,
  WebsocketToastReceiver,
  Spotlight,
} from "Components";
// Contexts
import {
  ElementVisibilityContext,
  OnboardingContext,
  PinnedProvider,
  QueryProvider,
} from "Providers";
// Styles
import "Styles/markdownCustomBlocks.scss";
import styles from "./mainPage.module.scss";
// Constants
import { ErrorConstants } from "Constants";

type TMainPageContentProps = {
  RenderedComponent: React.FC;
};

export const MainPageContent: FC<TMainPageContentProps> = ({
  RenderedComponent,
}) => {
  const {
    isLeftSidebarVisible,
    isRightSidebarVisible,
    isTopbarVisible,
    isObjectEntityOrStudy,
  } = useContext(ElementVisibilityContext);

  const { spotlightDetails, clearSpotlight } = useContext(OnboardingContext);

  return (
    <WebsocketToastReceiver>
      <MaintenanceStatusBanner />
      {isLeftSidebarVisible && <Sidebar />}
      <div
        className={`${styles.mainContentContainer} ${
          !isLeftSidebarVisible ? styles.fullWidth : ""
        }`}
      >
        <ErrorHandling errorMessage={ErrorConstants.DEFAULT_ERROR_MESSAGE}>
          <QueryProvider>
            <PinnedProvider>
              {isTopbarVisible && <Topbar />}
              {isObjectEntityOrStudy && <ObjectTopbar />}
              <div className={styles.contentContainer}>
                <RenderedComponent />
                {isRightSidebarVisible && <RightSidebar />}
              </div>
            </PinnedProvider>
          </QueryProvider>
        </ErrorHandling>
      </div>
      {!!spotlightDetails && <Spotlight {...spotlightDetails} clearSpotlight={clearSpotlight}/>}
    </WebsocketToastReceiver>
  );
};
