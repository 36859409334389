// Enums
import { SavedDocumentTypeEnum } from "Enums";
// Types
import { THeaderContentDTO, TLinkedCountsDTO } from "Types";
// Interfaces
import { IAuthorships, IConnectedObjects, IDocumentDTO, IDocumentSearchResult, IHighlights, IImages, IIsOpenAccess, ISavedDocumentDTO, ISearchInformation } from "Interfaces";

export interface IDocumentDetails extends IConnectedObjects, ISearchInformation, IAuthorships, IIsOpenAccess, IImages, IHighlights {
    id: string,
    title: string,
    documentType: SavedDocumentTypeEnum,
    url: string,
    fullUrl: string,
    linkedCounts?: TLinkedCountsDTO,
    mainContents: THeaderContentDTO[] | undefined | null
    createdByUsername?: string,
    isAlreadyRead: boolean,
    dateAdded?: Date
}

export const fromIDocumentDTO = (savedDocument: IDocumentDTO): IDocumentDetails => {
    const mainContents: THeaderContentDTO[] = [];
    if (savedDocument.abstract) {
        mainContents.push({
            header: "Abstract",
            content: savedDocument.abstract
        });
    }
    if (savedDocument.claims) {
        mainContents.push({
            header: "Claims",
            content: savedDocument.claims
        });
    }
    return {
        id: savedDocument.id,
        documentType: savedDocument.savedDocumentType,
        title: savedDocument.title,
        url: savedDocument.fullUrl ?? savedDocument.url ?? "",
        fullUrl: savedDocument.fullUrl ?? savedDocument.url ?? "",
        mainContents,
        searchInformation: savedDocument.documentMetadata,
        authorships: savedDocument.authorships,
        connectedObjects: savedDocument.connectedPages ?? [],
        isOpenAccess: savedDocument.isOpenAccess,
        highlights: savedDocument.highlights,
        images: savedDocument.images,
        isAlreadyRead: false,
        linkedCounts: savedDocument.linkedObjectCounts,
        createdByUsername: savedDocument.createdByUsername,
        dateAdded: savedDocument.dateAdded
    };
};

export const fromISavedDocumentDTO = (savedDocument: ISavedDocumentDTO): IDocumentDetails => {
    const mainContents: THeaderContentDTO[] = [];
    if (savedDocument.abstract) {
        mainContents.push({
            header: "Abstract",
            content: savedDocument.abstract
        });
    }
    if (savedDocument.claims) {
        mainContents.push({
            header: "Claims",
            content: savedDocument.claims
        });
    }
    return {
        id: savedDocument.id,
        documentType: savedDocument.savedDocumentType,
        title: savedDocument.title,
        url: savedDocument.fullUrl ?? savedDocument.url ?? "",
        fullUrl: savedDocument.fullUrl ? savedDocument.fullUrl : "",
        mainContents,
        searchInformation: savedDocument.searchInformation,
        authorships: savedDocument.authorships,
        connectedObjects: savedDocument.connectedObjects,
        isOpenAccess: savedDocument.isOpenAccess,
        highlights: savedDocument.highlights,
        images: savedDocument.images,
        isAlreadyRead: false,
        linkedCounts: savedDocument.linkedCounts,
        createdByUsername: savedDocument.createdByUsername,
        dateAdded: savedDocument.dateAdded
    };
};


export const fromIDocumentSearchResult = (documentSearchResult: IDocumentSearchResult): IDocumentDetails => {
    return {
        id: documentSearchResult.documentId,
        documentType: documentSearchResult.documentType,
        title: documentSearchResult.title,
        url: documentSearchResult.url,
        fullUrl: "",
        mainContents: documentSearchResult.mainContents,
        searchInformation: documentSearchResult.searchInformation,
        authorships: documentSearchResult.authorships,
        connectedObjects: documentSearchResult.connectedObjects,
        isOpenAccess: documentSearchResult.isOpenAccess,
        highlights: documentSearchResult.highlights,
        images: documentSearchResult.images,
        isAlreadyRead: documentSearchResult.isAlreadyRead
    };
};