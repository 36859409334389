// Enums
import { ObjectTypeEnum } from "Enums";
// Types
import { TIdNameTypeObjectType } from "Types";

export type TLinkGraphNodeDTO = {
    id: string,
    name: string,
    type: string,
    customTypeName?: string,
    objectType: ObjectTypeEnum,
    dateAdded: Date,
    lowerLevelNodes: TLinkGraphNodeDTO[],
    otherUpperLevelNodes: TLinkGraphNodeDTO[]
}


export const fromTIdNameTypeObjectType = (idNameTypeObjectType: TIdNameTypeObjectType): TLinkGraphNodeDTO => {
    return {
        id: idNameTypeObjectType.id,
        name: idNameTypeObjectType.name ?? "",
        type: idNameTypeObjectType.type,
        customTypeName: idNameTypeObjectType.customTypeName,
        objectType: idNameTypeObjectType.objectType,
        dateAdded: new Date(),
        lowerLevelNodes: [],
        otherUpperLevelNodes: []
    };
};
