import { FindestButton } from "Components";
import { QueryResultViewTypeEnum, SearchQueryTypeEnum } from "Enums";
import { Dispatch, FC, SetStateAction } from "react";
import styles from "./executeAQueryFirst.module.scss";

interface IExecuteAQueryFirstProps {
  queryResultView: QueryResultViewTypeEnum;
  type: SearchQueryTypeEnum;
  onSearchQueryTypeUpdate: (newSearchQueryType: SearchQueryTypeEnum) => void;
  setIsQueryShown: Dispatch<SetStateAction<boolean>>;
}

export const ExecuteAQueryFirst: FC<IExecuteAQueryFirstProps> = ({
  queryResultView,
  type,
  onSearchQueryTypeUpdate,
  setIsQueryShown,
}: IExecuteAQueryFirstProps) => {
  const onExecuteQueryClick = (): void => {
    if (queryResultView === QueryResultViewTypeEnum.TechnologyResult) {
      onSearchQueryTypeUpdate(SearchQueryTypeEnum.UniverseTechnologies);
    } else {
      onSearchQueryTypeUpdate(type || SearchQueryTypeEnum.USEOnScience);
    }

    setIsQueryShown(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.explanationContainer}>
        <h3 className={styles.header}>Execute a query first</h3>
        <p className={styles.explanation}>
          Before we can show you any results here, you need to execute a query.
        </p>
        {[
          QueryResultViewTypeEnum.AffiliationResult,
          QueryResultViewTypeEnum.GroupedDocumentResult,
          QueryResultViewTypeEnum.DocumentResult,
        ].includes(queryResultView) && (
          <p className={styles.explanation}>
            You can do this by clicking on the Query tab in the top left and
            execute a
            {type === SearchQueryTypeEnum.USEOnPatents ||
            type === SearchQueryTypeEnum.UniversePatents ? (
              <b> Patent documents search </b>
            ) : (
              <b> Science documents search </b>
            )}
            query using keywords.
          </p>
        )}
        {queryResultView === QueryResultViewTypeEnum.TechnologyResult && (
          <>
            <p className={styles.explanation}>
              You can do this in two ways when clicking on the Query tab in the
              top left:
            </p>
            <p className={styles.explanation}>
              1. Directly generate a <b>Technology overview</b> using natural
              language.
            </p>
            <p className={styles.explanation}>
              2. First perform a <b>Science or Patent documents search</b> using
              keywords and, based on the first 100 results, generate a
              Technology Overview.
            </p>
          </>
        )}
        <FindestButton
          onClick={onExecuteQueryClick}
          buttonType="primary"
          title="Execute query"
          extraClassName={styles.excuteQueryButton}
        />
      </div>
    </div>
  );
};
