import { AxiosHelperSingleton } from "./AxiosHelper";

export class URLHelper {
  public stripProtocol = (inputUrl: string) => {
    return inputUrl.replace(/^https?:\/\//, "");
  };

  public isValidUrl = (url?: string | null, addServerBaseURL = false) => {
    const validUrl: string = url?.trim() || "";
    try {
      new URL(validUrl, addServerBaseURL ? AxiosHelperSingleton.getServerBaseURL() : undefined);
      return validUrl !== "";
    } catch (error) {
      return false;
    }
  };
}

export const URLHelperSingleton = new URLHelper();
