// Constants
import { WebsocketFunctionNames } from "Constants";
// Enums
import { AskIgorMenuItemEnum } from "Enums";
// Types
import { TLogEventName } from "Types";

export class AskIgorMenuItemHelper {
  public askIgorMenuItemEnumToDisplayString(
    askIgorMenuItem: AskIgorMenuItemEnum
  ): string {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.QuestionAndAnswer:
        return "Question & Answer";
      case AskIgorMenuItemEnum.GeneralDescription:
      case AskIgorMenuItemEnum.GeneralDescriptionUsingLinks:
      case AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge:
        return "General description";
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return "Summary";
      case AskIgorMenuItemEnum.Table:
      case AskIgorMenuItemEnum.InformationExtraction:
        return "Extract detail information";
      case AskIgorMenuItemEnum.WriteSection:
        return "Section";
      case AskIgorMenuItemEnum.GenerateReport:
        return "Standard report";
      default:
        return "";
    }
  }

  public askIgorMenuItemEnumToDisplayDescription(
    askIgorMenuItem: AskIgorMenuItemEnum
  ): string {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.GeneralDescription:
      case AskIgorMenuItemEnum.GeneralDescriptionUsingLinks:
      case AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge:
        return "Either based on general knowledge or the linked sources.";
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return "Based on the linked sources.";
      case AskIgorMenuItemEnum.Table:
      case AskIgorMenuItemEnum.InformationExtraction:
        return "I can also extract specific data from the linked sources.";
      case AskIgorMenuItemEnum.WriteSection:
        return "Give me a title and I will write the section.";
      case AskIgorMenuItemEnum.GenerateReport:
        return "Introduction, Main developments, Findings, Pros and cons, Conclusion, Next steps";
      default:
        return "";
    }
  }

  public getRelatedWebsocketFunctionName(
    askIgorMenuItem: AskIgorMenuItemEnum
  ): string {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.QuestionAndAnswer:
        return WebsocketFunctionNames.StartQuestionAndAnswerCompletion;
      case AskIgorMenuItemEnum.GeneralDescriptionUsingLinks:
        return WebsocketFunctionNames.StartDescriptionCompletion;
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return WebsocketFunctionNames.StartExecutiveSummaryCompletion;
      case AskIgorMenuItemEnum.WriteSection:
        return WebsocketFunctionNames.StartWriteSectionCompletion;
      case AskIgorMenuItemEnum.Table:
        return WebsocketFunctionNames.StartTableCompletion;
      case AskIgorMenuItemEnum.InformationExtraction:
        return WebsocketFunctionNames.StartInformationExtractionCompletion;
      default:
        return "";
    }
  }

  public getDoRequireDocumentsOrHighlights(
    askIgorMenuItem: AskIgorMenuItemEnum
  ): boolean {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.QuestionAndAnswer:
      case AskIgorMenuItemEnum.GeneralDescriptionUsingLinks:
      case AskIgorMenuItemEnum.ExecutiveSummary:
      case AskIgorMenuItemEnum.Table:
      case AskIgorMenuItemEnum.WriteSection:
      case AskIgorMenuItemEnum.GenerateReport:
      case AskIgorMenuItemEnum.InformationExtraction:
        return true;
      default:
        return false;
    }
  }

  public getRelatedStartLogEventName(
    askIgorMenuItem: AskIgorMenuItemEnum
  ): TLogEventName {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.QuestionAndAnswer:
        return "Start-Gpt-QuestionAndAnswer";
      case AskIgorMenuItemEnum.GeneralDescriptionUsingLinks:
        return "Start-Gpt-GeneralDescriptionUsingLinks";
      case AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge:
        return "Start-Gpt-GeneralDescriptionUsingGeneralKnowledge";
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return "Start-Gpt-ExecutiveSummary";
      case AskIgorMenuItemEnum.WriteSection:
        return "Start-Gpt-WriteSection";
      case AskIgorMenuItemEnum.GenerateReport:
        return "Start-Gpt-GenerateReport";
      case AskIgorMenuItemEnum.Table:
        return "Start-Gpt-Table";
      case AskIgorMenuItemEnum.InformationExtraction:
        return "Start-Gpt-InformationExtraction";
      default:
        return "Start-Gpt-QuestionAndAnswer";
    }
  }

  public getRelatedInsertResultLogEventName(
    askIgorMenuItem: AskIgorMenuItemEnum
  ): TLogEventName {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.QuestionAndAnswer:
        return "Insert-Gpt-QuestionAndAnswer-Result";
      case AskIgorMenuItemEnum.GeneralDescriptionUsingLinks:
        return "Insert-Gpt-GeneralDescriptionUsingLinks-Result";
      case AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge:
        return "Insert-Gpt-GeneralDescriptionUsingGeneralKnowledge-Result";
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return "Insert-Gpt-ExecutiveSummary-Result";
      case AskIgorMenuItemEnum.WriteSection:
        return "Insert-Gpt-WriteSection-Result";
      case AskIgorMenuItemEnum.GenerateReport:
        return "Insert-Gpt-GenerateReport-Result";
      case AskIgorMenuItemEnum.Table:
        return "Insert-Gpt-Table-Result";
      case AskIgorMenuItemEnum.InformationExtraction:
        return "Insert-Gpt-InformationExtraction-Result";
      default:
        return "Insert-Gpt-QuestionAndAnswer-Result";
    }
  }

  public getRelatedErrorMessage(askIgorMenuItem: AskIgorMenuItemEnum | undefined): string {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.GeneralDescription:
      case AskIgorMenuItemEnum.GeneralDescriptionUsingLinks:
      case AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge:
        return "Could not generate general description, please contact the support.";
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return "Could not generate executive summary, please contact the support.";
      case AskIgorMenuItemEnum.Table:
      case AskIgorMenuItemEnum.InformationExtraction:
        return "Could not extract detail information, please contact the support.";
      case AskIgorMenuItemEnum.GenerateReport:
        return "Could not generate report, please contact the support.";
      default:
        return "Something went wrong, please contact the support.";
    }
  }
}

export const AskIgorMenuItemHelperSingleton = new AskIgorMenuItemHelper();
