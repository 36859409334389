// node_modules
import {
  faBook,
  faBookOpenReader,
  faDiceD6,
  faFile,
  faInbox,
  faMagnifyingGlass,
  faCircleUser,
  faChevronRight,
  faMessageBot
} from "@fortawesome/pro-solid-svg-icons";
import { FC, useContext, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// Components
import { Explorer, Popover } from "Components";
import { SidebarItem } from "./SidebarItem";
import { ProfileMenu } from "./ProfileMenu";
import { TenantMenu } from "./TenantMenu/TenantMenu";
// Images
import FindestUniverseLogo from "Assets/Images/universe_logo_white.png";
// Styles
import styles from "./sidebar.module.scss";
// Contexts
import {
  AuthContext,
  DocumentContext,
  ElementVisibilityContext,
} from "Providers";
// Constants
import { FeatureToggleConstants } from "Constants";
// Enums
import { SidebarItemTypeEnum } from "Enums";
// Types
import { TSidebarListItem } from "Types";
// Custom hooks
import { useClickOutsideRef } from "Hooks";

export const Sidebar: FC = () => {
  const [text, setText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const profileButtonRef = useRef<HTMLDivElement>(null);
  const profileMenuContainerRef = useRef<HTMLDivElement>(null);
  const profilePopoversContainerRef = useRef<HTMLDivElement>(null);

  // Contexts
  const { hasAdvanced } = useContext(AuthContext);
  const { documentsNotLinkedCount } = useContext(DocumentContext);
  const { canUserEdit } = useContext(ElementVisibilityContext);

  // Custom hooks
  const { entityId, studyId } = useParams();

  const sidebarListItems: TSidebarListItem[] = useMemo(() => {
    let sidebarItems: TSidebarListItem[] = [];

    // If the user can edit then show the inbox option
    if (canUserEdit) {
      sidebarItems.push({
        title: "Inbox",
        type: SidebarItemTypeEnum.Inbox,
        icon: faInbox,
        navigateTo: "/inbox",
        count: documentsNotLinkedCount,
        rightContent:
          documentsNotLinkedCount !== undefined && documentsNotLinkedCount > 0
            ? documentsNotLinkedCount
            : undefined,
      });
    }

    sidebarItems = sidebarItems.concat([
      {
        title: "Universe",
        type: SidebarItemTypeEnum.Library,
        icon: faBook,
        navigateTo: "/library/overview",
        homePath: "/",
        subItems: [
          {
            id: "Universe",
            title: "Universe",
            icon: faBook,
            navigateTo: "/library/overview",
            onlyVisibleWhenCollapsed: true,
          },
          {
            id: "Documents",
            title: "Documents",
            icon: faFile,
            navigateTo: "/library/documents",
          },
          {
            id: "Entities",
            title: "Entities",
            icon: faDiceD6,
            navigateTo: "/library/entities",
          },
          {
            id: "Studies",
            title: "Studies",
            icon: faBookOpenReader,
            navigateTo: "/library/studies",
          },
        ],
      },
    ]);

    if (!FeatureToggleConstants.USEFeatures && hasAdvanced) {
      sidebarItems.push({
        title: "Queries",
        type: SidebarItemTypeEnum.Queries,
        icon: faMagnifyingGlass,
        navigateTo: "/queries",
      });
    }

    if (FeatureToggleConstants.USEFeatures && hasAdvanced) {
      sidebarItems.push({
        htmlTitle: "IGOR<sup>AI</sup> search",
        title: "IGOR<sup>AI</sup> search",
        type: SidebarItemTypeEnum.Queries,
        icon: faMessageBot,
        navigateTo: "/queries",
      });
    }
    return sidebarItems;
  }, [
    canUserEdit,
    hasAdvanced,
    documentsNotLinkedCount
  ]);

  // Custom hooks
  const navigate = useNavigate();
  const location = useLocation();

  const isObjectDetailsPageShown = useMemo((): boolean => {
    // if entityId or studyId is set, then we are on an object details page
    if (entityId || studyId) {
      return true;
    } else {
      return false;
    }
  }, [entityId, studyId]);

  const resetProfileMenu = () => {
    setIsProfileMenuOpen(false);
    setIsEditing(false);
  };

  useClickOutsideRef(
    profilePopoversContainerRef,
    () => {
      resetProfileMenu();
    },
    [profileButtonRef]
  );

  // Render
  return (
    <>
      <div
        className={`${styles.sidebarContainerMainTop} ${isObjectDetailsPageShown ? styles.doubleSidebar : ""
          }`}
      >
        <div className={styles.universeLogo}>
          <img
            onClick={() => {
              navigate("/");
            }}
            src={FindestUniverseLogo}
            alt="Findest Universe Logo"
          />
        </div>
        <div className={`${styles.sidebarContainerMain}`}>
          <div className={`${styles.sidebarContainer}`}>
            <div className={styles.body}>
              <ul>
                {sidebarListItems.map((item) => (
                  <SidebarItem
                    isCollapsed={isObjectDetailsPageShown}
                    activeSidebarItem={location.pathname}
                    key={item.title}
                    sidebarItem={item}
                    showInsideAnchor
                  />
                ))}
              </ul>
            </div>
            <div className={styles.footer} ref={profileButtonRef}>
              <SidebarItem
                isCollapsed={isObjectDetailsPageShown}
                activeSidebarItem={location.pathname}
                key="profile"
                sidebarItem={{
                  title: "Profile",
                  icon: faCircleUser,
                  rightContent: faChevronRight,
                  isSelected: isProfileMenuOpen,
                }}
                onItemClick={() => {
                  setIsProfileMenuOpen(!isProfileMenuOpen);
                }}
                extraClassNames={{
                  sidebarListItem: styles.footerItemContainer,
                }}
              />
            </div>
          </div>
          {isObjectDetailsPageShown && <Explorer />}
        </div>
      </div>
      <div ref={profilePopoversContainerRef}>
        <Popover
          referenceEl={profileButtonRef.current}
          isOpen={isProfileMenuOpen}
          placement="right"
          extraClassName={styles.profilePopover}
        >
          <div ref={profileMenuContainerRef}>
            <ProfileMenu
              text={text}
              setText={setText}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onItemClick={resetProfileMenu}
            />
          </div>
        </Popover>
        <Popover
          referenceEl={profileMenuContainerRef.current}
          isOpen={isEditing}
          placement="right"
          extraClassName={styles.tenantPopover}
        >
          <TenantMenu
            text={text}
            setText={setText}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          ></TenantMenu>
        </Popover>
      </div>
    </>
  );
};
