import { faClose, faLink, faMagnifyingGlass, faRobot } from "@fortawesome/pro-solid-svg-icons";
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
// Enums
import {
  AskIgorMenuItemEnum,
  LinkStatusEnum,
  LogFeatureNameEnum,
  ObjectTypeEnum,
  SavedDocumentTypeEnum,
  SearchQueryTypeEnum,
  SortTypeEnum,
  ToastTypeEnum,
  WebRequestStatusEnum,
} from "Enums";
// Components
import {
  ConnectedQueries,
  Editor,
  FileUploadProgressIndicator,
  FindestButton,
  HasAdvanced,
  LinkingModal,
  MaturityRadar,
  PageComments,
  ReferencedBy,
  RequirementsTable,
  SavedDocuments,
} from "Components";
// Contexts
import {
  AuthContext,
  EditorContext,
  ElementVisibilityContext,
  WindowingContext,
} from "Providers";
// Types
import {
  TIdNameTypeObjectType,
  TImageDTO,
  TOption,
  TSavedFileDTO,
} from "Types";
// Constants
import { EventConstants, FeatureToggleConstants, LinkingConstants } from "Constants";
// Controllers
import { LinkingControllerSingleton, QueryControllerSingleton, SavedFileControllerSingleton } from "Controllers";
// Helpers
import { LogHelperSingleton, LocalStorageHelperSingleton, ToastHelperSingleton, URLHelperSingleton, UserHelperSingleton, EditorHelperSingleton } from "Helpers";
// Interfaces
import { IObject, IQueryDTO, ISavedDocumentDTO } from "Interfaces";
// Styles
import entityLikeCardStyles from "Styles/entityLikeCard.module.scss";
// Custom hooks
import { useCreateEntity, useSelectionNode } from "Hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TObjectDetailsProps<T extends IObject> = {
  children?: React.ReactNode;
  object?: T;
  setObject: Dispatch<SetStateAction<T | undefined>>;
  objectType: ObjectTypeEnum;
  type: string;
  onImageInsertedAsync: (
    image: File,
    caption?: string
  ) => Promise<TImageDTO | undefined>;
  onSourceChangeAsync: (
    newValue: string,
    forceUpdate: boolean
  ) => Promise<void>;
  refreshDocumentsAsync: (
    fromDate: Date | undefined,
    selectedFilterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[],
    sortType: SortTypeEnum,
    callback?: (newSavedDocuments: ISavedDocumentDTO[]) => void
  ) => Promise<void>;
  deleteSavedDocumentAsync?: (
    savedDocumentsToDelete: ISavedDocumentDTO[]
  ) => Promise<void>;
};

export function ObjectDetails<T extends IObject>({
  children,
  object,
  setObject,
  objectType,
  type,
  onImageInsertedAsync,
  onSourceChangeAsync,
  refreshDocumentsAsync,
  deleteSavedDocumentAsync,
}: TObjectDetailsProps<T>) {
  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();

  // Context
  const { auth } = useContext(AuthContext);
  const {
    isEditOn,
    isObjectLocked,
    setIsObjectLocked,
    setSavedDocumentsCount,
    isLinkingModalOpen,
    setIsLinkingModalOpen,
    isObjectToDocumentLinkingModalOpen,
    setIsObjectToDocumentLinkingModalOpen,
    linkToName,
    setLinkToName,
    editor,
    updateTOCItems,
    scrollingParentElement,
    setScrollingParentElement,
    setAskIgorModalOptions,
  } = useContext(EditorContext);
  const { canUserEdit, isRightSidebarCollapsed } = useContext(
    ElementVisibilityContext
  );
  const { openQueryDetails } = useContext(WindowingContext);
  const selectionNodeData = useSelectionNode({ editor });

  // State
  const [fileUploadProgress, setFileUploadProgress] =
    useState<number | undefined>(undefined);
  const [isScrollingAreaPositionTop, setIsScrollingAreaPositionTop] =
    useState<boolean>(true);
  const [openDocumentModal, setOpenDocumentModal] = useState<{ url: string, id?: string } | undefined>(undefined);
  const [showQuickStartButtons, setShowQuickStartButtons] = useState<boolean>(false);
  const [isDismissButtonVisible, setIsDismissButtonVisible] = useState<boolean>(false);
  const [connectedQueriesCount, setConnectedQueriesCount] = useState<number>(0);
  const [selectedSavedDocuments, setSelectedSavedDocuments] = useState<ISavedDocumentDTO[]>([]);

  // Refs
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const { onCreateEntityAsyncCallback } = useCreateEntity({});

  useEffect(() => {
    if (!object?.id) return;
    const currentDismissedObjects: Record<string, number> = LocalStorageHelperSingleton.getItem("quickStartButtonsDismissedObjects") ?? {};
    const isObjectQuickStartButtonsDismissed = currentDismissedObjects[object?.id];
    setShowQuickStartButtons(!isObjectQuickStartButtonsDismissed);
  }, [object?.id]);

  useEffect(() => {
    // if the user cannot edit then do nothing
    if (!canUserEdit) return;

    // If the linking parameter is enabled then open the linking modal
    // and remove the linking parameter
    if (searchParams.has("isLinking")) {
      setIsLinkingModalOpen(true);
      setSearchParams({});
    }
  }, [
    isObjectLocked,
    canUserEdit,
    searchParams,
    setIsLinkingModalOpen,
    setSearchParams,
  ]);

  useEffect(() => {
    setIsObjectLocked(object?.isLocked);
    setSavedDocumentsCount(object?.savedDocuments.length ?? 0);
  }, [
    object?.isLocked,
    object?.savedDocuments,
    setIsObjectLocked,
    setSavedDocumentsCount,
  ]);

  const uploadFileAsync = useCallback(
    async (file: File): Promise<TSavedFileDTO | undefined> => {
      if (!canUserEdit || isObjectLocked || !object) return undefined;

      abortControllerRef.current = new AbortController();

      const uploadedFile: TSavedFileDTO | null | undefined =
        await SavedFileControllerSingleton.createSavedFileUsingForm(
          file,
          file.name,
          object.id,
          objectType,
          setFileUploadProgress,
          abortControllerRef.current.signal
        );
      if (!uploadedFile) {
        if (uploadedFile === null) {
          return undefined;
        }

        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not attach file."
        );
        return undefined;
      }

      setObject({
        ...object,
        linkedFiles: [...object.linkedFiles, uploadedFile],
      });

      return uploadedFile;
    },
    [isObjectLocked, canUserEdit, object, objectType, setObject]
  );

  const onLinkingModalClose = useCallback(() => {
    setLinkToName(undefined);
    setIsObjectToDocumentLinkingModalOpen(false);
  }, [setIsObjectToDocumentLinkingModalOpen, setLinkToName]);

  const excludedSearchTypes = useMemo((): ObjectTypeEnum[] => {
    // if link to name is defined, exclude the study type
    // (we could have open linking modal through save as entity menu option)
    // and in this case we want to exclude the study type
    if (linkToName) {
      return [ObjectTypeEnum.Study];
    } else {
      return [];
    }
  }, [linkToName]);

  const onSaveClickCallback = useCallback(
    (selectedLinkToObject: TIdNameTypeObjectType): void => {
      if (
        !linkToName ||
        selectedLinkToObject.objectType !== ObjectTypeEnum.Entity ||
        !editor
      ) {
        return;
      }

      onCreateEntityAsyncCallback(selectedLinkToObject, editor);
    },
    [editor, linkToName, onCreateEntityAsyncCallback]
  );


  // open document modal of the id or matched URL
  const onReferenceClick = useCallback(async (event: Event) => {
    const url: string = (event as CustomEvent).detail.url;
    const id: string = (event as CustomEvent).detail.id;
    if (object?.savedDocuments.find((doc) => URLHelperSingleton.stripProtocol(doc.url) === URLHelperSingleton.stripProtocol(url))) {
      LogHelperSingleton.logWithProperties("OpenDocument", { DocumentId: id });
      setOpenDocumentModal({ url, id });
    } else {
      LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-OpenLink`);
      window.open(url, "_blank", "noopener,noreferrer");
    }
  }, [object?.savedDocuments]);

  const onLinkQuery = useCallback(async (query: IQueryDTO) => {
    if (!object?.id) {
      return;
    }
    // Link the query to the current object
    const requestStatus = await LinkingControllerSingleton.createToAsync(
      query.guid,
      ObjectTypeEnum.Query,
      object.id,
      objectType
    );
    // Notify the user if the query could not be linked
    if (requestStatus !== WebRequestStatusEnum.Success) {
      ToastHelperSingleton.showToast(ToastTypeEnum.Error, "Could not link query to object");
    }
  }, [object?.id, objectType]);

  const onFindPapersButtonClick = useCallback(
    async () => {
      if (!object) {
        return;
      }

      const newQueryName = `${object.title} - Query ${connectedQueriesCount + 1}`;

      const createdQuery: IQueryDTO | undefined =
        await QueryControllerSingleton.createAsync(
          newQueryName,
          SearchQueryTypeEnum.USEOnScience,
          LogFeatureNameEnum.FindPapersButton
        );

      if (!createdQuery) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to create query."
        );
        return;
      }

      await onLinkQuery(createdQuery);

      openQueryDetails(createdQuery, LogFeatureNameEnum.CreateQueryModal);
    },
    [connectedQueriesCount, object, onLinkQuery, openQueryDetails]
  );

  useEffect(() => {
    window.addEventListener(EventConstants.INLINE_REFERENCE_EVENT, onReferenceClick);

    return () => {
      window.removeEventListener(EventConstants.INLINE_REFERENCE_EVENT, onReferenceClick);
    };
  }, [onReferenceClick]);

  // if object is undefined then return an empty div
  if (!object) return <div></div>;

  const scrollEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!scrollingParentElement) return;
    updateTOCItems();
    const target = e.target as HTMLDivElement;
    if (target.scrollTop > 0) {
      if (isScrollingAreaPositionTop) {
        setIsScrollingAreaPositionTop(false);
      }
    } else {
      setIsScrollingAreaPositionTop(true);
    }
  };

  const onCancelUpload = () => {
    // abort
    abortControllerRef.current.abort();
    setFileUploadProgress(undefined);
  };

  const onLinkDocumentsButtonClick = () => {
    if (!canUserEdit) return;

    setIsLinkingModalOpen(true);
    setIsObjectToDocumentLinkingModalOpen(true);
  };

  const onAskIgorQuickStartButtonClick = () => {
    if (!canUserEdit || isObjectLocked) return;

    if (!editor) return;

    let defaultInput: string = selectionNodeData?.selectedText ?? "";

    if (!defaultInput) {
      const headingText: string = EditorHelperSingleton.getHeadingText(
        editor,
        editor.$pos(editor.state.selection.$from.pos)
      );

      defaultInput = headingText ?? defaultInput;
    }
              
    setAskIgorModalOptions((prevAskIgorModalOptions) => {
      return {
        ...prevAskIgorModalOptions,
        selectedMenuItem: object.totalDocumentsCount > 0 ? AskIgorMenuItemEnum.QuestionAndAnswer : AskIgorMenuItemEnum.GeneralDescription,
        defaultInput,
        isOpen: true,
        isMinimized: false
      };
    });
  };

  const onDismissQuickStartButtons = () => {
    if (!object.id) {
      return;
    }

    setShowQuickStartButtons(false);
    const currentDismissedObjects: { [key: string]: number } = LocalStorageHelperSingleton.getItem("quickStartButtonsDismissedObjects") ?? {};
    if (Object.keys(currentDismissedObjects).length === 1000) {
      // find the oldest dismissed object and remove it from the list to keep the list at 1000 objects max
      const oldestObjectKey = Object.keys(currentDismissedObjects).reduce((a, b) => currentDismissedObjects[a] < currentDismissedObjects[b] ? a : b, "0");
      delete currentDismissedObjects[oldestObjectKey];
    }
    if (Object.keys(currentDismissedObjects).length < 1000) {
      currentDismissedObjects[object.id] = Date.now();
      LocalStorageHelperSingleton.setItem(currentDismissedObjects, "quickStartButtonsDismissedObjects");
    }
  };

  return (
    <div
      ref={setScrollingParentElement}
      className={`${entityLikeCardStyles.entityLikeCard} ${isRightSidebarCollapsed
        ? "rightSidebarCollapsed"
        : "rightSidebarUncollapsed"
        }`}
      onScroll={scrollEvent}
    >
      <div
        className={`${entityLikeCardStyles.isScrollingElement} ${isScrollingAreaPositionTop ? "" : entityLikeCardStyles.isScrolling
          }`}
      ></div>
      {children}
      {!UserHelperSingleton.isSharingRestrictedToObject(auth) && (
        <ReferencedBy referencedBy={object.referencedBy} />
      )}
      <div className={entityLikeCardStyles.entityLikeCardContentContainer}>
        {isEditOn && canUserEdit && showQuickStartButtons && (
          <div className={entityLikeCardStyles.quickStartButtonsSection}>
            <div className={entityLikeCardStyles.quickStartButtonsContainer}
              onMouseEnter={() => setIsDismissButtonVisible(true)}
              onMouseLeave={() => setIsDismissButtonVisible(false)}
              aria-hidden="true"
            >
              <button
                type="button"
                className={`${entityLikeCardStyles.quickStartDismissButton} ${isDismissButtonVisible ? entityLikeCardStyles.quickStartDismissButtonVisible : ""}`}
                onClick={onDismissQuickStartButtons}
                onMouseEnter={() => setIsDismissButtonVisible(true)}
                onMouseLeave={() => setIsDismissButtonVisible(false)}
              ><FontAwesomeIcon icon={faClose} /></button>
              <div className={entityLikeCardStyles.line}>
                <FindestButton leftIconName={faLink} buttonType="secondary" title="Link documents" onClick={onLinkDocumentsButtonClick} />
                <HasAdvanced>
                  <FindestButton leftIconName={faMagnifyingGlass} buttonType="secondary" title="Find papers" onClick={onFindPapersButtonClick} />
                </HasAdvanced>
              </div>
              {!isObjectLocked && <FindestButton extraClassName={entityLikeCardStyles.askIgorButton} leftIconName={faRobot} buttonType="secondary" title={object.totalDocumentsCount > 0 ? "Ask me anything about the linked sources" : "Write introduction based on general knowledge"} onClick={onAskIgorQuickStartButtonClick} />}
            </div>
          </div>
        )}
        <Editor
          onImageInsertedAsync={onImageInsertedAsync}
          onContentChangeAsync={onSourceChangeAsync}
          uploadFileAsync={uploadFileAsync}
          object={object}
          objectType={objectType}
          type={type}
          content={object.description}
          selectedSavedDocuments={selectedSavedDocuments}
        />
        {FeatureToggleConstants.RequirementsTable && (
          <div
            className={
              entityLikeCardStyles.entityLikeCardConnectedDocumentsContainer
            }
          >
            <RequirementsTable
              shouldShowEditingOptions={isEditOn}
              objectType={objectType}
              objectId={object.id}
            />
          </div>
        )}
        {FeatureToggleConstants.MaturityRadar && (
          <div>
            <MaturityRadar
              objectId={object.id}
              objectType={objectType}
              shouldShowEditingOptions={isEditOn}
            />
          </div>
        )}
        <div
          className={
            entityLikeCardStyles.entityLikeCardConnectedDocumentsContainer
          }
        >
          <SavedDocuments
            linkedToObjectId={object.id}
            header={"Linked documents"}
            documents={object.savedDocuments}
            doUseSavedFilters={false}
            totalDocumentsCount={object.totalDocumentsCount}
            refreshDocumentsAsync={refreshDocumentsAsync}
            deleteSavedDocumentAsync={
              !canUserEdit || isObjectLocked
                ? undefined
                : deleteSavedDocumentAsync
            }
            deleteIsUnlink={true}
            doHideIfNoDocumentsLinked={true}
            isEditable={canUserEdit && !isObjectLocked}
            isObjectDetails={true}
            setAskIgorModalOptions={setAskIgorModalOptions}
            openDocumentModal={openDocumentModal}
            onSelectedSavedDocumentsChange={(newSavedDocuments) => {
              setSelectedSavedDocuments(newSavedDocuments);
            }}
          />
        </div>
        <HasAdvanced>
          <div
            className={
              entityLikeCardStyles.entityLikeCardConnectedQueriesContainer
            }
          >
            <ConnectedQueries
              objectId={object.id}
              objectName={object.title}
              objectType={objectType}
              setConnectedQueriesCount={setConnectedQueriesCount}
            />
          </div>
        </HasAdvanced>
        <div
          className={entityLikeCardStyles.entityLikeCardPageCommentsContainer}
        >
          <PageComments
            currentUsername={auth.userEmail}
            objectType={objectType}
            objectId={object.id}
            header="Page comments"
          />
        </div>
      </div>
      <LinkingModal
        forcedLinkType={isObjectToDocumentLinkingModalOpen ? LinkingConstants.PARENT_LINK_TYPE : undefined}
        linkToName={linkToName}
        isOpen={isLinkingModalOpen}
        setIsOpen={setIsLinkingModalOpen}
        selectedObjects={[
          { id: object.id, name: object.title, type, objectType },
        ]}
        defaultLinkType={LinkingConstants.CHILD_LINK_TYPE}
        excludedSearchTypes={excludedSearchTypes}
        onClose={onLinkingModalClose}
        onSaveClickCallback={onSaveClickCallback}
        linkToType={isObjectToDocumentLinkingModalOpen ? ObjectTypeEnum.Document : undefined}
      />
      <FileUploadProgressIndicator
        onCancelClick={onCancelUpload}
        fileUploadProgress={fileUploadProgress}
      />
    </div>
  );
}
