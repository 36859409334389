// node_modules
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useRef, useState } from "react";
// Components
import { MoreActionsDropdownButton } from "Components";
import { ReferencePopover } from "Components/Shared/Modals/ReferenceModal";
// Enums
import { ObjectTypeEnum } from "Enums";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Hooks
import { useObjectReferenceModal } from "Hooks";
// Types
import { TIdNameTypeObjectType } from "Types";
// Styles
import styles from "./objectChip.module.scss";

type TObjectChipProps = {
  object: TIdNameTypeObjectType;
  onClick?: (object: TIdNameTypeObjectType) => void;
  onRemoveClick?: (object: TIdNameTypeObjectType) => void;
  isChipClickable?: boolean;
  isXMarkVisible?: boolean;
  removeIconTitle?: string;
  shouldShowPreviewCard?: boolean;
  hideMoreActionsDropdownButton?: boolean;
  navigateCallback?: () => void;
  onMoreActionsOptionClickCallback?: () => void;
  openReferenceModal?: (objectId: string, objectType: ObjectTypeEnum) => void;
};

export const ObjectChip: FC<TObjectChipProps> = ({
  object,
  onClick,
  onRemoveClick,
  shouldShowPreviewCard,
  isXMarkVisible,
  removeIconTitle,
  isChipClickable,
  hideMoreActionsDropdownButton,
  navigateCallback,
  onMoreActionsOptionClickCallback,
  openReferenceModal,
}: TObjectChipProps) => {
  // State
  const type = object.objectType ?? object.type;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [popoverReferenceElement, setPopoverReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [isReferencePopoverOpen, setIsReferencePopoverOpen] =
    useState<boolean>(false);
  const [referencePopoverProps, setReferencePopoverProps] =
    useState<{ id: string; type: ObjectTypeEnum } | undefined>(undefined);

  // Custom hooks
  const { referenceModal, setReferenceModalProps } =
    useObjectReferenceModal(undefined);

  let hoverTimer: NodeJS.Timeout | null = null;

  const isHoveredRef = useRef(isHovered);

  useEffect(() => {
    isHoveredRef.current = isHovered;
  }, [isHovered]);

  const handleRemoveClick = () => {
    // safety-checks
    if (!onRemoveClick) return;
    // call on remove click handler
    onRemoveClick(object);
  };

  const onMouseEnter = () => {
    setIsHovered(true);
    if (shouldShowPreviewCard) {
      hoverTimer = setTimeout(() => {
        if (isHoveredRef.current) {
          setIsReferencePopoverOpen(true);
          setReferencePopoverProps({
            id: object.id,
            type: type,
          });
        }
      }, 500);
    }
  };

  const onMouseLeave = () => {
    setIsHovered(false);
    if (shouldShowPreviewCard) {
      setIsReferencePopoverOpen(false);
      if (hoverTimer) {
        clearTimeout(hoverTimer);
        hoverTimer = null;
        isHoveredRef.current = false;
      }
    }
  };

  return (
    <>
      <div
        className={`${styles.existingConnectionItem} ${
          isChipClickable || !!onClick ? styles.clickable : ""
        } ${shouldShowPreviewCard ? styles.hasPaddingRight : ""} ${
          styles[
            `${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
              type
            ).toLowerCase()}`
          ]
        }`}
        onClick={() => {
          if (onClick) {
            onClick(object);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === " ") {
            e.preventDefault();
            onClick?.(object);
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={setPopoverReferenceElement}
        role="button"
        tabIndex={0}
      >
        <div className={[styles.iconContainer, styles.objectIcon].join(" ")}>
          <FontAwesomeIcon
            icon={ObjectTypeHelperSingleton.getObjectTypeIcon(
              type
            )}
          />
        </div>
        <p>{object.name ?? object.title}</p>
        {!isXMarkVisible &&
          !hideMoreActionsDropdownButton &&
          !shouldShowPreviewCard && (
            <MoreActionsDropdownButton
              objectId={object.id}
              objectType={type}
              extraClassNames={{
                dropdownButton: styles.moreActionsDropdownButton,
              }}
              onDeleteConnection={onRemoveClick ? handleRemoveClick : undefined}
              deleteConnectionText={removeIconTitle}
              navigateCallback={navigateCallback}
              onClickOptionCallback={onMoreActionsOptionClickCallback}
              openReferenceModalProp={openReferenceModal}
            />
          )}
        {isXMarkVisible && onRemoveClick && (
          <div
            className={[
              styles.iconContainer,
              styles.deleteIcon,
              isHovered ? styles.active : null,
            ].join(" ")}
            title={removeIconTitle ?? ""}
            onClick={handleRemoveClick}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
      </div>
      {shouldShowPreviewCard &&
        isReferencePopoverOpen &&
        referencePopoverProps &&
        popoverReferenceElement && (
          <ReferencePopover
            isOpen
            popoverOffset={0}
            id={referencePopoverProps.id}
            type={referencePopoverProps.type}
            referenceElement={popoverReferenceElement}
            setReferenceModalProps={setReferenceModalProps}
            hideReferencePopover={() => {
              setIsReferencePopoverOpen(false);
            }}
            onMouseEnter={() => {
              setIsReferencePopoverOpen(true);
            }}
            onMouseLeave={() => {
              setIsReferencePopoverOpen(false);
            }}
            showInPortal
          />
        )}
      {shouldShowPreviewCard && referenceModal}
    </>
  );
};
