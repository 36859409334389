import { FormEvent, useCallback, useMemo, useState } from "react";
import { URLHelperSingleton } from "Helpers";

interface IUseFormLinkProps {
  initialUrl?: string;
  onSave: (url: string, text?: string) => void;
}

export const useFormLink = ({ initialUrl, onSave }: IUseFormLinkProps) => {
  const [text, setText] = useState<string>("");
  const [url, setUrl] = useState<string>(initialUrl || "");

  const isValidUrl = useMemo(() => {
    return URLHelperSingleton.isValidUrl(url);
  }, [url]);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (isValidUrl) onSave(url, text);
    },
    [isValidUrl, onSave, url, text]
  );

  return {
    text,
    setText,
    url,
    setUrl,
    isValidUrl,
    handleSubmit,
  };
};
