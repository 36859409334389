import axios from "axios";
import { AxiosHelperSingleton, DateHelperSingleton } from "Helpers";
import { IDocumentSearchResult } from "Interfaces";

export class UseOnPatentsController {
  private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/use-patent`;

  public async searchAsync(
    query: string,
    start?: string | null,
    end?: string | null,
    abortSignal?: AbortSignal
  ): Promise<
    (IDocumentSearchResult & {
      score?: number | null;
      citation_score?: number | null;
    })[]
  > {
    try {
      const adjustedDates = DateHelperSingleton.adjustDateRangeForUse(
        start,
        end
      );
      start = adjustedDates.start;
      end = adjustedDates.end;

      const body: {
        query: string;
        dateRange?: { start?: string | null; end?: string | null };
      } = {
        query,
      };

      if (start || end) {
        body.dateRange = {
          start,
          end,
        };
      }

      const response = await axios.post<{ patents: IDocumentSearchResult[] }>(
        `${this._resourcePath}/search`,
        body,
        {
          signal: abortSignal,
        }
      );

      if (response && response.data && response.data.patents) {
        return response.data.patents;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }

  public async chatAsync(
    message: string,
    start?: string | null,
    end?: string | null,
    abortSignal?: AbortSignal
  ): Promise<string | undefined> {
    try {
      const adjustedDates = DateHelperSingleton.adjustDateRangeForUse(
        start,
        end
      );
      start = adjustedDates.start;
      end = adjustedDates.end;

      const body: {
        message: string;
        dateRange?: { start?: string | null; end?: string | null };
      } = {
        message,
      };

      if (start || end) {
        body.dateRange = {
          start,
          end,
        };
      }

      const response = await axios.post<{ response: string }>(
        `${this._resourcePath}/chat`,
        body,
        {
          signal: abortSignal,
        }
      );

      if (response && response.data && response.data.response) {
        return response.data.response;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }
}

export const UseOnPatentsControllerSingleton = new UseOnPatentsController();
