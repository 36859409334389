export const StudyConstants = {
  MAXIMUM_STUDIES_TO_RETRIEVE: 10,
  CREATE_CUSTOM_TYPE_OPTION: "Create custom type",
  CONVERT_TO_ENTITY_OPTION: "Convert to entity",
  DELETE_STUDY_CONFIRMATION:
    'Are you sure you want to delete this study?\n\nAfter it is deleted, it can be restored through "Settings" page, but the links to the other objects will be gone!',
  DELETE_STUDIES_CONFIRMATION:
    'Are you sure you want to delete the selected studies?\n\nAfter they are deleted, they can be restored through "Settings" page, but their links to the other objects will be gone!',
  CREATE_STUDY_DESCRIPTION: "Create a workspace to gather and present acquired knowledge and insights from your technical or scientific research studies.",
  } as const;
