// node_modules
import { FC, useContext, useEffect, useMemo, useState } from "react";
// Components
import { AskIgorMenuItem } from "./AskIgorMenuItem/AskIgorMenuItem";
import { Checkbox, FindestButton, Popover, Tabs } from "Components";
// Types
import {
  TAskIgorRequirement,
  TAskIgorSettings,
  TIdNameTypeObjectType,
  TTab,
} from "Types";
// Enums
import { ObjectTypeEnum, AskIgorMenuItemEnum } from "Enums";
// Constants
import { FeatureToggleConstants } from "Constants";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faAward,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faFile,
  faFlask,
  faGlobe,
  faHighlighter,
  faMessageBot,
} from "@fortawesome/pro-solid-svg-icons";
// Controllers
import {
  HighlightControllerSingleton,
  SavedDocumentControllerSingleton,
} from "Controllers";
// Interfaces
import { ISavedDocumentDTO } from "Interfaces";
// Helpers
import { ObjectTypeHelperSingleton } from "Helpers";
// Providers
import { EditorContext } from "Providers";
// Styles
import styles from "./askIgorMenu.module.scss";

type TAskIgorMenuProps = {
  object: TIdNameTypeObjectType;
  selectedItem: AskIgorMenuItemEnum | undefined;
  selectedDocumentsOfIgor: string[];
  defaultInput?: string;
  selectedSavedDocumentsFormList: ISavedDocumentDTO[] | undefined;
  onDocumentTypesChange: (documentTypes: ObjectTypeEnum[]) => void;
  onSelectedItemUpdate: (newSelectedItem: AskIgorMenuItemEnum) => void;
  onSubmit: (
    newSelectedItem: AskIgorMenuItemEnum,
    text: string,
    dataPointsAmount?: number,
    requirements?: TAskIgorRequirement[]
  ) => void;
  loading: boolean;
  onEmptyDocumentList: () => void;
  onSetAskIgorModalOptions: (documentId: string[]) => void;
  isGeneratingText: boolean;
  onFetchLinkedDocuments: (
    savedDocuments: ISavedDocumentDTO[] | undefined
  ) => void;
};

export const AskIgorMenu: FC<TAskIgorMenuProps> = ({
  object,
  selectedItem,
  selectedDocumentsOfIgor,
  defaultInput,
  selectedSavedDocumentsFormList,
  onSelectedItemUpdate,
  onDocumentTypesChange,
  onSubmit,
  loading,
  onEmptyDocumentList,
  onSetAskIgorModalOptions,
  isGeneratingText,
  onFetchLinkedDocuments,
}: TAskIgorMenuProps) => {
  // State
  const [documentTypes, setDocumentTypes] = useState<TAskIgorSettings[]>([
    { id: ObjectTypeEnum.Weblink, name: "Web", isChecked: false },
    { id: ObjectTypeEnum.ScienceArticle, name: "Science", isChecked: false },
    { id: ObjectTypeEnum.UsPatent, name: "Patents", isChecked: false },
  ]);
  const [inputValue, setInputValue] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("Report");
  const [highlightCount, setHighlightCount] = useState<number>(0);
  const [linkedHighlightCount, setLinkedHighlightCount] = useState<number>(0);
  const [isPopoverShown, setIsPopoverShown] = useState<boolean>(false);
  const [savedDocuments, setSavedDocuments] =
    useState<ISavedDocumentDTO[] | undefined>(undefined);
  const [referenceElement, setReferenceElement] =
    useState<HTMLHeadElement | null>(null);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);
  const [emptyDocumentList, setEmptyDocumentList] = useState<boolean>(false);

  // Context
  const { setAskIgorModalOptions } = useContext(EditorContext);

  // Memo
  const presetTabs = useMemo((): TTab[] => {
    // init settings tabs
    const tabs: TTab[] = [
      { name: "Report" },
      { name: "Extract information" },
      { name: "Other" },
    ];

    // return settings tabs
    return tabs;
  }, []);

  // Logic
  const onAskIgorMenuItemClick = (item: AskIgorMenuItemEnum): void => {
    onSubmit(item, inputValue);
  };

  const getSelectedTypes = (types: TAskIgorSettings[]): ObjectTypeEnum[] => {
    let selectedTypes = types
      .filter((type) => type.isChecked)
      .map((type) => type.id);
    if (selectedTypes.includes(ObjectTypeEnum.UsPatent)) {
      selectedTypes.push(ObjectTypeEnum.MagPatent);
    } else {
      selectedTypes = selectedTypes.filter(
        (type) => type !== ObjectTypeEnum.MagPatent
      );
    }
    return selectedTypes;
  };

  // if ObjectTypeEnum.UsPatent is (not) selected, do the same for ObjectTypeEnum.MagPatent
  const onPatentChange = (types: TAskIgorSettings[]): void => {
    setDocumentTypes(types);
    onDocumentTypesChange(getSelectedTypes(types));
  };

  // sync the document types with the selection of the documents
  const onDocumentsSelectionChanges = (selectedIds: string[]): void => {
    const updatedDocumentTypes = documentTypes.map((type) => {
      const isChecked = savedDocuments?.some(
        (doc) =>
          selectedIds.includes(doc.id) &&
          ObjectTypeHelperSingleton.documentTypeToObjectType(
            doc.savedDocumentType
          ) === type.id
      );
      return { ...type, isChecked: isChecked ?? false };
    });

    onPatentChange(updatedDocumentTypes);

    const totalHighlightCount =
      savedDocuments
        ?.filter((doc) => selectedIds.includes(doc.id))
        .reduce(
          (acc, doc) => acc + (doc.linkedCounts?.highlightCount || 0),
          0
        ) || 0;

    setHighlightCount(totalHighlightCount + linkedHighlightCount);
    onSetAskIgorModalOptions(selectedIds);
    setAskIgorModalOptions((prevAskIgorModalOptions) => {
      return {
        ...prevAskIgorModalOptions,
        documentsSelected: selectedIds,
      };
    });
  };

  // sync the selection of the documents with the document types
  const onDocumentTypesCheckboxChange = (
    typeId: number,
    isChecked: boolean
  ) => {
    const newTypes = documentTypes.map((type) =>
      type.id === typeId ? { ...type, isChecked } : type
    );

    onPatentChange(newTypes);

    if (isChecked) {
      const documentIdsToAdd = savedDocuments
      ?.filter(
        (doc) =>
        ObjectTypeHelperSingleton.documentTypeToObjectType(
          doc.savedDocumentType
        ) === typeId && !selectedDocumentIds.includes(doc.id)
      )
      .map((doc) => doc.id) || [];
      setSelectedDocumentIds([...selectedDocumentIds, ...documentIdsToAdd]);
      onDocumentsSelectionChanges([...selectedDocumentIds, ...documentIdsToAdd]);
    } else {
      const documentIdsToRemove = savedDocuments
      ?.filter(
        (doc) =>
        ObjectTypeHelperSingleton.documentTypeToObjectType(
          doc.savedDocumentType
        ) === typeId && selectedDocumentIds.includes(doc.id)
      )
      .map((doc) => doc.id) || [];
      const updatedSelectedDocumentIds = selectedDocumentIds.filter(
      (id) => !documentIdsToRemove.includes(id)
      );
      setSelectedDocumentIds(updatedSelectedDocumentIds);
      onDocumentsSelectionChanges(updatedSelectedDocumentIds);
    }
  };

  // on the click of a document from the list
  const onSavedDocumentCheckboxChange = (documentId: string) => {
    const newSelectedDocumentIds = selectedDocumentIds.includes(documentId)
      ? selectedDocumentIds.filter((id) => id !== documentId)
      : [...selectedDocumentIds, documentId];
    setSelectedDocumentIds(newSelectedDocumentIds);
    onDocumentsSelectionChanges(newSelectedDocumentIds);
  };

  // get savedDocuments & counts of the linked documents and highlights
  const onGetDocuments = async () => {
    setInputValue(defaultInput ?? "");

    const linkedDocuments =
      await SavedDocumentControllerSingleton.getLinkedToObject(object.id);
    setSavedDocuments(linkedDocuments);

    onFetchLinkedDocuments(linkedDocuments);

    const getSelectedLinkedDocuments = (): ISavedDocumentDTO[] => {
      if (selectedSavedDocumentsFormList?.length) return selectedSavedDocumentsFormList;
      if (selectedDocumentsOfIgor.length > 0) {
        return (
          linkedDocuments?.filter((doc) =>
            selectedDocumentsOfIgor.includes(doc.id)
          ) ?? []
        );
      }
      return linkedDocuments ?? [];
    };

    const selectedLinkedDocuments = getSelectedLinkedDocuments();

    // Update document types with selected state
    selectedLinkedDocuments.forEach((doc) => {
      const type = ObjectTypeHelperSingleton.documentTypeToObjectType(
        doc.savedDocumentType
      );
      const documentType = documentTypes.find((t) => t.id === type);
      if (documentType) documentType.isChecked = true;
    });

    const preSelectedDocuments = selectedSavedDocumentsFormList?.length
      ? selectedSavedDocumentsFormList.map((doc) => doc.id)
      : selectedDocumentsOfIgor.length > 0
      ? selectedDocumentsOfIgor
      : linkedDocuments?.map((doc) => doc.id) ?? [];

    setSelectedDocumentIds(preSelectedDocuments);
    onDocumentTypesChange(getSelectedTypes(documentTypes));
    onSetAskIgorModalOptions(preSelectedDocuments);

    // Fetch and update highlight counts
    const linkedHighlights =
      await HighlightControllerSingleton.getLinkedToObject(object.id);
    setLinkedHighlightCount(linkedHighlights?.length ?? 0);

    const calculateTotalHighlightCount = (): number =>
      selectedLinkedDocuments.reduce(
        (acc, doc) => acc + (doc.linkedCounts?.highlightCount ?? 0),
        0
      );

    setHighlightCount(
      calculateTotalHighlightCount() + (linkedHighlights?.length ?? 0)
    );
    if (linkedDocuments?.length === 0 && linkedHighlights?.length === 0) {
      onEmptyDocumentList();
      setEmptyDocumentList(true);
    }
  };

  useEffect(() => {
    onGetDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSourcesContainerKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === "Enter") {
      setIsPopoverShown((prevState) => !prevState);
    }
  };

  // Render
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <FontAwesomeIcon icon={faMessageBot} />
        <p>Ask IGOR</p>
      </div>
      <div
        className={`${styles.optionsContainer} ${
          loading || emptyDocumentList ? styles.emptyDocumentList : ""
        }`}
      >
        <div className={styles.options}>
          <div className={styles.optionsTitle}>
            <FontAwesomeIcon
              icon={ObjectTypeHelperSingleton.getObjectTypeIcon(
                object.objectType
              )}
            />
            <h4>{object.name}</h4>
          </div>
          <div className={styles.sourcesContainer}>
            <div
              className={`${styles.sources} ${
                isPopoverShown ? styles.open : ""
              }`}
              ref={setReferenceElement}
              onClick={() => {
                setIsPopoverShown(!isPopoverShown);
              }}
              role="button"
              tabIndex={0}
              onKeyDown={onSourcesContainerKeyDown}
            >
              <div className={styles.countContainer}>
                <div className={`${styles.count} ${styles.document}`}>
                  <div className={styles.iconContainer}>
                    <FontAwesomeIcon icon={faFile} />
                  </div>
                  <h4>{selectedDocumentIds.length}</h4>
                </div>
                <div className={`${styles.count} ${styles.highlight}`}>
                  <div className={styles.iconContainer}>
                    <FontAwesomeIcon icon={faHighlighter} />
                  </div>
                  <h4>{highlightCount}</h4>
                </div>
              </div>
              <FontAwesomeIcon
                icon={isPopoverShown ? faChevronUp : faChevronDown}
              />
            </div>
            {documentTypes.map((label, index) => (
              <div
                key={label.id}
                className={`${styles.sources} ${
                  loading || emptyDocumentList ? styles.disabled : ""
                }`}
              >
                <Checkbox
                  onCheckboxChange={(isChecked: boolean) => {
                    onDocumentTypesCheckboxChange(label.id, isChecked);
                  }}
                  isChecked={label.isChecked}
                  theme={emptyDocumentList || savedDocuments?.every(
                    (doc) =>
                      ObjectTypeHelperSingleton.documentTypeToObjectType(
                        doc.savedDocumentType
                      ) !== label.id
                  ) ? "grayDisabled" : "blue"}
                />
                <FontAwesomeIcon icon={[faGlobe, faFlask, faAward][index]} />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.options}>
          <div className={styles.optionsTitle}>
            <h4>Ask me anything about the linked sources</h4>
          </div>
          <div
            className={`${styles.textContainer} ${
              loading || emptyDocumentList ? styles.disabled : ""
            }`}
          >
            <textarea
              className={styles.questionBox}
              placeholder={
                "Write your question on the linked sources (e.g. What are the main technologies mentioned?)"
              }
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />
            <FindestButton
              buttonType="primary"
              extraClassName={`${styles.questionSubmit} ${
                inputValue === "" ? styles.disabled : ""
              }`}
              leftIconName={faChevronRight}
              onClick={() => {
                onSubmit(AskIgorMenuItemEnum.QuestionAndAnswer, inputValue);
              }}
            />
          </div>
        </div>
        <div className={styles.options}>
          <div className={styles.optionsTitle}>
            <h4>Or pick a preset</h4>
          </div>
          <Tabs
            tabs={presetTabs}
            theme="blue"
            defaultSelectedTab={presetTabs[0].name}
            onSelectedTabChange={(tabName: string) => {
              setActiveTab(tabName);
            }}
          />
          {activeTab === "Report" ? (
            <div
              className={`${styles.presetContainer} ${
                loading || emptyDocumentList ? styles.disabled : ""
              }`}
            >
              <div className={styles.active}>
                {FeatureToggleConstants.TIAutomation && emptyDocumentList && (
                  <AskIgorMenuItem
                    item={
                      AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge
                    }
                    isSelected={
                      selectedItem ===
                        AskIgorMenuItemEnum.GeneralDescriptionUsingLinks ||
                      selectedItem ===
                        AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge ||
                      selectedItem === AskIgorMenuItemEnum.GeneralDescription
                    }
                    isGeneratingText={isGeneratingText}
                    onClick={onAskIgorMenuItemClick}
                  />
                )}
              </div>
              <AskIgorMenuItem
                item={AskIgorMenuItemEnum.WriteSection}
                isSelected={selectedItem === AskIgorMenuItemEnum.WriteSection}
                isDisabled={inputValue === ""}
                isGeneratingText={isGeneratingText}
                onClick={onAskIgorMenuItemClick}
              />
              {FeatureToggleConstants.TIAutomation && !emptyDocumentList && (
                <AskIgorMenuItem
                  item={
                    AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge
                  }
                  isSelected={
                    selectedItem ===
                      AskIgorMenuItemEnum.GeneralDescriptionUsingLinks ||
                    selectedItem ===
                      AskIgorMenuItemEnum.GeneralDescriptionUsingGeneralKnowledge
                  }
                  isGeneratingText={isGeneratingText}
                  onClick={onAskIgorMenuItemClick}
                />
              )}
              <AskIgorMenuItem
                item={AskIgorMenuItemEnum.GenerateReport}
                isSelected={selectedItem === AskIgorMenuItemEnum.GenerateReport}
                isGeneratingText={isGeneratingText}
                onClick={onAskIgorMenuItemClick}
              />
            </div>
          ) : activeTab === "Extract information" ? (
            <div
              className={`${styles.presetContainer} ${
                loading || emptyDocumentList ? styles.disabled : ""
              }`}
            >
              {FeatureToggleConstants.TIAutomation && (
                <AskIgorMenuItem
                  item={AskIgorMenuItemEnum.InformationExtraction}
                  isSelected={
                    selectedItem === AskIgorMenuItemEnum.InformationExtraction
                  }
                  isGeneratingText={isGeneratingText}
                  onClick={onSelectedItemUpdate}
                />
              )}
            </div>
          ) : (
            <div
              className={`${styles.presetContainer} ${
                loading || emptyDocumentList ? styles.disabled : ""
              }`}
            >
              {FeatureToggleConstants.TIAutomation && (
                <AskIgorMenuItem
                  item={AskIgorMenuItemEnum.ExecutiveSummary}
                  isSelected={
                    selectedItem === AskIgorMenuItemEnum.ExecutiveSummary
                  }
                  isGeneratingText={isGeneratingText}
                  onClick={onAskIgorMenuItemClick}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {isPopoverShown && (
        <Popover
          referenceEl={referenceElement}
          extraClassName={styles.popover}
          placement="bottom-start"
          onClickOutside={() => {
            if (event && !referenceElement?.contains(event.target as Node)) {
              setIsPopoverShown(false);
            }
          }}
        >
          <h4>Linked documents</h4>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.findest.com/contributor-workflows-and-features/build-reports-with-ai#sources-used-per-task"
            className={styles.moreInformation}
          >
            More information about linked sources
            <span>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </span>
          </a>
          {savedDocuments?.map((doc) => (
            <div key={doc.id} className={styles.popoverItem}>
              <div className={styles.popoverItemIcon}>
                <Checkbox
                  isChecked={selectedDocumentIds.includes(doc.id)}
                  onCheckboxChange={() => {
                    onSavedDocumentCheckboxChange(doc.id);
                  }}
                  theme="blue"
                />
                <FontAwesomeIcon icon={faFile} />
              </div>
              <p>{doc.title}</p>
            </div>
          ))}
        </Popover>
      )}
    </div>
  );
};
