// node_modules
import {
  faDownload,
  faLink,
  faLinkSlash,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons";
import {
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
// Components
import {
  ExportSearchResultsButton,
  LinkingModal,
  ListHeader,
} from "Components";
import { SavedDocumentItem } from "./SavedDocumentItem/SavedDocumentItem";
// Types
import {
  TButtonDefinition,
  TIdNameTypeObjectType,
  TOption,
  TOptions,
} from "Types";
// Enums
import {
  LinkStatusEnum,
  ObjectTypeEnum,
  SavedDocumentTypeEnum,
  SortTypeEnum,
} from "Enums";
// Constants
import { LinkingConstants, SavedDocumentConstants } from "Constants";
// Styles
import listHeaderStyles from "Components/Shared/Lists/ListHeader/listHeader.module.scss";
import entityLikeCardStyles from "Styles/entityLikeCard.module.scss";
import styles from "./savedDocuments.module.scss";
// Helpers
import {
  DocumentTypeHelperSingleton,
  LinkStatusHelperSingleton,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
  SavedFiltersHelperSingleton,
} from "Helpers";
// Custom hooks
import { useAnyLinkRemovedListener, useObjectLinkedListener } from "Hooks";
// Interfaces
import {
  IAskIgorModalOptions,
  ISavedDocumentDTO,
  convertFromISavedDocumentDTO,
} from "Interfaces";

type TSavedDocumentsProps = {
  linkedToObjectId?: string;
  header?: string;
  documents: ISavedDocumentDTO[];
  doUseSavedFilters: boolean;
  totalDocumentsCount: number;
  refreshDocumentsAsync: (
    fromDate: Date | undefined,
    selectedFilterOptions: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[],
    sortType: SortTypeEnum,
    callback?: (newSavedDocuments: ISavedDocumentDTO[]) => void
  ) => Promise<void>;
  deleteSavedDocumentAsync?: (
    savedDocumentsToDelete: ISavedDocumentDTO[]
  ) => Promise<void>;
  deleteIsUnlink?: boolean;
  doHideIfNoDocumentsLinked?: boolean;
  isInboxSection?: boolean;
  isEditable?: boolean;
  isObjectDetails?: boolean;
  setAskIgorModalOptions?: (
    value: SetStateAction<IAskIgorModalOptions>
  ) => void;
  extraClassName?: string;
  openDocumentModal?: {url: string, id?: string};
  onSelectedSavedDocumentsChange?: (selectedSavedDocuments: ISavedDocumentDTO[]) => void;
};

export const SavedDocuments: FC<TSavedDocumentsProps> = ({
  linkedToObjectId,
  header,
  documents,
  doUseSavedFilters,
  totalDocumentsCount,
  refreshDocumentsAsync,
  deleteSavedDocumentAsync,
  deleteIsUnlink,
  doHideIfNoDocumentsLinked,
  isInboxSection,
  isEditable = true,
  isObjectDetails,
  setAskIgorModalOptions,
  extraClassName,
  openDocumentModal,
  onSelectedSavedDocumentsChange
}: TSavedDocumentsProps) => {
  // State
  const [selectedSavedDocuments, setSelectedSavedDocuments] = useState<
    ISavedDocumentDTO[]
  >([]);
  const [sortType, setSortType] = useState<SortTypeEnum>(SortTypeEnum.Newest);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    TOption<SavedDocumentTypeEnum | LinkStatusEnum>[]
  >([]);
  const [lastPaginationFromDates, setLastPaginationFromDates] = useState<
    Date[]
  >([]);
  const [isAddLinkModalShown, setIsAddLinkModalShown] =
    useState<boolean>(false);
  const [totalSavedDocumentsCount, setTotalSavedDocumentsCount] =
    useState<number>(0);

  const selectedFilterOptionsForDropdown = useMemo(() => {
    const newSelectedFilterDropdownOptions: TOption<
      SavedDocumentTypeEnum | LinkStatusEnum
    >[] = [];

    for (const selectedFilterOption of selectedFilterOptions) {
      if (
        DocumentTypeHelperSingleton.allDocumentTypes.includes(
          selectedFilterOption.value as SavedDocumentTypeEnum
        )
      ) {
        newSelectedFilterDropdownOptions.push({
          value: selectedFilterOption.value,
          title: DocumentTypeHelperSingleton.getSavedDocumentTypeDisplayName(
            selectedFilterOption.value as SavedDocumentTypeEnum
          ),
        });
      } else if (
        (selectedFilterOption.value as LinkStatusEnum) ===
        LinkStatusEnum.NotLinked
      ) {
        newSelectedFilterDropdownOptions.push({
          value: selectedFilterOption.value,
          title: LinkStatusHelperSingleton.getLinkStatusDisplayName(
            selectedFilterOption.value as LinkStatusEnum
          ),
        });
      }
    }

    return newSelectedFilterDropdownOptions;
  }, [selectedFilterOptions]);

  // Logic
  useEffect(() => {
    // get saved filters in local storage if needed
    if (doUseSavedFilters) {
      const savedFilters: TOption<SavedDocumentTypeEnum | LinkStatusEnum>[] =
        SavedFiltersHelperSingleton.getDocumentsFilters(
          isInboxSection ? undefined : LinkStatusEnum.NotLinked
        );

      // if there are saved filters, set them as selected
      if (savedFilters.length > 0) {
        setSelectedFilterOptions(savedFilters);
      }
    }
  }, [doUseSavedFilters, isInboxSection]);

  useEffect(() => {
    setTotalSavedDocumentsCount(totalDocumentsCount);
  }, [totalDocumentsCount]);

  const isAllSavedDocumentsSelected = useMemo(() => {
    return (
      documents.length > 0 &&
      documents.every((element) =>
        selectedSavedDocuments.some((selected) => selected.id === element.id)
      )
    );
  }, [documents, selectedSavedDocuments]);

  const isAnySavedDocumentSelected = useMemo(() => {
    const someMatch = documents.some((element) =>
      selectedSavedDocuments.find((selected) => selected.id === element.id)
    );
    return someMatch && !isAllSavedDocumentsSelected;
  }, [documents, isAllSavedDocumentsSelected, selectedSavedDocuments]);

  const onSelectAllCheckboxChange = (isChecked: boolean) => {
    // If the document is not editable then don't do anything
    if (!isEditable) {
      return;
    }

    let newSelectedDocuments = [...selectedSavedDocuments];
    if (isChecked) {
      newSelectedDocuments = documents.filter(
        (document) =>
          !selectedSavedDocuments.some(
            (savedDocument) => savedDocument.id === document.id
          )
      );
      setSelectedSavedDocuments([
        ...selectedSavedDocuments,
        ...newSelectedDocuments,
      ]);
      onSelectedSavedDocumentsChange?.([
        ...selectedSavedDocuments,
        ...newSelectedDocuments,
      ]);
      // log
      LogHelperSingleton.log("SelectAllDocuments");
    } else {
      newSelectedDocuments = newSelectedDocuments.filter(
        (savedDocument) =>
          !documents.some((selected) => selected.id === savedDocument.id)
      );
      setSelectedSavedDocuments(newSelectedDocuments);
      onSelectedSavedDocumentsChange?.(newSelectedDocuments);
      // log
      LogHelperSingleton.log("UnselectAllDocuments");
    }
  };

  const onSavedDocumentCheckboxChange = (
    isChecked: boolean,
    newSelectedSavedDocument: ISavedDocumentDTO
  ) => {
    // If the document is not editable then don't do anything
    if (!isEditable) {
      return;
    }

    let newSelectedSavedDocuments = [...selectedSavedDocuments];
    if (isChecked) {
      newSelectedSavedDocuments.push(newSelectedSavedDocument);
      // log
      LogHelperSingleton.log("SelectDocument");
    } else {
      newSelectedSavedDocuments = newSelectedSavedDocuments.filter(
        (selectedSavedDocument) =>
          selectedSavedDocument.id !== newSelectedSavedDocument.id
      );
      // log
      LogHelperSingleton.log("UnselectDocument");
    }
    setSelectedSavedDocuments(newSelectedSavedDocuments);
    onSelectedSavedDocumentsChange?.(newSelectedSavedDocuments);
  };

  const updateFilterOptionsAsync = async (
    action: "add" | "remove",
    option: TOption<SavedDocumentTypeEnum | LinkStatusEnum>
  ): Promise<void> => {
    // reset last pagination from dates
    setLastPaginationFromDates([]);

    const newFilterOptions =
      action === "add"
        ? [...selectedFilterOptions].concat([option])
        : selectedFilterOptions.filter((opt) => opt.value !== option.value);
    setSelectedFilterOptions(newFilterOptions);

    // save filters in local storage if needed
    if (doUseSavedFilters) {
      SavedFiltersHelperSingleton.saveDocumentsFilters(newFilterOptions);
    }

    // update saved documents list
    refreshDocumentsAsync(undefined, newFilterOptions, sortType);

    // reset selected saved documents
    setSelectedSavedDocuments([]);
    onSelectedSavedDocumentsChange?.([]);

    // log
    LogHelperSingleton.log("FilterDocuments");
  };

  const updateSortTypeAsync = async (
    newSortType: SortTypeEnum
  ): Promise<void> => {
    // reset last pagination from dates
    setLastPaginationFromDates([]);

    // safety-checks
    if (newSortType === sortType) {
      return;
    }

    // set new sort type
    setSortType(newSortType);

    // update saved documents list
    refreshDocumentsAsync(undefined, selectedFilterOptions, newSortType);

    // reset selected saved documents
    setSelectedSavedDocuments([]);
    onSelectedSavedDocumentsChange?.([]);

    // log
    LogHelperSingleton.log("SortDocuments");
  };

  const onPaginatePreviousAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (lastPaginationFromDates && lastPaginationFromDates.length > 0) {
      lastPaginationFromDates.pop();
      if (lastPaginationFromDates.length >= 1) {
        fromDate = lastPaginationFromDates[lastPaginationFromDates.length - 1];
      }
      setLastPaginationFromDates(lastPaginationFromDates);
    }

    // update saved documents list
    await refreshDocumentsAsync(fromDate, selectedFilterOptions, sortType);

    // log
    LogHelperSingleton.log("GoToPreviousDocumentsPage");
  };

  const onPaginateNextAsync = async (): Promise<void> => {
    // get new from date
    let fromDate: Date | undefined = undefined;
    if (documents && documents.length > 0) {
      const lastPaginationFromDate: Date =
        documents[documents.length - 1].dateAdded;
      fromDate = lastPaginationFromDate;
      lastPaginationFromDates.push(fromDate);
      setLastPaginationFromDates(lastPaginationFromDates);
    }

    // update saved documents list
    await refreshDocumentsAsync(fromDate, selectedFilterOptions, sortType);

    // log
    LogHelperSingleton.log("GoToNextDocumentsPage");
  };

  const onLinkSavedDocuments = (savedDocument: ISavedDocumentDTO) => {
    // If the document is not editable then don't do anything
    if (!isEditable) {
      return;
    }

    setSelectedSavedDocuments([savedDocument]);
    onSelectedSavedDocumentsChange?.([savedDocument]);
    setIsAddLinkModalShown(true);
    // log
    LogHelperSingleton.log("StartLinkingDocument(s)");
  };

  const onLinkSavedDocumentsClick = useCallback(() => {
    // If the document is not editable then don't do anything
    if (!isEditable) {
      return;
    }

    setIsAddLinkModalShown(true);
    // log
    LogHelperSingleton.log("StartLinkingDocument(s)");
  }, [isEditable]);

  const onDeleteSavedDocumentsAsync = useCallback(
    async (savedDocumentsToDelete: ISavedDocumentDTO[]): Promise<void> => {
      // If the document is not editable or deletable then don't do anything
      if (!deleteSavedDocumentAsync || !isEditable) {
        return;
      }

      // Confirm with the user that they want to delete the documents
      if (savedDocumentsToDelete.length === 1) {
        if (deleteIsUnlink) {
          if (!confirm("Are you sure you want to unlink the document?")) return;
        } else {
          if (!confirm("Are you sure you want to delete the document?")) return;
        }
      } else {
        if (deleteIsUnlink) {
          if (
            !confirm("Are you sure you want to unlink the selected documents?")
          ) {
            return;
          }
        } else {
          if (
            !confirm("Are you sure you want to delete the selected documents?")
          ) {
            return;
          }
        }
      }

      for (const savedDocumentToDelete of savedDocumentsToDelete) {
        await deleteSavedDocumentAsync([savedDocumentToDelete]);
      }

      // update saved documents list
      await refreshDocumentsAsync(undefined, selectedFilterOptions, sortType);

      // reset selected saved documents
      setSelectedSavedDocuments([]);
      onSelectedSavedDocumentsChange?.([]);

      // set current saved documents count
      setTotalSavedDocumentsCount(
        totalSavedDocumentsCount - savedDocumentsToDelete.length
      );

      // log
      LogHelperSingleton.log("RemoveDocument(s)");
    },
    [
      deleteIsUnlink,
      deleteSavedDocumentAsync,
      isEditable,
      refreshDocumentsAsync,
      selectedFilterOptions,
      sortType,
      totalSavedDocumentsCount,
      onSelectedSavedDocumentsChange,
    ]
  );

  const onDeleteSavedDocumentsClick = useCallback(async () => {
    // If the document is not editable then don't do anything
    if (!isEditable) {
      return;
    }

    // safety-checks
    if (selectedSavedDocuments.length === 0) {
      return;
    }

    // delete saved documents
    onDeleteSavedDocumentsAsync(
      documents.filter((savedDocument) =>
        selectedSavedDocuments.includes(savedDocument)
      )
    );
  }, [
    documents,
    isEditable,
    onDeleteSavedDocumentsAsync,
    selectedSavedDocuments,
  ]);

  const getDropdownOptions = (): TOptions<
    SavedDocumentTypeEnum | LinkStatusEnum
  >[] => {
    // init default dropdown options
    const dropdownOptions: TOptions<SavedDocumentTypeEnum | LinkStatusEnum>[] =
      [...DocumentTypeHelperSingleton.savedDocumentDropdownOptions];

    // if is inbox section, add link status options
    if (isInboxSection) {
      dropdownOptions.push(
        ...LinkStatusHelperSingleton.linkStatusFilterDropdownOptions
      );
    }

    // return dropdown options
    return dropdownOptions;
  };

  const documentsHeaderButtons = useMemo(() => {
    const buttonDefintions = [
      {
        title: "Link",
        icon: faLink,
        onClick: onLinkSavedDocumentsClick,
        className: listHeaderStyles.linkIcon,
      },
    ] as TButtonDefinition[];

    if (deleteSavedDocumentAsync) {
      buttonDefintions.push({
        title: deleteIsUnlink ? "Unlink" : "Delete from inbox",
        icon: deleteIsUnlink ? faLinkSlash : faTrashCan,
        onClick: onDeleteSavedDocumentsClick,
        className: deleteIsUnlink
          ? listHeaderStyles.unlinkIcon
          : listHeaderStyles.trashIcon,
      });
    }

    return buttonDefintions;
  }, [
    deleteIsUnlink,
    deleteSavedDocumentAsync,
    onDeleteSavedDocumentsClick,
    onLinkSavedDocumentsClick,
  ]);

  // refresh selected saved documents
  const refreshSelectedSavedDocuments = useCallback(
    (newSavedDocuments: ISavedDocumentDTO[]) => {
      onSelectedSavedDocumentsChange?.(selectedSavedDocuments.filter(
        (selectedSavedDocument) => {
          return newSavedDocuments.some(
            (newSavedDocument) =>
              newSavedDocument.id === selectedSavedDocument.id
          );
        }
      ));
      setSelectedSavedDocuments((prevSelectedSavedDocuments) => {
        return prevSelectedSavedDocuments.filter(
          (prevSelectedSavedDocument) => {
            return newSavedDocuments.some(
              (newSavedDocument) =>
                newSavedDocument.id === prevSelectedSavedDocument.id
            );
          }
        );
      });
    },
    []
  );

  // refresh documents async with current options
  const refreshDocumentsAsyncWithCurrentOptions = useCallback(async () => {
    await refreshDocumentsAsync(
      lastPaginationFromDates[lastPaginationFromDates.length - 1],
      selectedFilterOptions,
      sortType,
      refreshSelectedSavedDocuments
    );
  }, [
    lastPaginationFromDates,
    refreshDocumentsAsync,
    refreshSelectedSavedDocuments,
    selectedFilterOptions,
    sortType,
  ]);

  // on object linked handler (useObjectLinkedListener custom hook, data pushed by the server)
  const onObjectLinked = useCallback(
    (fromObject: TIdNameTypeObjectType, toObject: TIdNameTypeObjectType) => {
      // safety-checks
      if (!linkedToObjectId || !documents) {
        return;
      }

      // if the object linked is the current object
      // and the to object is a document
      if (
        linkedToObjectId === fromObject.id &&
        (toObject.objectType === ObjectTypeEnum.ScienceArticle ||
          toObject.objectType === ObjectTypeEnum.UsPatent ||
          toObject.objectType === ObjectTypeEnum.MagPatent ||
          toObject.objectType === ObjectTypeEnum.Weblink) &&
        !documents.find((savedDocument) => savedDocument.id === toObject.id)
      ) {
        // refresh documents async with current options
        refreshDocumentsAsyncWithCurrentOptions();
      }
    },
    [documents, linkedToObjectId, refreshDocumentsAsyncWithCurrentOptions]
  );

  // on any link removed handler (useAnyLinkRemovedListener custom hook, data pushed by the server)
  const onLinkRemoved = useCallback(
    (fromId: string, toId: string) => {
      // safety-checks
      if (!linkedToObjectId || !documents) {
        return;
      }

      // if the from id is the current object id
      // and the to id is in object saved documents
      if (
        (linkedToObjectId === fromId &&
          documents.some((savedDocument) => savedDocument.id === toId)) ||
        (linkedToObjectId === toId &&
          documents.some((savedDocument) => savedDocument.id === fromId))
      ) {
        // refresh documents async with current options
        refreshDocumentsAsyncWithCurrentOptions();
      }
    },
    [documents, linkedToObjectId, refreshDocumentsAsyncWithCurrentOptions]
  );

  useEffect(() => {
    setAskIgorModalOptions?.((prevAskIgorModalOptions) => {
      return {
        ...prevAskIgorModalOptions,
        isOpen: false,
        documentsSelected: selectedSavedDocuments.map(
          (documentsSelected) => documentsSelected.id
        ),
      };
    });
  }, [selectedSavedDocuments, setAskIgorModalOptions]);

  useEffect(() => {
    setSelectedSavedDocuments([]);
  }, [linkedToObjectId]);

  // Custom hooks for Pub/Sub events handling
  useObjectLinkedListener(onObjectLinked);
  useAnyLinkRemovedListener(onLinkRemoved);

  // Render
  return doHideIfNoDocumentsLinked &&
    totalSavedDocumentsCount === 0 &&
    selectedFilterOptions.length === 0 ? null : (
    <>
      {" "}
      <div
        className={`${styles.savedDocumentHeaderContainer} ${
          extraClassName ? extraClassName : ""
        }`}
      >
        {header ? (
          <h1
            id={`${LinkingConstants.LINKED_DOCUMENTS_HEADER_ID}_${linkedToObjectId}`}
            className={entityLikeCardStyles.resultsTitle}
          >
            {header}
          </h1>
        ) : null}
        {header ? (
          <ExportSearchResultsButton
            icon={faDownload}
            buttonType={"tertiary"}
            searchResults={documents.map(convertFromISavedDocumentDTO)}
            tooltipText={"Export linked documents to CSV"}
            filename={"linked_documents.csv"}
          />
        ) : null}
      </div>
      <div
        className={`${styles.savedDocumentListContainer} ${
          extraClassName ? extraClassName : ""
        }`}
      >
        <ListHeader
          isAllListItemsSelected={isAllSavedDocumentsSelected}
          isAnyListItemSelected={isAnySavedDocumentSelected}
          onSelectAllCheckboxChange={
            isEditable ? onSelectAllCheckboxChange : undefined
          }
          selectedFilterOptions={selectedFilterOptionsForDropdown}
          updateFilterOptions={updateFilterOptionsAsync}
          sortType={sortType}
          updateSortType={updateSortTypeAsync}
          totalListItemCount={totalSavedDocumentsCount}
          onPaginatePrevious={onPaginatePreviousAsync}
          onPaginateNext={onPaginateNextAsync}
          filterOptions={getDropdownOptions()}
          listItemCountInterval={
            SavedDocumentConstants.MAXIMUM_SAVED_DOCUMENTS_TO_RETRIEVE
          }
          buttonDefinitions={documentsHeaderButtons}
          isObjectDetails={isObjectDetails}
          onOpenAskIgorModal={() => {
            setAskIgorModalOptions?.((prevAskIgorModalOptions) => {
              return {
                ...prevAskIgorModalOptions,
                isOpen: true,
                isMinimized: false,
                documentsSelected: selectedSavedDocuments.map(
                  (documentsSelected) => documentsSelected.id
                ),
              };
            });
          }}
        />
        <div className={styles.savedDocumentList}>
          {documents.map((savedDocument) => {
            const isSelected =
              selectedSavedDocuments.find(
                (document) => document.id === savedDocument.id
              ) !== undefined;
            return (
              <SavedDocumentItem
                key={savedDocument.id}
                savedDocument={savedDocument}
                isSelected={isSelected}
                onCheckboxChange={
                  isEditable ? onSavedDocumentCheckboxChange : undefined
                }
                onLinkSavedDocumentClick={
                  isEditable ? onLinkSavedDocuments : undefined
                }
                onDeleteSavedDocumentClick={
                  deleteSavedDocumentAsync
                    ? (savedDocumentToDelete: ISavedDocumentDTO) =>
                        onDeleteSavedDocumentsAsync([savedDocumentToDelete])
                    : undefined
                }
                deleteIsUnlink={deleteIsUnlink ? deleteIsUnlink : undefined}
                isInboxSection={isInboxSection ? isInboxSection : undefined}
                refreshDocuments={refreshDocumentsAsyncWithCurrentOptions}
                openDocumentModal={openDocumentModal}
              />
            );
          })}
        </div>
      </div>
      <LinkingModal
        isOpen={isAddLinkModalShown}
        setIsOpen={setIsAddLinkModalShown}
        defaultLinkType={LinkingConstants.CHILD_LINK_TYPE}
        forcedLinkType={LinkingConstants.CHILD_LINK_TYPE}
        selectedObjects={selectedSavedDocuments.map((document) => ({
          id: document.id,
          type: DocumentTypeHelperSingleton.getSavedDocumentTypeDisplayName(
            document.savedDocumentType
          ),
          objectType: ObjectTypeHelperSingleton.documentTypeToObjectType(
            document.savedDocumentType
          ),
          name: document.title,
        }))}
        onLinkingDoneAsync={async () =>
          await refreshDocumentsAsync(
            lastPaginationFromDates[lastPaginationFromDates.length - 1],
            selectedFilterOptions,
            sortType
          )
        }
      />
    </>
  );
};
