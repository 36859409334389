// node_modules
import { FC, useEffect, useState } from "react";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import { FindestButton, InformationBox } from "Components";
import { ConfigureAuthenticatorApp } from "./ConfigureAuthenticatorApp";
// Context
import { AuthContext } from "Providers";
// Controllers
import { AuthControllerSingleton } from "Controllers";
// Styles
import styles from "./twoFactorAuthenticationSettings.module.scss";
import { ToastHelperSingleton } from "Helpers";
import { ExtraSecurityViewTypeEnum, ToastTypeEnum } from "Enums";
import { RecoveryCodesView } from "./RecoveryCodesView";

export const TwoFactorAuthenticationSettings: FC = () => {
  const [extraSecurityView, setExtraSecurityView] =
    useState<ExtraSecurityViewTypeEnum>(
      ExtraSecurityViewTypeEnum.NotConfigured,
    );
  const [isTwoFactorConfigured, setIsTwoFactorConfigured] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);

  useEffect(() => {
    const fetchSecurityDetails = async () => {
      try {
        const details = await AuthControllerSingleton.getExtraSecurityDetails();
        if (!details || !details.isTwoFactorEnabled) {
          setExtraSecurityView(ExtraSecurityViewTypeEnum.NotConfigured);
        } else {
          setExtraSecurityView(ExtraSecurityViewTypeEnum.AlreadyConfigured);
          setIsTwoFactorConfigured(true); // Set 2FA status
        }
      } catch (err) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to load security details. Please try again.",
        );
      }
    };

    fetchSecurityDetails();
  }, []);

  const handleEnableTwoFactorAuthentication = async () => {
    setExtraSecurityView(ExtraSecurityViewTypeEnum.Setup);
  };

  const handleOnBack = async () => {
    if (isTwoFactorConfigured) {
      setExtraSecurityView(ExtraSecurityViewTypeEnum.AlreadyConfigured);
    } else {
      setExtraSecurityView(ExtraSecurityViewTypeEnum.NotConfigured);
    }
  };

  const resetRecoveryCodes = async () => {
    const result = await AuthControllerSingleton.resetRecoveryCodes();
    if (result && result.recoveryCodes.length > 0) {
      setRecoveryCodes(result.recoveryCodes);
      setExtraSecurityView(ExtraSecurityViewTypeEnum.ResetRecoveryCodes);
    } else {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Failed to reset recovery codes. Please try again.",
      );
    }
  };

  const resetAuthenticatorApp = async () => {
    const result = await AuthControllerSingleton.resetAuthenticatorApp();
    if (result) {
      setExtraSecurityView(ExtraSecurityViewTypeEnum.NotConfigured);
    }
  };

  const disable2FA = async () => {
    const result =
      await AuthControllerSingleton.disableTwoFactorAuthentication();
    if (result) {
      setExtraSecurityView(ExtraSecurityViewTypeEnum.NotConfigured);
    }
  };

  const handleOnVerify = async (recoveryCodes: string[]) => {
    setRecoveryCodes(recoveryCodes);
    setExtraSecurityView(ExtraSecurityViewTypeEnum.Verify);
  };

  const handleOnAcknowledged = async () => {
    setExtraSecurityView(ExtraSecurityViewTypeEnum.AlreadyConfigured);
  };

  return (
    <div className={styles.twoFactorAuthenticationSettingsTab}>
      <div
        className={`${styles.twoFactorAuthenticationSettings} ${extraSecurityView == ExtraSecurityViewTypeEnum.NotConfigured ? styles.configurationScreen : ""}`}
      >
        <h2 className={styles.header}>Two-factor authentication (2FA)</h2>
        {extraSecurityView == ExtraSecurityViewTypeEnum.NotConfigured && (
          <>
            <p className={styles.description}>
              Add an extra layer of security to your access to your Universe by
              enabling two-factor authentication. It will add an additional step
              to the login process, making it more secure.
            </p>
            <div className={styles.moreInformationSection}>
              <a
                target="_blank"
                href={process.env.REACT_APP_FINDEST_DOCS_URL}
                className={styles.moreInformation}
                rel="noreferrer"
              >
                More information
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className={styles.icon}
                />
              </a>
            </div>
            <InformationBox type="warning">
              <div className={styles.informationContent}>
                <h3>You have no two-factor authentication enabled</h3>
                <p className={styles.informationDesc}>
                  Start setting it up using the Microsoft Authenticator or Google Authenticator
                  app.
                </p>
              </div>
              <FindestButton
                title="Add Authenticator App"
                onClick={handleEnableTwoFactorAuthentication}
              />
            </InformationBox>
          </>
        )}
        {extraSecurityView == ExtraSecurityViewTypeEnum.Setup && (
          <ConfigureAuthenticatorApp
            onBack={handleOnBack}
            onVerify={handleOnVerify}
          />
        )}
        {extraSecurityView == ExtraSecurityViewTypeEnum.AlreadyConfigured && (
          <div>
            <p className={styles.description}>
              Add an extra layer of security to your access to your Universe by
              enabling two-factor authentication. It will add an additional step
              to the login process, making it more secure.
            </p>
            <div className={styles.moreInformationSection}>
              <a
                target="_blank"
                href={process.env.REACT_APP_FINDEST_DOCS_URL}
                className={styles.moreInformation}
                rel="noreferrer"
              >
                More information
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className={styles.icon}
                />
              </a>
            </div>
            <InformationBox type="success">
              <div className={styles.informationContent}>
                <p>
                  <b>You have two-factor authentication enabled</b> using
                  Microsoft Authenticator or Google Authenticator app
                </p>
              </div>
              <FindestButton
                isColorless
                title="Configure Authenticator App"
                onClick={handleEnableTwoFactorAuthentication}
              />
            </InformationBox>
            <div className={styles.moreActions}>
              <FindestButton
                title="Reset Recovery Codes"
                extraClassName={styles.actionButton}
                buttonType="secondary"
                onClick={resetRecoveryCodes}
              />
              <FindestButton
                title="Reset Authenticator App"
                extraClassName={styles.actionButton}
                buttonType="secondary"
                onClick={resetAuthenticatorApp}
              />
              <FindestButton
                extraClassName={styles.disable2FAButton}
                title="Disable 2FA"
                onClick={disable2FA}
              />
            </div>
          </div>
        )}
        {(extraSecurityView == ExtraSecurityViewTypeEnum.ResetRecoveryCodes ||
          extraSecurityView == ExtraSecurityViewTypeEnum.Verify) && (
          <RecoveryCodesView
            recoveryCodes={recoveryCodes}
            onAcknowledged={handleOnAcknowledged}
            extraSecurityViewType={extraSecurityView}
          />
        )}
      </div>
    </div>
  );
};
