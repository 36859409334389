// node_modules
import { FC, useContext } from "react";
// Constants
import { EmptyStateMessageConstants, TextConstants } from "Constants";
// Components
import { InformationBox, ObjectItem } from "Components";
// Types
import { TRecentSidebarActivityItemDTO, fromTRecentSidebarActivityItemDTO } from "Types";
// Styles
import styles from "./recentActivity.module.scss";
// Contexts
import { AuthContext, ElementVisibilityContext } from "Providers";

type TRecentActivityProps = {
    recentActivity: TRecentSidebarActivityItemDTO[];
}

export const RecentActivity: FC<TRecentActivityProps> = ({ recentActivity }: TRecentActivityProps) => {
    const { isUserExternal } = useContext(AuthContext);
    const { canUserEdit } = useContext(ElementVisibilityContext);

    return (
        <div className={styles.recentActivity}>
            {recentActivity.length > 0 && recentActivity
                .filter((a) => a && a.name && a.name.trim().length > 0)
                .sort((a: TRecentSidebarActivityItemDTO, b: TRecentSidebarActivityItemDTO) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                ).map((recentSidebarActivityItem: TRecentSidebarActivityItemDTO) => (
                    <ObjectItem
                        extraClassName={styles.objectItem}
                        key={recentSidebarActivityItem.id}
                        objectItem={fromTRecentSidebarActivityItemDTO(recentSidebarActivityItem)}
                        isEditable={false}
                        isUserExternal={isUserExternal}
                    />
                ))}
            {recentActivity.length === 0 && (
                <div className={styles.emptyContentContainer}>
                    <InformationBox
                        title={EmptyStateMessageConstants.EMPTY_RECENT_ITEMS}
                        subTitle={canUserEdit ? TextConstants.START_CREATING_BY_CLICKING_CREATE_BUTTON : TextConstants.RECENT_ACTIVITY_OF_YOU_WILL_BE_DISPLAYED_HERE}
                        type="information"
                    />
                </div>
            )}
        </div>
    );
};
