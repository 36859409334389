import { useContext, useEffect } from "react";
import { PubSubContext } from "Providers";
import { PubSubEventTypeEnum } from "Enums";

export const useContentGeneratedFromAskIgorListener = (onContentGeneratedFromAskIgor: (userId: string) => void) => {
  const { pubSubHandler } = useContext(PubSubContext);
  useEffect(() => {
    pubSubHandler.subscribeToEvent(PubSubEventTypeEnum.ContentGeneratedFromAskIgor, onContentGeneratedFromAskIgor);

    return () => {
      pubSubHandler.unsubscribeFromEvent(PubSubEventTypeEnum.ContentGeneratedFromAskIgor, onContentGeneratedFromAskIgor);
    };
  }, [onContentGeneratedFromAskIgor, pubSubHandler]);
};
