import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Checkbox,
  DocumentModal,
  FindestButton,
  LinkCreatedEntityModal,
  ObjectSearchPopupContent,
  OpenAccess,
  Popover,
  PubSubConnectedObjects,
} from "Components";
import { SearchMetadataBubble } from "./SearchMetadataBubble";
import { ExtractedMetadata } from "./ExtractedMetadata/ExtractedMetadata";
import {
  ReadDocumentsControllerSingleton,
  SavedDocumentControllerSingleton,
} from "Controllers";
import {
  EntityTypeEnum,
  LogFeatureNameEnum,
  SavedDocumentTypeEnum,
  ToastTypeEnum,
} from "Enums";
import {
  EntityHelperSingleton,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
import { PubSubContext, QueryContext } from "Providers";
import {
  THighlightDTO,
  TIdNameTypeObjectType,
  TImageDTO,
  TQueryViewOptions,
  TReadDocumentDTO,
} from "Types";
import {
  IDocumentDTO,
  IDocumentDetails,
  IDocumentExtractedMetadata,
  IDocumentSearchResult,
  IEntityDTO,
  fromIDocumentDetails,
  fromIDocumentSearchResult,
} from "Interfaces";
import styles from "./documentSearchResults.module.scss";
import { useClickOutsideRef, useLinkNewEntityToQuery } from "Hooks";
import { GeneralConstants } from "Constants";

type IDocumentSearchResultProps = {
  document: IDocumentSearchResult;
  doIncludeSaveButton?: boolean;
  queryViewOptions?: TQueryViewOptions;
  updateDocument?: (document: IDocumentSearchResult) => void;
  hideZeroScoreMetadata?: boolean;
  extractedMetadata?: IDocumentExtractedMetadata;
  doIncludeCheckbox?: boolean;
  onAddToSelected?: (document: IDocumentSearchResult) => void;
  checked?: boolean;
  orderNumber?: number;
  referenceNumber?: number;
  documentModal?: string;
};

export const DocumentSearchResult: FC<IDocumentSearchResultProps> = ({
  document,
  queryViewOptions,
  doIncludeSaveButton,
  doIncludeCheckbox,
  updateDocument,
  hideZeroScoreMetadata,
  extractedMetadata,
  onAddToSelected,
  checked,
  orderNumber,
  referenceNumber,
  documentModal
}) => {
  // Context
  const { query } = useContext(QueryContext);
  const { pubSubHandler } = useContext(PubSubContext);

  // State
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSavePopupOpen, setIsSavePopupOpen] = useState<boolean>(false);
  const [isCreatedEntityModalOpen, setIsCreatedEntityModalOpen] =
    useState<boolean>(false);
  const [documentElementReference, setDocumentElementReference] =
    useState<HTMLDivElement | null>(null);
  const [creatingEntity, setCreatingEntity] =
    useState<IEntityDTO | undefined>(undefined);
  const [documentImages, setDocumentImages] = useState<TImageDTO[]>([]);
  const [documentHighlights, setDocumentHighlights] = useState<THighlightDTO[]>(
    []
  );
  const [documentCreatedByUsername, setDocumentCreatedByUsername] =
    useState<string>("");
  const [documentAddedDate, setDocumentAddedDate] =
    useState<Date | undefined>(undefined);

  // Ref
  const documentSearchResultsMetadataRef = useRef<HTMLDivElement>(null);
  const saveContainerElementRef = useRef<HTMLDivElement>(null);

  // Memos
  const documentObjectType = useMemo(() => {
    return ObjectTypeHelperSingleton.documentTypeToObjectType(
      document.documentType
    );
  }, [document.documentType]);

  const affiliations = useMemo(() => {
    if (document.searchInformation?.fixedPatentAssignees) {
      return [document.searchInformation?.fixedPatentAssignees];
    }
    const affiliationSet = new Set(
      (document.authorships ?? [])
        .filter((authorship) => !!authorship.institutionName)
        .map((authorship) => authorship.institutionName ?? "")
    );
    return Array.from(affiliationSet);
  }, [document.authorships, document.searchInformation?.fixedPatentAssignees]);

  const authors = useMemo(() => {
    if (document.searchInformation?.fixedPatentAuthors) {
      return [document.searchInformation?.fixedPatentAuthors];
    }

    const authorSet = new Set(
      (document.authorships ?? [])
        .filter((authorship) => !!authorship.authorName)
        .map((authorship) => authorship.authorName ?? "")
    );
    return Array.from(authorSet);
  }, [document.authorships, document.searchInformation?.fixedPatentAuthors]);

  const doHidePatent = useMemo(() => {
    if (!query?.filters?.patentFilters) return false;
    if (
      !(
        document.documentType === SavedDocumentTypeEnum.UsPatent ||
        document.documentType === SavedDocumentTypeEnum.MagPatent
      )
    ) {
      return false;
    }

    const countryFilterType = query.filters.patentFilters.countryFilterType;
    const countryNameSet = new Set(
      query.filters.patentFilters.filteredCountries
    );

    if (
      !countryFilterType ||
      !document.searchInformation?.patentCountries ||
      (document.searchInformation.patentCountries.length === 0 &&
        countryNameSet.size === 0)
    ) {
      return false;
    }

    if (countryFilterType === 1) {
      // Show only is selected
      for (const patentCountry of document.searchInformation.patentCountries) {
        if (!countryNameSet.has(patentCountry)) {
          return true;
        }
      }
    } else if (countryFilterType === 2) {
      // Exclude is selected
      for (const patentCountry of document.searchInformation.patentCountries) {
        if (countryNameSet.has(patentCountry)) {
          return true;
        }
      }
    }

    return false;
  }, [
    document.documentType,
    document.searchInformation?.patentCountries,
    query?.filters.patentFilters,
  ]);

  // Hooks
  useClickOutsideRef(
    saveContainerElementRef,
    () => {
      setIsSavePopupOpen(false);
    },
    [],
    GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
  );
  const { linkNewEntityToQueryAsync } = useLinkNewEntityToQuery(
    query,
    setIsCreatedEntityModalOpen,
    fromIDocumentSearchResult(document),
    documentObjectType,
    updateDocument
      ? (documentToUpdate: IDocumentDetails) => {
        updateDocument(fromIDocumentDetails(documentToUpdate));
      }
      : undefined
  );

  const onSaveElementClickAsync = async (
    element: TIdNameTypeObjectType,
    currentDocument: IDocumentSearchResult,
    currentUpdateDocument?: (document: IDocumentSearchResult) => void
  ): Promise<void> => {
    // close save popup
    setIsSavePopupOpen(false);

    // if currentUpdateDocument is not set
    if (!currentUpdateDocument) {
      // show error message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        `Could not link ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
          element.objectType
        ).toLowerCase()} to document.`
      );
      return;
    }

    await EntityHelperSingleton.createEntityFromQuery(
      element,
      currentDocument,
      pubSubHandler,
      query
    );

    // update document
    currentUpdateDocument(currentDocument);
  };

  const onCreateNewEntity = async (text: string) => {
    setIsCreatedEntityModalOpen(true);
    setCreatingEntity({
      title: text,
      type: EntityTypeEnum.Undefined,
    } as IEntityDTO);
  };

  const onSaveButtonClick = () => {
    setIsSavePopupOpen(!isSavePopupOpen);
  };

  const onSaveButtonEnter = () => {
    setIsSavePopupOpen(true);
  };

  const onSaveButtonLeave = () => {
    setIsSavePopupOpen(false);
  };

  const onOpenDocumentModalClick = useCallback(async () => {
    // get document by id in order to retrieve images and highlights linked to document search result
    // because it was easier to do it this way than to change the backend (too much coupling and time limited)
    // to see why: see Universe(Patent/Science)ArticleSearchStrategy.cs, we would have to use the LinkingLogic in order to get the images and highlights
    const documentWithImagesAndHighlights: IDocumentDTO | undefined =
      await SavedDocumentControllerSingleton.getByIdAsyncV2(document.documentId);

    // safety-checks
    if (documentWithImagesAndHighlights) {
      // set document images
      setDocumentImages(documentWithImagesAndHighlights.images);

      // set document highlights
      setDocumentHighlights(documentWithImagesAndHighlights.highlights);

      // set document created by username amd added date
      if (
        documentWithImagesAndHighlights.createdByUsername &&
        documentWithImagesAndHighlights.dateAdded
      ) {
        setDocumentCreatedByUsername(
          documentWithImagesAndHighlights.createdByUsername
        );
        setDocumentAddedDate(documentWithImagesAndHighlights.dateAdded);
      }
    }

    // add document to read document
    const readDocument: TReadDocumentDTO | undefined =
      await ReadDocumentsControllerSingleton.addAsync(document.documentId);

    // update document
    if (updateDocument) {
      updateDocument({
        ...document,
        isAlreadyRead: true,
      });
    }

    // safety-checks
    if (!readDocument) {
      // show error message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not add document to read documents."
      );
    }

    LogHelperSingleton.logWithProperties("OpenDocument", {
      DocumentId: document.documentId,
      ...(query ? { ActionOrigin: LogFeatureNameEnum.AdvancedSearch } : {}),
    });

    // set is modal open to true
    setIsModalOpen(true);
  }, [document, query, updateDocument]);

  const documentDetails = useMemo((): IDocumentDetails => {
    // see explanation in onOpenDocumentModalClick to understand why we have this hook

    // convert document search result to document details
    const newDocumentDetails: IDocumentDetails =
      fromIDocumentSearchResult(document);

    // set images and highlights
    newDocumentDetails.images = documentImages;
    newDocumentDetails.highlights = documentHighlights;
    if (documentCreatedByUsername && documentAddedDate) {
      newDocumentDetails.createdByUsername = documentCreatedByUsername;
      newDocumentDetails.dateAdded = documentAddedDate;
    }

    // return new document details
    return newDocumentDetails;
  }, [
    document,
    documentHighlights,
    documentImages,
    documentCreatedByUsername,
    documentAddedDate,
  ]);

  const isValidValue = (value: number | string | null | undefined): boolean => {
    if (value === undefined || value === null) {
      return false;
    }

    const numberValue = typeof value === "string" ? parseFloat(value) : value;

    return !isNaN(numberValue) && !(numberValue === 0 && Boolean(hideZeroScoreMetadata));
  };

  const shouldShowScore = (score?: number | null): boolean => {
    return isValidValue(score);
  };

  const shouldShowImpactFactor = (impactFactor?: string | null): boolean => {
    return isValidValue(impactFactor);
  };

  useEffect(() => {
    if (documentModal === document.documentId) {
      onOpenDocumentModalClick();
    }
  }, [documentModal]);

  // Check if document should be filtered out based on country filter
  if (doHidePatent) return null;

  return (
    <div className={styles.documentSearchResultContainer}>
      {doIncludeSaveButton && updateDocument && (
        <div
          className={styles.saveContainer}
          onMouseEnter={onSaveButtonEnter}
          onMouseLeave={onSaveButtonLeave}
          ref={saveContainerElementRef}
        >
          <div
            className={styles.refContainer}
            ref={setDocumentElementReference}
          ></div>
          <FindestButton
            title="Save"
            buttonType={"secondary"}
            onClick={onSaveButtonEnter}
          />
          <Popover
            referenceEl={documentElementReference}
            placement="right-start"
            isOpen={isSavePopupOpen}
            extraClassName={styles.objectSearchPopupContainer}
          >
            <ObjectSearchPopupContent
              currentObjectId={document.documentId}
              onElementClick={async (result: TIdNameTypeObjectType) => {
                await onSaveElementClickAsync(result, document, updateDocument);
              }}
              doShowRecentActivity={true}
              doShowCreateButton={true}
              onCreateClick={onCreateNewEntity}
              initialLinkedObjects={query?.connectedObjects}
              initialLinkedObjectsTitle="Query connections"
              moreActionsDropdownPopoverDataIdentifier={
                GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
              }
            />
          </Popover>
        </div>
      )}
      {doIncludeCheckbox && (
        <Checkbox
          extraClassNames={{ container: styles.checkbox }}
          theme="black"
          isChecked={checked}
          onCheckboxChange={() => {
            onAddToSelected?.(document);
          }}
        />
      )}
      <div className={styles.documentSearchResultContentContainer}>
        {document.isOpenAccess && <OpenAccess />}
        <button type="button" onClick={onOpenDocumentModalClick}>
          <h3 className={document.isAlreadyRead ? styles.isAlreadyRead : ""}>
            {orderNumber && <span className={styles.answerReference}>{orderNumber}</span>}
            {document.title}
            {referenceNumber && <span>{referenceNumber}</span>}
          </h3>
        </button>
        {query && extractedMetadata && (
          <ExtractedMetadata
            query={query}
            document={document}
            extractedMetadata={extractedMetadata}
            updateDocument={updateDocument}
          />
        )}
        {queryViewOptions && (
          <div
            className={styles.documentSearchResultsMetadata}
            ref={documentSearchResultsMetadataRef}
          >
            {queryViewOptions.isPublicationDateViewOptionChecked &&
              document.searchInformation?.publicationDate && (
                <SearchMetadataBubble
                  text={document.searchInformation.publicationDate.toString()}
                  title="Publication date"
                  referenceContainer={documentSearchResultsMetadataRef}
                />
              )}
            {queryViewOptions.isFilingDateViewOptionChecked &&
              document.searchInformation?.filingDate && (
                <SearchMetadataBubble
                  text={document.searchInformation.filingDate.toString()}
                  title="Filing date"
                  referenceContainer={documentSearchResultsMetadataRef}
                />
              )}
            {queryViewOptions.isRelevanceScoreViewOptionChecked &&
              shouldShowScore(document?.searchInformation?.score) && (
                <SearchMetadataBubble
                  text={document?.searchInformation?.score ?? ""}
                  title="Relevance score"
                  referenceContainer={documentSearchResultsMetadataRef}
                />
              )}
            {queryViewOptions.isCitationScoreViewOptionChecked &&
              shouldShowImpactFactor(
                document?.searchInformation?.impactFactor
              ) && (
                <SearchMetadataBubble
                  text={document?.searchInformation?.impactFactor ?? ""}
                  title="Citation score"
                  referenceContainer={documentSearchResultsMetadataRef}
                />
              )}
            {((queryViewOptions.isAffiliationViewOptionChecked &&
              document.documentType === SavedDocumentTypeEnum.ScienceArticle) ||
              (queryViewOptions.isAssigneeViewOptionChecked &&
                (document.documentType === SavedDocumentTypeEnum.UsPatent ||
                  document.documentType ===
                  SavedDocumentTypeEnum.MagPatent))) &&
              affiliations.length > 0 &&
              affiliations.map((affiliation) => {
                return (
                  <SearchMetadataBubble
                    key={affiliation}
                    text={affiliation}
                    title="Affiliation"
                    referenceContainer={documentSearchResultsMetadataRef}
                  />
                );
              })}
            {((queryViewOptions.isAuthorViewOptionChecked &&
              document.documentType === SavedDocumentTypeEnum.ScienceArticle) ||
              (queryViewOptions.isInventorViewOptionChecked &&
                (document.documentType === SavedDocumentTypeEnum.UsPatent ||
                  document.documentType ===
                  SavedDocumentTypeEnum.MagPatent))) &&
              authors.length > 0 &&
              authors.map((author) => {
                return (
                  <SearchMetadataBubble
                    key={author}
                    text={author}
                    title="Author"
                    referenceContainer={documentSearchResultsMetadataRef}
                  />
                );
              })}
            {queryViewOptions.isMatchedTermsViewOptionChecked &&
              document.searchInformation?.matchedTerms?.map((matchedTerm) => {
                return (
                  <SearchMetadataBubble
                    key={matchedTerm.searchTerm}
                    text={matchedTerm.searchTerm}
                    title="Matched term"
                    referenceContainer={documentSearchResultsMetadataRef}
                  />
                );
              })}
            {queryViewOptions.isPatentCountryViewOptionChecked &&
              document.searchInformation?.patentCountries?.map(
                (patentCountry) => {
                  return (
                    <SearchMetadataBubble
                      key={patentCountry}
                      text={patentCountry}
                      title="Country"
                      referenceContainer={documentSearchResultsMetadataRef}
                    />
                  );
                }
              )}
            {queryViewOptions.isPatentNumberViewOptionChecked &&
              document.searchInformation?.patentNumbers?.map((patentNumber) => {
                return (
                  <SearchMetadataBubble
                    key={patentNumber}
                    text={patentNumber}
                    title="Patent number"
                    referenceContainer={documentSearchResultsMetadataRef}
                  />
                );
              })}
          </div>
        )}
        <PubSubConnectedObjects
          mainObjectId={document.documentId}
          mainObjectType={documentObjectType}
          connectedObjects={document.connectedObjects ?? []}
          onConnectToObjectClick={onSaveButtonClick}
          extraClassName={styles.connectedObjectsContainer}
          disableConnectToNewObjectButton={true}
          doHideTitleOnEmptyOrUnsetConnectedObjects={true}
        />
        <DocumentModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onAddImage={(image) => {
            setDocumentImages([...documentImages, image]);
          }}
          onDeleteImage={(image) => {
            setDocumentImages(
              documentImages.filter(
                (currentImage) => currentImage.id !== image.id
              )
            );
          }}
          updateHighlights={(highlights) => {
            setDocumentHighlights(highlights);
          }}
          document={documentDetails}
          updateDocument={
            updateDocument
              ? (updatedDocument: IDocumentDetails) => {
                updateDocument(fromIDocumentDetails(updatedDocument));
              }
              : undefined
          }
          onSaveElementClick={async (
            element: TIdNameTypeObjectType,
            closeSavePopupCallback?: (() => void) | undefined
          ) => {
            await onSaveElementClickAsync(element, document, updateDocument);
            if (closeSavePopupCallback) {
              closeSavePopupCallback();
            }
          }}
          keywordsToHighlight={extractedMetadata?.igorHits ?? []}
        />
        {isCreatedEntityModalOpen && (
          <LinkCreatedEntityModal
            isOpen={isCreatedEntityModalOpen}
            setIsOpen={setIsCreatedEntityModalOpen}
            creatingEntity={creatingEntity}
            onCreateEntityClickAsync={linkNewEntityToQueryAsync}
          />
        )}
      </div>
    </div>
  );
};
