// node_modules
import {
  faMaximize,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dagre from "dagre";
import {
  DragEventHandler,
  FC,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactFlow, {
  Connection,
  ControlButton,
  Controls,
  Edge,
  EdgeTypes,
  FitViewOptions,
  HandleType,
  Node,
  NodeTypes,
  OnConnectStartParams,
  Position,
  ReactFlowInstance,
  XYPosition,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from "reactflow";
// Components
import { ButtonEdge } from "./Edges/ButtonEdge";
import { EntityNode, StudyNode } from "./Nodes";
// Styles
import "reactflow/dist/style.css";
import styles from "./linkGraphView.module.scss";
// Enums
import { ObjectTypeEnum } from "Enums";
// Types
import { LogHelperSingleton } from "Helpers";
import { TIdNameTypeObjectType, TReactFlowNode, TUseDragAndDrop } from "Types";

type TLinkGraphViewProps = {
  initialNodes: Node<TReactFlowNode>[];
  initialEdges: Edge[];
  onLoadMoreClickAsync: (
    nodeId: string,
    nodeType: ObjectTypeEnum
  ) => Promise<void>;
  onAddNewLinkClick: (
    data: TReactFlowNode,
    defaultLinkTypeValue: string
  ) => void;
  onCollapseOrExpandClick: (isCollapsed: boolean, id: string) => void;
  onEdgesDelete: (edges: Edge[]) => void;
  onEdgeDelete: (edgeId: string) => void;
  onConnect: (connection: Connection) => Promise<void>;
  onEdgeUpdate: (oldEdge: Edge, newConnection: Connection) => void;
  isViewOnlyMode: boolean;
  isFullscreen: boolean;
  setIsFullScreen?: (isFullScreen: boolean) => void;
  onDropEnd: (
    position: XYPosition,
    draggedObject: TIdNameTypeObjectType
  ) => void;
  onNodeDragStop: (event: React.MouseEvent, node: Node, nodes: Node[]) => void;
  useDragAndDropProps: TUseDragAndDrop;
  setIsLinksWindowSearchBarResultsElementActive?: (
    isLinksWindowSearchBarResultsElementActive: boolean
  ) => void;
};

export const LinkGraphView: FC<TLinkGraphViewProps> = ({
  initialNodes,
  initialEdges,
  onLoadMoreClickAsync,
  onAddNewLinkClick,
  onCollapseOrExpandClick,
  onEdgesDelete,
  onEdgeDelete,
  onConnect,
  onEdgeUpdate,
  isViewOnlyMode,
  isFullscreen,
  setIsFullScreen,
  useDragAndDropProps,
  onDropEnd,
  onNodeDragStop,
  setIsLinksWindowSearchBarResultsElementActive,
}: TLinkGraphViewProps) => {
  // Constants
  const nodeWidth = 178;
  const nodeHeight = 98;
  const fitViewOptions: FitViewOptions = { padding: 0.2 };
  const nodeTypes: NodeTypes = useMemo(() => {
    return {
      entityNode: (props) => (
        <EntityNode
          {...props}
          isViewOnlyMode={isViewOnlyMode}
          onLoadMoreClickAsync={onLoadMoreClickAsync}
          onAddNewLinkClick={onAddNewLinkClick}
          onCollapseOrExpandClick={onCollapseOrExpandClick}
          onDragOver={useDragAndDropProps.onDragOver}
          onDragLeave={useDragAndDropProps.onDragLeave}
        />
      ),
      studyNode: (props) => (
        <StudyNode
          {...props}
          isViewOnlyMode={isViewOnlyMode}
          onLoadMoreClickAsync={onLoadMoreClickAsync}
          onAddNewLinkClick={onAddNewLinkClick}
          onCollapseOrExpandClick={onCollapseOrExpandClick}
          onDragOver={useDragAndDropProps.onDragOver}
          onDragLeave={useDragAndDropProps.onDragLeave}
        />
      ),
    };
  }, [
    isViewOnlyMode,
    onLoadMoreClickAsync,
    onAddNewLinkClick,
    onCollapseOrExpandClick,
    useDragAndDropProps.onDragOver,
    useDragAndDropProps.onDragLeave,
  ]);
  const edgeTypes: EdgeTypes = useMemo(() => {
    return {
      buttonedge: (props) => (
        <ButtonEdge
          {...props}
          isViewOnlyMode={isViewOnlyMode}
          onEdgeDelete={onEdgeDelete}
        />
      ),
    };
  }, [isViewOnlyMode, onEdgeDelete]);

  // State
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [graphDirection, setGraphDirection] = useState<"TB" | "LR">("TB");
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>(null);

  const store = useStoreApi();
  const { setCenter } = useReactFlow();

  // Logic
  // init dagre graph
  const dagreGraph = useMemo(() => {
    const newDagreGraph: dagre.graphlib.Graph = new dagre.graphlib.Graph();
    newDagreGraph.setDefaultEdgeLabel(() => ({}));
    return newDagreGraph;
  }, []);

  // Effects
  useEffect(() => {
    store.getState().onError = (code, message) => {
      if (code === "002") {
        /*
                    Whenever we change focus on the tree graph, it triggers the change of nodeTypes.
                    Because nodeTypes depends on onReanchorClick function,
                    and that function (in LinkGraphContext.tsx) is depending on objectIdEdited, objectTypeEdited.
                    Since they have to change when changing focus, reactflow throws this warning:
                        "It looks like you have created a new nodeTypes or edgeTypes object.
                        If this wasn't on purpose please define the nodeTypes/edgeTypes outside of the component or memoize them."
                    That's why we are ignoring this warning.
                */
        return;
      }
      console.warn(message);
    };
  }, [store]);

  const getLayoutedElements = useCallback(
    (
      newNodes: Node<TReactFlowNode>[],
      newEdges: Edge[],
      direction: "TB" | "LR" = "TB"
    ) => {
      // set direction of dagre graph elements
      const isHorizontal = direction === "LR";
      dagreGraph.setGraph({ rankdir: direction });

      // add nodes to dagre graph
      newNodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
      });

      // add edges to dagre graph
      newEdges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
      });

      // set dagre layout
      dagre.layout(dagreGraph);

      newNodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal
          ? ("left" as Position)
          : ("top" as Position);
        node.sourcePosition = isHorizontal
          ? ("right" as Position)
          : ("bottom" as Position);
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        };

        if (
          node.data.customPosition &&
          !isNodeLinkedToAnotherNode(node, newEdges)
        ) {
          node.position = node.data.customPosition;
        }

        // set direct children count of the node
        const directChildrenCount = newEdges.filter(
          (edge) => edge.source === node.id
        ).length;
        node.data.directChildrenCount = directChildrenCount;

        return node;
      });

      return { nodes: newNodes, edges: newEdges };
    },
    [dagreGraph]
  );

  const isNodeLinkedToAnotherNode = (
    node: Node<TReactFlowNode>,
    currEdges: Edge[]
  ) => {
    return !!currEdges.find(
      (edge) => edge.source === node.id || edge.target === node.id
    );
  };

  useEffect(() => {
    const nodeIds = nodes.map((node) => node.id);
    const initialNodesIds = initialNodes.map((n) => n.id);
    const edgeIds = edges.map((edge) => edge.id);
    const initialEdgeIds = initialEdges.map((e) => e.id);

    if (
      JSON.stringify(nodeIds) === JSON.stringify(initialNodesIds) &&
      JSON.stringify(edgeIds) === JSON.stringify(initialEdgeIds)
    ) {
      return;
    }

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      initialNodes,
      initialEdges,
      graphDirection
    );
    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);
  }, [
    graphDirection,
    initialNodes,
    initialEdges,
    getLayoutedElements,
    setNodes,
    setEdges,
    edges,
    nodes,
  ]);

  // Actions logic
  const onLayout = useCallback(
    (direction: "TB" | "LR") => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
      setGraphDirection(direction);
      LogHelperSingleton.log(`StructureGraphLayout${direction}`);
    },
    [nodes, edges, getLayoutedElements, setNodes, setEdges]
  );

  const onConnectEnd = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_event: MouseEvent | TouchEvent) => {
      const newNodes = nodes.map((node) => {
        return {
          ...node,
          data: { ...node.data, availableConnectionType: null },
        };
      });
      setNodes(newNodes);
    },
    [nodes, setNodes]
  );

  /** Log the dragging of the view or a node */
  const logPaneClicked = () => {
    LogHelperSingleton.log("StructureGraphClicked");
    if (setIsLinksWindowSearchBarResultsElementActive) {
      setIsLinksWindowSearchBarResultsElementActive(false);
    }
  };

  /** Visually focuses to a reactflow node */
  const focusNode = useCallback(
    (nodeId: string) => {
      const { nodeInternals } = store.getState();
      const nodeInternalValues = Array.from(nodeInternals).map(
        ([, node]) => node
      );

      if (nodeInternalValues.length > 0) {
        const node = nodeInternalValues.find((n) => n.id === nodeId);
        if (node) {
          const x = node.position.x + (node.width || 0) / 2;
          const y = node.position.y + (node.height || 0) / 2;
          const zoom = 1.85;

          setCenter(x, y, { zoom, duration: 1000 });
        }
      }
    },
    [setCenter, store]
  );

  /** Called when user starts to drag connection line */
  const onConnectStart = useCallback(
    (
      _event: ReactMouseEvent | ReactTouchEvent,
      connectStartParams: OnConnectStartParams
    ) => {
      const newNodes = nodes.map((node) => {
        const availableHandle =
          connectStartParams.handleType === "source" ? "target" : "source";
        return {
          ...node,
          data: {
            ...node.data,
            availableConnectionType: availableHandle as HandleType,
          },
        };
      });
      setNodes(newNodes);
    },
    [nodes, setNodes]
  );

  const onDragOver: DragEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
      useDragAndDropProps.onDragOver(event, {
        id: "linkGraph",
        name: "linkGraph",
        type: "",
        objectType: 0,
      });
    },
    [useDragAndDropProps]
  );

  const onDrop: DragEventHandler<HTMLDivElement> = useCallback(
    async (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current
        ? (reactFlowWrapper.current as HTMLElement).getBoundingClientRect()
        : ({} as DOMRect);
      const draggedObject = useDragAndDropProps.draggedObject;

      // check if the dropped element is valid
      if (
        typeof draggedObject?.objectType === "undefined" ||
        !draggedObject?.objectType
      ) {
        return;
      }

      useDragAndDropProps.onDrop(event, (object: TIdNameTypeObjectType) => {
        if (useDragAndDropProps.draggedOverObject?.id === "linkGraph") {
          // if object is already in the graph, visually focus to that node
          if (
            useDragAndDropProps.draggedObject &&
            nodes.find(
              (node) => node.id === useDragAndDropProps.draggedObject?.id
            )
          ) {
            focusNode(useDragAndDropProps.draggedObject.id);
            return;
          } else {
            // if object is not in the graph
            const position = reactFlowInstance?.project({
              x: event.clientX - reactFlowBounds.left,
              y: event.clientY - reactFlowBounds.top,
            });

            const newNode = {
              ...object,
              position,
            };

            if (position) {
              onDropEnd(position, newNode);
            }
          }
        }
      });
    },
    [reactFlowInstance, useDragAndDropProps, nodes, onDropEnd, focusNode]
  );

  return (
    <>
      <div
        ref={reactFlowWrapper}
        className={`${styles.linkGraphContainer} ${
          isFullscreen ? styles.fullscreen : ""
        }`}
      >
        <ReactFlow
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgesDelete={onEdgesDelete}
          onConnect={onConnect}
          onPointerDown={logPaneClicked}
          fitView
          fitViewOptions={fitViewOptions}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          multiSelectionKeyCode={null}
          onInit={setReactFlowInstance}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onEdgeUpdate={onEdgeUpdate}
          onNodeDragStop={onNodeDragStop}
        >
          <Controls className={styles.controls} position="top-right">
            <ControlButton onClick={() => onLayout("LR")}>
              <div title="Horizontal">
                <FontAwesomeIcon
                  className={styles.horizontal}
                  icon={faUpRightAndDownLeftFromCenter}
                />
              </div>
            </ControlButton>
            <ControlButton onClick={() => onLayout("TB")}>
              <div title="Vertical">
                <FontAwesomeIcon
                  className={styles.vertical}
                  icon={faUpRightAndDownLeftFromCenter}
                />
              </div>
            </ControlButton>
            {!isFullscreen && setIsFullScreen && (
              <ControlButton
                onClick={() => {
                  setIsFullScreen(true);
                }}
              >
                <div title="Full screen">
                  <FontAwesomeIcon icon={faMaximize} />
                </div>
              </ControlButton>
            )}
          </Controls>
        </ReactFlow>
      </div>
    </>
  );
};
