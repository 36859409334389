import { useCallback, useEffect, useRef, useState } from "react";
import { TIdNameTypeObjectType } from "Types";
import { GeneralConstants } from "Constants";
import { ObjectTypeEnum } from "Enums";
import { StringHelperSingleton, URLHelperSingleton } from "Helpers";

interface IUseReferenceLinkProps {
  ref: HTMLDivElement | null;
  click?: boolean;
}

export const useReference = ({ ref, click }: IUseReferenceLinkProps) => {
  const [referenceLink, setReferenceLink] =
    useState<TIdNameTypeObjectType | null>(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLElement | undefined>(undefined);
  const [referenceId, setReferenceId] = useState<string | undefined>(undefined);

  const setReferenceLinkTimeout = useRef<NodeJS.Timeout | null>(null);

  const clearSetReferenceLinkTimeout = useCallback(() => {
    if (setReferenceLinkTimeout.current) {
      clearTimeout(setReferenceLinkTimeout.current);
      setReferenceLinkTimeout.current = null;
    }
  }, []);

  const resetReference = useCallback(() => {
    setReferenceElement(undefined);
    setReferenceLink(null);
    clearSetReferenceLinkTimeout();
  }, [clearSetReferenceLinkTimeout]);

  useEffect(() => {
    const refElement = ref;

    const mouseOverHandler = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const id = target.getAttribute("id");
      const type = target.getAttribute("type");
      const url = target.getAttribute("href");

      if (!["A", "SPAN"].includes(target.tagName) || !URLHelperSingleton.isValidUrl(StringHelperSingleton.sanitizeString(url), true)) {
        resetReference();
        return;
      }

      setReferenceLinkTimeout.current = setTimeout(() => {
        setReferenceLink({
          id: id ?? "",
          objectType: type
            ? parseInt(type, 10)
            : ObjectTypeEnum.Weblink,
          type: "",
          name: url ?? "",
        });
      }, GeneralConstants.DEFAULT_POPOVER_MS_DELAY);
      setReferenceElement(target);
    };

    const clickHandler = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const id = target.getAttribute("id");
      
      if (!["A", "SPAN"].includes(target.tagName) || !id) {
        resetReference();
        return;
      }

      if (click) {
        event.preventDefault();
        setReferenceId(id);
        setTimeout(() => {
          setReferenceId(undefined);
        }, GeneralConstants.DEFAULT_REFERENCE_CLICK_MS_DELAY);
      }
    };

    if (refElement) {
      refElement.addEventListener("mouseover", mouseOverHandler);
      refElement.addEventListener("click", clickHandler);
    }

    return () => {
      if (refElement) {
        refElement.removeEventListener("mouseover", mouseOverHandler);
        refElement.removeEventListener("click", clickHandler);
        clearSetReferenceLinkTimeout();
      }
    };
  }, [clearSetReferenceLinkTimeout, ref, resetReference, click]);

  return { referenceLink, referenceElement, resetReference, referenceId };
};
