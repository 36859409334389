export const EntityConstants = {
  MAXIMUM_ENTITIES_TO_RETRIEVE: 10,
  CREATE_CUSTOM_TYPE_OPTION: "Create custom type",
  CONVERT_TO_STUDY_OPTION: "Convert to Study",
  DELETE_ENTITY_CONFIRMATION:
    'Are you sure you want to delete this entity?\n\nAfter it is deleted, it can be restored through "Settings" page, but the links to the other objects will be gone!',
  DELETE_ENTITIES_CONFIRMATION:
    'Are you sure you want to delete the selected entities?\n\nAfter they are deleted, they can be restored through "Settings" page, but their links to the other objects will be gone!',
  CREATE_ENTITY_DESCRIPTION: "Create an encyclopedia-style page within your Universe, focusing on a specific entity like a technology, material, or company.",
  } as const;
