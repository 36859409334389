import { FC, useContext, useEffect } from "react";
import { QueryDetailsPage } from "./QueryDetailsPage";
import { QueryContext } from "Providers";
import { QueryControllerSingleton } from "Controllers";
import { SearchQueryTypeEnum, ToastTypeEnum } from "Enums";
import { ToastHelperSingleton } from "Helpers";
import { IQueryDTO } from "Interfaces";

type TQueryDetailsLoaderProps = {
  queryId: string;
  onDelete: () => void;
  onDuplicateAsync: (duplicateQuery: IQueryDTO) => Promise<void>;
  onSearchStarted: (
    correlationId: string,
    type: SearchQueryTypeEnum,
    hasTechnologySearchResults?: boolean | null,
    abortController?: AbortController
  ) => void;
  onSearchCompleted: (hasTechnologySearchResults?: boolean | null) => void;
};

export const QueryDetailsLoader: FC<TQueryDetailsLoaderProps> = ({
  queryId,
  onDelete,
  onDuplicateAsync,
  onSearchStarted,
  onSearchCompleted,
}) => {
  // Context
  const { setQuery } = useContext(QueryContext);

  useEffect(() => {
    (async () => {
      const savedQuery = await QueryControllerSingleton.getByIdAsync(queryId);
      if (!savedQuery) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not retrieve query"
        );
        return;
      }
      setQuery(savedQuery);
    })();
  }, [queryId, setQuery]);

  return (
    <QueryDetailsPage
      showGoBackButton={false}
      onDelete={onDelete}
      onDuplicateAsync={onDuplicateAsync}
      onSearchStarted={onSearchStarted}
      onSearchCompleted={onSearchCompleted}
    />
  );
};
