// node_modules
import React, { FC, useContext, useEffect } from "react";
// Styles
import "Styles/markdownCustomBlocks.scss";
import styles from "./mainPage.module.scss";
// Helpers
import { IntercomHelperSingleton } from "Helpers";
// Contexts
import {
  AuthContext,
  ClaimEditOnObjectProvider,
  DocumentProvider,
  EditorProvider,
  ElementVisibilityProvider,
  LinkGraphProvider,
  LinksProvider,
  RecentActivityProvider,
  WebsocketProvider,
  WebsocketPubSubProvider,
  WindowingProvider,
  OnboardingProvider,
} from "Providers";

type TMainPageProvidersProps = {
  children?: React.ReactNode;
};

export const MainPageProviders: FC<TMainPageProvidersProps> = ({
  children,
}) => {
  // Contexts
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    IntercomHelperSingleton.startIntercom(auth.userEmail);
  }, [auth.userEmail]);

  // Render
  return (
    <div className={styles.mainPageContainer}>
      <WebsocketProvider>
        <WebsocketPubSubProvider>
          <DocumentProvider>
            <ClaimEditOnObjectProvider doNoStopClaimingOnCleanup={true}>
              <EditorProvider>
                <LinksProvider>
                  <ElementVisibilityProvider>
                    <LinkGraphProvider>
                      <RecentActivityProvider>
                        <WindowingProvider>
                          <OnboardingProvider>
                            {children}
                          </OnboardingProvider>
                        </WindowingProvider>
                      </RecentActivityProvider>
                    </LinkGraphProvider>
                  </ElementVisibilityProvider>
                </LinksProvider>
              </EditorProvider>
            </ClaimEditOnObjectProvider>
          </DocumentProvider>
        </WebsocketPubSubProvider>
      </WebsocketProvider>
    </div>
  );
};
