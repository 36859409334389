import { useContext, useEffect } from "react";
import { PubSubContext } from "Providers";
import { PubSubEventTypeEnum } from "Enums";

export const usePageCreatedListener = (onPageCreated: (userId: string) => void) => {
  const { pubSubHandler } = useContext(PubSubContext);
  useEffect(() => {
    pubSubHandler.subscribeToEvent(PubSubEventTypeEnum.PageCreated, onPageCreated);

    return () => {
      pubSubHandler.unsubscribeFromEvent(PubSubEventTypeEnum.PageCreated, onPageCreated);
    };
  }, [onPageCreated, pubSubHandler]);
};
