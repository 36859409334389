// node_modules
import { FC } from "react";
// Enums
import { AskIgorMenuItemEnum } from "Enums";
// Helpers
import { AskIgorMenuItemHelperSingleton } from "Helpers/Enums/AskIgorMenuItemHelper";
// Styles
import styles from "./askIgorMenuItem.module.scss";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";

type TAskIgorMenuItemProps = {
  item: AskIgorMenuItemEnum;
  isSelected: boolean;
  isDisabled?: boolean;
  isGeneratingText: boolean;
  onClick: (item: AskIgorMenuItemEnum) => void;
};

export const AskIgorMenuItem: FC<TAskIgorMenuItemProps> = ({
  item,
  isSelected,
  isDisabled,
  isGeneratingText,
  onClick,
}: TAskIgorMenuItemProps) => {
  const onItemKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onClick(item);
    }
  };

  return (
    <div
      className={`${styles.container} ${isSelected ? styles.selected : ""} ${
        isDisabled ? styles.disabled : ""
      }`}
      onClick={() => {
        onClick(item);
      }}
      role="button"
      tabIndex={0}
      onKeyDown={onItemKeyDown}
    >
      <div className={styles.item}>
        <h4>
          {AskIgorMenuItemHelperSingleton.askIgorMenuItemEnumToDisplayString(
            item
          )}
        </h4>
        <p>
          {AskIgorMenuItemHelperSingleton.askIgorMenuItemEnumToDisplayDescription(
            item
          )}
        </p>
      </div>
      <FontAwesomeIcon
        icon={(isSelected && isGeneratingText) ? faCircle : faChevronRight}
      />
    </div>
  );
};
